import React from 'react';
import { Navbar, NavbarBrand, Nav, NavbarText } from 'reactstrap';
import flybitsWhiteCompass from 'assets/images/logo-flybits-white-compass.svg';
import './NavBar.scss';
import { history } from 'configureStore';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';

let displayText: string;

export function NavBar() {
  const authState = useSelector((state: RootState) => state.auth);
  let firstName = '';
  let lastName = '';

  if (authState.length > 0) {
    [{ firstName, lastName }] = authState;
  }

  if (history.location.pathname === '/') {
    displayText = `${firstName + ' ' + lastName}`;
  }
  return (
    <div>
      <Navbar className="navbar" expand="sm" dark>
        <NavbarBrand href="/">
          <img className="navbar__logo-img" src={flybitsWhiteCompass} alt="flybits-logo" />
          <span className="navbar__logo-text">Developers</span>
        </NavbarBrand>
        <Nav className="mr-auto navbar__nav" />
        <NavbarText>{displayText}</NavbarText>
      </Navbar>
    </div>
  );
}
