import React, { useState, useEffect, useCallback } from 'react';
import {
  FlightButton,
  FlightTable,
  FlightSnackbar,
  FlightSelect,
  FlightTextInput,
  FlightOverflowMenu,
  FlightTooltip,
  FlightModal,
  FlightCheckbox,
} from '@flybits/webapp-design-system-react';
import {
  ContextPlugin,
  ContextPluginAttributes,
  ContextPlugins,
  ContextPluginData,
  ContextPluginsDownload,
} from 'model/contextPlugins/contextPlugins';
import './ContextPlugins.scss';
import { useDropzone } from 'react-dropzone';
import { flatten, cloneDeep, uniqBy } from 'lodash';
import { Project } from 'model/project';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import * as ContextPluginsActions from 'actions/contextPlugins';
import { Row } from 'reactstrap';
import { useActions } from 'actions';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { downloadAsJson } from 'helpers/downloadAsJson';
import { importHelper } from 'helpers/importHelper';
import { getProjectToken } from 'helpers/auth';
import { ProjectToken } from 'model/auth';
import * as AuthActions from 'actions/auth';
import { ReactComponent as DefaultIcon } from 'assets/images/default-ctx-plugin.svg';

interface Props {
  contextPluginsFromState?: ContextPlugins[];
  projectsFromState?: Project[];
  projectFromState?: Project[];
  projectTokenFromState?: ProjectToken[];
}

interface SelectOptionProps {
  key: string;
  name: string;
}

interface TableData {
  key: string;
  name: JSX.Element;
  status?: JSX.Element;
  createdBy?: string;
  numAttributes?: number;
  moreOpts?: JSX.Element;
}

export default function ContextPluginsTable(props: Props) {
  const {
    contextPluginsFromState = [],
    projectFromState = [],
    projectsFromState = [],
    projectTokenFromState = [],
  } = props;
  const token = getProjectToken();
  const authActions = useActions(AuthActions);
  const contextPluginActions = useActions(ContextPluginsActions);
  const [searchTerm, setSearchTerm] = useState('');
  const myProjectsFromState = flatten(cloneDeep(projectsFromState));
  const localProjectsFromState = myProjectsFromState.filter(project => {
    return project.id !== projectFromState[0]?.id;
  });
  const [projectSelectCheckbox, setProjectSelectCheckbox] = useState(['']);
  const [currentPluginId, setCurrentPluginId] = useState('');
  const [duplicateModal, setDuplicateModal] = useState(false);
  const localContextPluginsFromState = flatten(cloneDeep(contextPluginsFromState));
  const [showBanner, setShowBanner] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<SelectOptionProps>({ key: 'default', name: 'Any Status' });
  const [selectedAuthor, setSelectedAuthor] = useState<SelectOptionProps>({
    key: 'default',
    name: 'Created by anyone',
  });
  let myPlugins: ContextPlugins[] = [];
  let searchedTerm: ContextPlugins[] = [];
  function filterPlugins(myPlugins: ContextPlugins[]) {
    if (selectedStatus.key === 'default') {
      if (selectedAuthor.key === 'default') {
        myPlugins = myPlugins.filter(plugin => {
          return plugin.name.length !== 0;
        });
      } else {
        if (selectedAuthor.key === 'flybits') {
          myPlugins = myPlugins.filter(plugin => {
            return plugin.name.length !== 0 && plugin.isReserved;
          });
        } else {
          myPlugins = myPlugins.filter(plugin => {
            return plugin.name.length !== 0 && !plugin.isReserved;
          });
        }
      }
    } else {
      if (selectedStatus.key === 'active') {
        if (selectedAuthor.key === 'default') {
          myPlugins = myPlugins.filter(plugin => {
            return plugin.name.length !== 0 && plugin.tenantConfig.isEnabled;
          });
        } else {
          if (selectedAuthor.key === 'flybits') {
            myPlugins = myPlugins.filter(plugin => {
              return plugin.name.length !== 0 && plugin.isReserved && plugin.tenantConfig.isEnabled;
            });
          } else {
            myPlugins = myPlugins.filter(plugin => {
              return plugin.name.length !== 0 && !plugin.isReserved && plugin.tenantConfig.isEnabled;
            });
          }
        }
      } else {
        if (selectedAuthor.key === 'default') {
          myPlugins = myPlugins.filter(plugin => {
            return plugin.name.length !== 0 && !plugin.tenantConfig.isEnabled;
          });
        } else {
          if (selectedAuthor.key === 'flybits') {
            myPlugins = myPlugins.filter(plugin => {
              return plugin.name.length !== 0 && plugin.isReserved && !plugin.tenantConfig.isEnabled;
            });
          } else {
            myPlugins = myPlugins.filter(plugin => {
              return plugin.name.length !== 0 && !plugin.isReserved && !plugin.tenantConfig.isEnabled;
            });
          }
        }
      }
    }
    return myPlugins;
  }
  if (localContextPluginsFromState.length !== 0) {
    if (searchTerm === '') {
      myPlugins = filterPlugins(localContextPluginsFromState);
    } else {
      searchedTerm = filterPlugins(searchedTerm);
    }
  }
  const hideBanner = () => {
    setShowBanner(showBanner => !showBanner);
  };
  const handleStatusOptionClick = (option: SelectOptionProps) => {
    setSelectedStatus(option);
  };
  const handleAuthorOptionClick = (option: SelectOptionProps) => {
    setSelectedAuthor(option);
  };
  const viewContextPlugin = (plugin: ContextPlugins) => {
    const contextPluginView: ContextPlugin = {
      id: plugin.uid,
      tenantId: plugin.tenantId,
      isReserved: plugin.isReserved,
      refreshRate: plugin.refreshRate,
      supportedPlatforms: plugin.supportedPlatforms,
      provider: plugin.provider,
      category: plugin.category,
      userScope: plugin.userScope,
      isSaRestricted: plugin.isSaRestricted,
      name: plugin.name,
      description: plugin.description,
      iconUrl: plugin.iconUrl,
      values: plugin.values,
      tenantConfig: plugin.tenantConfig,
      dataSource: plugin.dataSource,
      isView: true,
      isPreview: true,
      isCreate: false,
    };
    contextPluginActions.setContextPluginState(contextPluginView);
  };
  const editContextPlugin = (plugin: ContextPlugins) => {
    const contextPluginView: ContextPlugin = {
      id: plugin.uid,
      tenantId: plugin.tenantId,
      isReserved: plugin.isReserved,
      refreshRate: plugin.refreshRate,
      supportedPlatforms: plugin.supportedPlatforms,
      provider: plugin.provider,
      category: plugin.category,
      userScope: plugin.userScope,
      isSaRestricted: plugin.isSaRestricted,
      name: plugin.name,
      description: plugin.description,
      iconUrl: plugin.iconUrl,
      values: plugin.values,
      tenantConfig: plugin.tenantConfig,
      dataSource: plugin.dataSource,
      isView: true,
      isPreview: false,
      isCreate: false,
    };
    contextPluginActions.setContextPluginState(contextPluginView);
  };
  const createContextPlugin = () => {
    const contextPluginView: ContextPlugin = {
      id: '',
      tenantId: '',
      isReserved: false,
      refreshRate: 0,
      supportedPlatforms: [''],
      provider: '',
      category: '',
      userScope: false,
      isSaRestricted: false,
      name: '',
      description: '',
      iconUrl: '',
      values: {},
      tenantConfig: {
        id: '',
        tenantId: '',
        contextPluginId: '',
        isEnabled: false,
        refreshRate: 0,
      },
      dataSource: '',
      isView: true,
      isCreate: true,
      isPreview: false,
    };
    contextPluginActions.setContextPluginState(contextPluginView);
  };
  if (searchTerm !== '') {
    searchedTerm = searchWithRegExp(localContextPluginsFromState, searchTerm);
  }
  const handleImport = async (args: string) => {
    const prepareUploadedDarta = importHelper(args);
    const parseData: ContextPluginsDownload = JSON.parse(prepareUploadedDarta);
    const contextPluginForUpload: ContextPluginAttributes[] = [];
    parseData.data.forEach(item => {
      contextPluginForUpload.push({
        tenantId: item.attributes.tenantId,
        uniqueId: item.attributes.uniqueId,
        provider: item.attributes.provider,
        isSaRestricted: item.attributes.isSaRestricted,
        tenantConfigId: item.attributes.tenantConfigId,
        category: item.attributes.category,
        description: item.attributes.description,
        iconUrl: item.attributes.iconUrl,
        isEnabled: item.attributes.isEnabled,
        isReserved: item.attributes.isReserved,
        name: item.attributes.name,
        projectId: item.attributes.projectId,
        refreshRate: item.attributes.refreshRate,
        supportedPlatforms: item.attributes.supportedPlatforms,
        token: item.attributes.token,
        userScope: item.attributes.userScope,
        values: item.attributes.values,
      });
    });

    if (contextPluginForUpload.length !== 0) {
      contextPluginForUpload.forEach(async plugin => {
        await contextPluginActions.createContextPluginState(plugin);
      });
    }
  };
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const parseUploadedFile = new FileReader();
      parseUploadedFile.readAsDataURL(file);
      parseUploadedFile.onload = () => {
        if (!!parseUploadedFile.result) {
          const json = JSON.stringify(parseUploadedFile.result as string);
          handleImport(json);
        }
      };
    });
    // eslint-disable-next-line
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.context-plugins',
  });
  const tableHeaders = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
    },
    {
      name: 'Created by',
      key: 'createdBy',
      isVisible: true,
    },
    {
      name: 'Number of Attributes',
      key: 'numAttributes',
      isVisible: true,
    },
    {
      name: '',
      key: 'moreOpts',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  const optionGroups = (plugin: ContextPlugins) => {
    return [
      [
        {
          key: 1,
          name: 'Duplicate',
          disabled: plugin.isReserved,
          onClick: () => triggerDuplicateModal(plugin),
        },
        {
          key: 2,
          name: 'Delete',
          disabled: plugin.isReserved,
          onClick: () => toggleDeleteConfirm(plugin.uid),
        },
        {
          key: 3,
          name: 'Export',
          disabled: plugin.isReserved,
          onClick: () => handleExport(plugin),
        },
        {
          key: 4,
          name: 'Edit',
          disabled: plugin.isReserved,
          onClick: () => editContextPlugin(plugin),
        },
      ],
    ];
  };
  const handleExport = (plugin: ContextPlugins) => {
    const pluginData: ContextPluginData[] = [];
    pluginData.push({
      id: plugin.uid,
      attributes: {
        uniqueId: plugin.uid,
        tenantId: plugin.tenantId,
        isReserved: plugin.isReserved,
        isEnabled: plugin.tenantConfig.isEnabled,
        refreshRate: plugin.refreshRate,
        supportedPlatforms: plugin.supportedPlatforms,
        provider: plugin.provider,
        category: plugin.category,
        userScope: plugin.userScope,
        isSaRestricted: plugin.isSaRestricted,
        name: plugin.name,
        description: plugin.description,
        iconUrl: plugin.iconUrl,
        values: plugin.values,
        tenantConfigId: plugin.tenantConfig.id,
        token: token,
        projectId: null,
      },
      type: 'context-plugin',
    });

    const downloadData: ContextPluginsDownload = {
      data: pluginData,
      meta: {
        downloadedAt: Date.now(),
        totalRecords: 1,
      },
    };
    downloadAsJson(Date.now().toString(), JSON.stringify(downloadData), 'context-plugins');
  };
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [idDelete, setIdForDelete] = useState('');
  const toggleDeleteConfirm = (id: string) => {
    if (idDelete === '') {
      setIdForDelete(id);
    } else {
      setIdForDelete('');
    }
    setIsDeleteConfirm(isDeleteConfirm => !isDeleteConfirm);
  };
  const [isToggleStatusConfirm, setIsToggleStatusConfirm] = useState(false);
  const [idStatus, setIdForStatus] = useState('');
  const toggleStatusConfirm = (id: string) => {
    if (idStatus === '') {
      setIdForStatus(id);
    } else {
      setIdForStatus('');
    }
    setIsToggleStatusConfirm(isToggleStatusConfirm => !isToggleStatusConfirm);
  };
  const handleDelete = async () => {
    await contextPluginActions.deleteContextPlugin(idDelete);
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  const handleCancelDelete = () => {
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  const handleCancelStatus = () => {
    setIdForStatus('');
    toggleStatusConfirm('');
  };
  const [projectToken, setProjectToken] = useState<ProjectToken[]>([]);
  const cloneProjectSelect = projectSelectCheckbox.filter(checked => checked !== '');
  useEffect(() => {
    projectTokenFromState.map(project => {
      if (cloneProjectSelect.includes(project.projectId)) {
        setProjectToken([...projectToken, { projectId: project.projectId, projectToken: project.projectToken }]);
      }
      return 'next';
    });
    //eslint-disable-next-line
  }, [projectTokenFromState]);

  const handleDuplicateNext = () => {
    if (!showProjectList) {
      continueToDuplication();
    } else {
      cloneProjectSelect.forEach(async tenantId => {
        await authActions.fetchProjectToken(tenantId);
      });
    }
  };

  const handleAfterTokenFetch = (id?: string) => {
    const exportables: ContextPlugins[] = [];
    const contentForUpload: ContextPluginAttributes[] = [];
    if (id) {
      myPlugins.forEach(plugin => {
        if (id === plugin?.id) {
          exportables.push(plugin);
        }
      });
    }
    const cloneProjectSelectState = projectSelectCheckbox;
    const filterCheckboxState = cloneProjectSelectState.filter(removeId => {
      return removeId !== '';
    });

    const projectTokenClone = uniqBy(projectToken, 'projectId');
    if (filterCheckboxState.length === projectTokenClone.length) {
      projectTokenClone.map(project => {
        return exportables.map(item => {
          return contentForUpload.push({
            category: item.category,
            description: item.description,
            iconUrl: item.iconUrl,
            name: item.name,
            refreshRate: item.refreshRate,
            supportedPlatforms: item.supportedPlatforms,
            userScope: item.userScope,
            values: item.values,
            projectTokenForApiCall: project?.projectToken,
          });
        });
      });

      contentForUpload.forEach(async plugin => {
        const response = await contextPluginActions.createContextPluginState(plugin);
        if (response === 'done') {
          setProjectSelectCheckbox(['']);
          setShowProjectList(false);
          setDuplicateModal(false);
          setProjectToken([...projectToken.splice(projectToken.length)]);
        }
      });
    } else {
      return;
    }
    setDuplicateModal(false);
    setCurrentPluginId('');
  };
  useEffect(() => {
    let tempId;
    if (currentPluginId !== '') {
      tempId = currentPluginId;
    }
    handleAfterTokenFetch(tempId);
    //eslint-disable-next-line
  }, [projectToken]);
  const triggerDuplicateModal = (plugin?: ContextPlugins) => {
    setDuplicateModal(duplicateModal => !duplicateModal);
    if (plugin?.id) {
      setCurrentPluginId(plugin?.id);
    } else {
      setCurrentPluginId('');
    }
  };
  const [showProjectList, setShowProjectList] = useState(false);
  const continueToDuplication = () => {
    setShowProjectList(true);
  };

  const handleProjectSelectCheckbox = (id: string) => {
    if (projectSelectCheckbox.includes(id)) {
      const cloneCheckboxState = projectSelectCheckbox;
      const filterCheckboxState = cloneCheckboxState.filter(removeId => {
        return removeId !== id;
      });
      setProjectSelectCheckbox(filterCheckboxState);
    } else {
      setProjectSelectCheckbox(projectSelectCheckbox.concat(id));
    }
  };

  const handleCancelDuplicateModal = () => {
    setProjectSelectCheckbox(['']);
    setShowProjectList(false);
    triggerDuplicateModal();
  };
  const toggleStatus = () => {
    let plugin = undefined;
    if (searchTerm === '') {
      plugin = myPlugins.filter(plugin => {
        return plugin.id === idStatus;
      });
    } else {
      plugin = searchedTerm.filter(plugin => {
        return plugin.id === idStatus;
      });
    }
    const status = !plugin[0].tenantConfig.isEnabled;
    if (status) {
      contextPluginActions.enableContextPluginStatus(plugin[0]);
    } else {
      contextPluginActions.disableContextPluginStatus(plugin[0]);
    }
    setIsToggleStatusConfirm(false);
    setIdForStatus('');
  };
  myPlugins = filterPlugins(myPlugins);
  const tableData: TableData[] = [];
  if (searchTerm === '') {
    for (let i = 0; i < myPlugins.length; i++) {
      let myOptionGroups = optionGroups(myPlugins[i]);
      tableData.push({
        key: myPlugins[i].id,
        name: (
          <div className="context-plugins__table__name">
            {myPlugins[i].iconUrl ? (
              <img className="context-plugins__table__name__icon" src={myPlugins[i].iconUrl} alt="plugin-icon"></img>
            ) : (
              <DefaultIcon className="context-plugins__table__name__icon" />
            )}
            <div className="context-plugins__table__name__text">
              <FlightTooltip
                className="context-plugins__table__name__text__name"
                direction="right"
                isEnabled={true}
                description={myPlugins[i].description ? myPlugins[i].description : 'This plugin has no description'}
                delay={0}
              >
                <span
                  className="context-plugins__table__name__text__view"
                  onClick={() => viewContextPlugin(myPlugins[i])}
                >
                  {myPlugins[i].name}{' '}
                </span>
              </FlightTooltip>
              <span className="context-plugins__table__name__text__id">{myPlugins[i].uid} </span>
            </div>
          </div>
        ),
        status: (
          <div>
            {myPlugins[i].tenantConfig.isEnabled ? (
              <span
                onClick={() => {
                  toggleStatusConfirm(myPlugins[i].id);
                }}
                className="context-plugins__table__status__active"
              >
                Active
              </span>
            ) : (
              <span
                onClick={() => {
                  toggleStatusConfirm(myPlugins[i].id);
                }}
                className="context-plugins__table__status__inactive"
              >
                Inactive
              </span>
            )}
          </div>
        ),
        createdBy: myPlugins[i].isReserved ? 'Flybits' : 'User',
        numAttributes: Object.keys(myPlugins[i].values).length,
        moreOpts: <FlightOverflowMenu optionGroups={myOptionGroups} />,
      });
    }
  } else {
    searchedTerm = filterPlugins(searchedTerm);
    for (let i = 0; i < searchedTerm.length; i++) {
      let myOptionGroups = optionGroups(searchedTerm[i]);
      tableData.push({
        key: searchedTerm[i].id,
        name: (
          <div className="context-plugins__table__name">
            {searchedTerm[i].iconUrl ? (
              <img className="context-plugins__table__name__icon" src={searchedTerm[i].iconUrl} alt="plugin-icon"></img>
            ) : (
              <DefaultIcon className="context-plugins__table__name__icon" />
            )}
            <div className="context-plugins__table__name__text">
              <FlightTooltip
                className="context-plugins__table__name__text__name"
                direction="right"
                isEnabled={true}
                description={
                  searchedTerm[i].description ? searchedTerm[i].description : 'This plugin has no description'
                }
                delay={0}
              >
                <span onClick={() => viewContextPlugin(searchedTerm[i])}>{searchedTerm[i].name} </span>
              </FlightTooltip>
              <span className="context-plugins__table__name__text__id">{searchedTerm[i].uid} </span>
            </div>
          </div>
        ),
        status: (
          <div>
            {searchedTerm[i].tenantConfig.isEnabled ? (
              <span
                onClick={() => {
                  toggleStatusConfirm(searchedTerm[i].id);
                }}
                className="context-plugins__table__status__active"
              >
                Active
              </span>
            ) : (
              <span
                onClick={() => {
                  toggleStatusConfirm(searchedTerm[i].id);
                }}
                className="context-plugins__table__status__inactive"
              >
                Inactive
              </span>
            )}
          </div>
        ),
        createdBy: searchedTerm[i].isReserved ? 'Flybits' : 'User',
        numAttributes: Object.keys(searchedTerm[i].values).length,
        moreOpts: <FlightOverflowMenu optionGroups={myOptionGroups} />,
      });
    }
  }
  if (tableData.length === 0) {
    tableData.push({
      name: <span>No Results</span>,
      key: '',
    });
  }
  return (
    <div className="context-plugins">
      <FlightModal
        className="content-templates__duplicate-modal"
        isVisible={duplicateModal}
        toggleModalShown={triggerDuplicateModal}
        scrollable={false}
        size="medium"
        header={!showProjectList ? <span>Confirm</span> : <span>Select Projects</span>}
        content={
          !showProjectList ? (
            <div>
              <span>The following Context Plugins will be copied to another project. Do you wish to continue?</span>
              <FlightSnackbar
                isVisible={true}
                icon="infoFilled"
                title=""
                className="content-templates__duplicate-modal__banner"
                content={'context plugin duplication'}
                type="warning"
                isAutoDismiss={true}
                actionName=""
              />
              <div className="content-templates__duplicate-modal__template-container">{currentPluginId}</div>
            </div>
          ) : (
            <div>
              <span className="content-templates__duplicate-modal__template-container__modal-header">
                Select which active Project(s) you would like to copy to:
              </span>
              {localProjectsFromState.map(project => (
                <div key={Math.random()} className="content-templates__duplicate-modal__template-container__names">
                  <span>
                    <FlightCheckbox
                      checkState={projectSelectCheckbox.includes(project.id) ? 'SELECTED' : 'UNSELECTED'}
                      className="content-templates__duplicate-modal__template-container__select"
                      onSelect={() => handleProjectSelectCheckbox(project.id)}
                    />
                  </span>
                  <span className="content-templates__duplicate-modal__template-container__text">{project.name}</span>
                </div>
              ))}
            </div>
          )
        }
        footer={
          <div className="modal-footer">
            <FlightButton onClick={handleCancelDuplicateModal} label="Cancel" theme="secondary" />
            <FlightButton
              type="submit"
              label={!showProjectList ? 'Continue' : 'Export'}
              onClick={handleDuplicateNext}
            />
          </div>
        }
      />
      <ConfirmModal
        isVisible={isDeleteConfirm}
        title="Confirm Delete"
        message="Are you sure you want to delete this plugin?"
        toggleCallback={toggleDeleteConfirm}
        continueCallback={handleDelete}
        cancelCallback={handleCancelDelete}
      />
      <ConfirmModal
        isVisible={isToggleStatusConfirm}
        title="Confirm Change Status"
        message="Are you sure you want to change the status of this plugin?"
        toggleCallback={toggleStatusConfirm}
        continueCallback={toggleStatus}
        cancelCallback={handleCancelStatus}
      />
      <FlightSnackbar
        isVisible={showBanner}
        className="context-plugins__snackbar"
        isFloating
        icon="infoFilled"
        title=""
        content={'Context plugins are the way your project can read and accept data'}
        type="info"
        action={hideBanner}
        actionName="Got it!"
        isAutoDismiss={true}
      />
      <div className="context-plugins__container">
        <div className="context-plugins__filer">
          <span className="context-plugins__filer__text">Filter by: </span>
          <FlightSelect
            label="Any Status"
            className="context-plugins__filer__status"
            handleOptionClick={handleStatusOptionClick}
            selected={selectedStatus}
            options={[
              {
                key: 'default',
                name: 'Any Status',
              },
              {
                key: 'active',
                name: 'Active',
              },
              {
                key: 'inactive',
                name: 'Inactive',
              },
            ]}
            width="110px"
          />
          <FlightSelect
            label="Created by anyone"
            className="context-plugins__filer__created-by"
            selected={selectedAuthor}
            handleOptionClick={handleAuthorOptionClick}
            options={[
              {
                key: 'default',
                name: 'Created by anyone',
              },
              {
                key: 'flybits',
                name: 'Flybits',
              },
              {
                key: 'user',
                name: 'User',
              },
            ]}
            width="160px"
          />
        </div>
        <div className="context-plugins__buttons">
          <Row>
            <FlightTextInput
              placeholderText="Search for a plugin"
              type="text"
              name="search"
              className="context-plugins__search-field"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <SearchIcon className="context-plugins__search-icon" />
            <input name="file" id="fileValidation" {...getInputProps()} />
            <FlightButton
              className="context-plugins__buttons__import"
              label="Import"
              theme="secondary"
              onClick={open}
            />
            <FlightButton
              className="context-plugins__buttons__import__new-plugin"
              label="+  New Plugin"
              type="primary"
              onClick={createContextPlugin}
            />
          </Row>
        </div>
      </div>
      <div className="context-plugins__tablecontainer">
        <FlightTable
          className="context-plugins__table"
          tableHeaders={tableHeaders}
          tableData={tableData}
          hasPaginationBeforeTable={false}
        />
      </div>
    </div>
  );
}
