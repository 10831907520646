import React, { useEffect } from 'react';
import { Project } from 'model/project';
import { ProjectToken } from 'model/auth';
import { useDispatch } from 'react-redux';
import { showNotification } from 'actions/notifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';

import { IFrameHelper } from 'helpers/iframe.helper';

interface IProps {
  templateId: string;
  onLoad?: (iframe: any) => void;
  isVisible: boolean;
  projectsFromState?: Project[];
  projectTokenFromState?: ProjectToken[];
}

export default function ContentIframe(props: IProps) {
  let iframe: any;
  const dispatch = useDispatch();
  const { projectTokenFromState = []} = props;
  async function openIframe() {
    let token = projectTokenFromState[0]?.projectToken;
    const searchParams = new URLSearchParams();
    const search: any = { host: 'https://v3.flybits.com', projectToken: token, templateId: props.templateId };
    Object.keys(search).forEach((key: any) => searchParams.append(key, search[key]));
    let URL = 'https://devportal.flybits.com/content_template_builder/index.html?' + searchParams.toString();
    if (!iframe) {
      iframe = new IFrameHelper(`id-contentIframe`, document.querySelector(`.iframe-contentIframe`), URL);
    } else {
      iframe.setURL(URL);
    }
    return iframe
      .loadIframe()
      .then(() => {
        props.onLoad && props.onLoad(iframe);
        iframe.sendApply('fromReactDevPortal');
      })
      .catch(() => {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Failed',
            body: 'Could not open content template builder',
            type: 'danger',
          }),
        );
      });
  }

  useEffect(() => {
    openIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`template-iframe ${!props.isVisible ? 'hidden' : ''}`}>
      <div className={`iframe-container iframe-contentIframe`}></div>
    </div>
  );
}
