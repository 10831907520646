import {
  OktaSettingsActions,
  OktaSettingsActionTypes,
  OktaSettings,
  ObjectForApi,
  OktaSettingsForApi,
} from '../model/oktaSettings';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setOktaSettings(oktaSettings: OktaSettings): OktaSettingsActions {
  return {
    type: OktaSettingsActionTypes.SET_OKTASETTINGS_STATE,
    payload: oktaSettings,
  };
}

export function resetOktaSettings(): OktaSettingsActions {
  return {
    type: OktaSettingsActionTypes.RESET_OKTASETTINGS_STATE,
  };
}

export function fetchOktaSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getOktaSettings();
      dispatch(resetOktaSettings());
      const responseFromServer: OktaSettingsForApi = response.data.settings;
      const transformObject: OktaSettings = {
        authorizationServerID: responseFromServer.AuthorizationServerID,
        clientID: responseFromServer.ClientID,
        host: responseFromServer.Host,
        secretKey: responseFromServer.SecretKey,
      };
      dispatch(setOktaSettings(transformObject));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetOktaSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateOktaSetting(oktaSettings: OktaSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const oktaSettingsForApi: OktaSettingsForApi = {
        AuthorizationServerID: oktaSettings.authorizationServerID,
        ClientID: oktaSettings.clientID,
        Host: oktaSettings.host,
        SecretKey: oktaSettings.secretKey,
      };

      const objectForApi: ObjectForApi = {
        config: JSON.stringify(oktaSettingsForApi),
        configName: {
          configName: 'okta',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchOktaSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
