import React, { useState } from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { ContextPlugin, Attributes } from 'model/contextPlugins/contextPlugins';
import * as ContextPluginsActions from 'actions/contextPlugins';
import CreateContextAttribute from 'components/ContextPluginComponents/CreateContextAttribute';
import { useActions } from 'actions';
import CreateNewContextPlugin from 'components/ContextPluginComponents/CreateContextPlugin';
import ViewContextPlugin from 'components/ContextPluginComponents/ViewContextPlugin';
import { Project } from 'model/project';
import './ManageContextPlugin.scss';
import { ReactComponent as DefaultIcon } from 'assets/images/default-ctx-plugin.svg';
import { ProjectToken } from 'model/auth';

interface Props {
  contextPluginFromState?: ContextPlugin[];
  categories?: Array<string>;
  projectFromState?: Project[];
  projectTokenFromState?: ProjectToken[];
}

export default function ManageContextPlugins(props: Props) {
  const { contextPluginFromState = [], projectTokenFromState = [], categories, projectFromState = [] } = props;
  const createPluginActions = useActions(ContextPluginsActions);
  const [changed, setChanged] = useState(false);
  let name = '';
  let isCreate = undefined;
  let isCreateAttribute = undefined;
  let isPreview = undefined;
  let isReserved = undefined;
  let iconUrl = undefined;
  let isViewAttribute = undefined;
  let description = undefined;
  if (contextPluginFromState.length !== 0) {
    [
      { name, isCreate, isViewAttribute, isReserved, isCreateAttribute, isPreview, iconUrl, description },
    ] = contextPluginFromState;
  }
  if (!isViewAttribute) {
    isViewAttribute = '';
  }
  let isContextCreate = isCreate;
  const getAttributeFromPage = async (data: Attributes, name: string) => {
    await createPluginActions.setContextPluginAttributes(
      contextPluginFromState && contextPluginFromState[0],
      data,
      name,
    );
    await createPluginActions.setCreateAttribute(contextPluginFromState && contextPluginFromState[0], false);
    await createPluginActions.setViewAttribute(contextPluginFromState && contextPluginFromState[0], '');
    setChanged(true);
  };
  isViewAttribute = isViewAttribute.substring(isViewAttribute.lastIndexOf('.') + 1, isViewAttribute.length);
  return (
    <div className="manage-plugins">
      <div>
        {!isPreview ? null : isPreview && iconUrl ? (
          <img className="manage-plugins__icon" src={iconUrl} alt="plugin-icon"></img>
        ) : (
          <DefaultIcon className="manage-plugins__icon" />
        )}
        <div>
          <div className={isPreview ? 'manage-plugins__header' : ''}>
            <PageHeader
              title={
                isCreateAttribute
                  ? 'Create Attribute'
                  : isViewAttribute.length !== 0
                  ? (contextPluginFromState && (contextPluginFromState && contextPluginFromState[0])).values[isViewAttribute].name
                  : !isContextCreate
                  ? name
                  : 'Create Context Plugin'
              }
              isLarge={true}
            />
            {isPreview && description && isViewAttribute.length === 0 && !isCreateAttribute ? (
              <span className="manage-plugins__description">{description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        {isCreateAttribute || isViewAttribute.length !== 0 ? (
          <CreateContextAttribute
            callback={getAttributeFromPage}
            projectFromState={projectFromState}
            contextPluginFromState={contextPluginFromState}
            isReserved={isReserved}
          />
        ) : isContextCreate ? (
          <CreateNewContextPlugin
            projectFromState={projectFromState}
            contextPluginFromState={contextPluginFromState}
            categories={categories}
            projectTokenFromState={projectTokenFromState}
          />
        ) : isPreview ? (
          <ViewContextPlugin
            contextPluginFromState={contextPluginFromState}
            changed={changed}
            projectTokenFromState={projectTokenFromState}
            projectFromState={projectFromState}
          />
        ) : (
          <CreateNewContextPlugin
            contextPluginFromState={contextPluginFromState}
            projectFromState={projectFromState}
            categories={categories}
          />
        )}
      </div>
    </div>
  );
}
