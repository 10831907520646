import BaseService from './base.service';
import { ObjectForApi } from 'model/languages';
import { ObjectForSilentPushApi } from 'model/silentPushSettings';

export default class SettingsService extends BaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  LANGAUGE_RESOURCE: string;
  PUSH_RESOURCE: string;
  SILENT_PUSH_RESOURCE: string;
  FACEBOOK_SETTINGS_RESOURCE: string;
  GOOGLE_SETTINGS_RESOURCE: string;
  OPENID_SETTINGS_RESOURCE: string;
  OKTA_SETTINGS_RESOURCE: string;
  APIKEY_SETTINGS_RESOURCE: string;
  TENANT_ACCESS_RESOURCE: string;
  SIGNED_LOGIN_RESOURCE: string;
  SIGNED_LOGIN_SECONDARY_RESOURCE: string;
  PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'sso/settings/';
    this.SECONDARY_RESOURCE = 'configs';
    this.LANGAUGE_RESOURCE = 'languages';
    this.PUSH_RESOURCE = 'push-settings';
    this.SILENT_PUSH_RESOURCE = 'entity-event-silent-push';
    this.FACEBOOK_SETTINGS_RESOURCE = 'facebook';
    this.GOOGLE_SETTINGS_RESOURCE = 'gplus';
    this.OPENID_SETTINGS_RESOURCE = 'oidc';
    this.OKTA_SETTINGS_RESOURCE = 'okta';
    this.APIKEY_SETTINGS_RESOURCE = 'apikey';
    this.TENANT_ACCESS_RESOURCE = 'tenant-access';
    this.SIGNED_LOGIN_RESOURCE = 'singedLogin/';
    this.SIGNED_LOGIN_SECONDARY_RESOURCE = 'publicKey?json=true';
    this.PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE = 'project-custom-content-templates';
  }

  async getLanguages() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.LANGAUGE_RESOURCE);
  }

  async getUserSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.PUSH_RESOURCE);
  }

  async getSilentPushSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.SILENT_PUSH_RESOURCE);
  }

  async createSilentPushSetting(data: ObjectForSilentPushApi) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }

  async getFacebookSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.FACEBOOK_SETTINGS_RESOURCE);
  }

  async getGoogleSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.GOOGLE_SETTINGS_RESOURCE);
  }

  async getOpenIDSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.OPENID_SETTINGS_RESOURCE);
  }

  async getOktaSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.OKTA_SETTINGS_RESOURCE);
  }

  async getApiKeySettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.APIKEY_SETTINGS_RESOURCE);
  }

  async getTenantAccessSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.TENANT_ACCESS_RESOURCE);
  }

  async getProjectCustomContentTemplates() {
    return this.request(
      'GET',
      this.CURRENT_PATH + this.SECONDARY_RESOURCE + '/' + this.PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE,
    );
  }

  async createOrUpdateSetting(data: ObjectForApi) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }
}
