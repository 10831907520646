import React, { useState, useEffect } from 'react';
import { FlightModal, FlightButton, FlightToggleSwitch } from '@flybits/webapp-design-system-react';
import { ReactComponent as ApiKeyLogo } from 'assets/images/idplogos/api.svg';
import { getGuid } from 'helpers/randomUuidGenerator';
import './ApiKeyModal.scss';
import { ReactComponent as TrashBin } from 'assets/images/trash_bin.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg';
import * as ApiKeyActions from 'actions/apiKeySettings';
import { useActions } from 'actions';
import { Row, Col } from 'reactstrap';
import { ApiKeySettings } from 'model/apikeySettings';
import * as NotificationsActions from 'actions/notifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { ReactComponent as WarningIcon } from 'assets/images/warning-icon.svg';
import * as TenantSettingsActions from 'actions/tenantAccessSettings';
import { TenantAccessSettings, AllowedTenants } from 'model/tenantAccessSettings';
import { API_KEY_DELETION_WARNING } from 'constants/banners/banners';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  apiKeySettingsFromState?: ApiKeySettings[];
  tenantAccessSettingsFromState?: TenantAccessSettings[];
}

export default function ApiKeyModal(props: Props) {
  const { openModal, toggleModal, apiKeySettingsFromState = [], tenantAccessSettingsFromState = [] } = props;
  const [checkedStateApi, setCheckedStateApi] = useState(false);
  const [checked, setChecked] = useState(false);

  const [uuid, setUuid] = useState('');
  const apiKeyActions = useActions(ApiKeyActions);
  const notficationActions = useActions(NotificationsActions);
  const tenantSettingsActions = useActions(TenantSettingsActions);

  const setUuidToState = () => {
    const uuid = getGuid().toLocaleUpperCase();
    setUuid(uuid);
  };

  let apiKey = '';
  if (apiKeySettingsFromState.length !== 0) {
    [{ apiKey }] = apiKeySettingsFromState;
  }
  useEffect(() => {
    setUuid(apiKey);
    // eslint-disable-next-line
  }, [apiKey]);

  let allowed: AllowedTenants[] = [{ type: 0, value: '' }];
  if (tenantAccessSettingsFromState.length !== 0) {
    [{ allowed }] = tenantAccessSettingsFromState;
  }

  let allowedApiKey: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedApiKey = allowed.filter(x => x.value === 'apikey');
  }

  function resetModal() {
    setUuid('');
    toggleModal();
  }

  function handleDelete() {
    setChecked(false);
    setUuid('');
  }

  function copyId() {
    navigator.clipboard.writeText(uuid);
    const uniqueId = generateUniqueId();
    notficationActions.showNotification({
      id: uniqueId,
      showNotfication: true,
      body: 'Copied to clipboard.',
      type: 'success',
    });
  }

  function handleCreate() {
    const allowedTenants: AllowedTenants[] = allowed;
    allowedTenants.push({
      type: 1,
      value: 'apikey',
    });
    if (uuid !== '') {
      apiKeyActions.createOrUpdateApiKeySettings({ apikey: uuid });
      if (checked) {
        const enableAPKeySettings = {
          allowed: allowedTenants,
        };
        tenantSettingsActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
      } else {
        const disableApiKeySettings = {
          allowed: allowed.filter(x => x.value !== 'apikey'),
        };
        tenantSettingsActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
      }
    } else {
      const disableApiKeySettings = {
        allowed: allowed.filter(x => x.value !== 'apikey'),
      };
      tenantSettingsActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
      apiKeyActions.createOrUpdateApiKeySettings({ apikey: null });
    }
    setUuid('');
    toggleModal();
  }

  useEffect(() => {
    if (allowedApiKey.length !== 0 && allowedApiKey[0].value !== '' && uuid !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedApiKey]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  return (
    <div className="api-key-modal">
      <FlightModal
        isVisible={openModal}
        toggleModalShown={() => {
          setUuid(apiKey);
          toggleModal();
        }}
        scrollable={false}
        size="small"
        content={
          <Col className="api-key-modal__content">
            <Row className="api-key-modal__contentr">
              <ApiKeyLogo className="api-key-modal__content__header-logo" />
              <span className="api-key-modal__content__header-text">API Key</span>
            </Row>
            <Col>
              <Row className="api-key-modal__content__toggle">
                <FlightToggleSwitch checked={checked} onChange={setChecked} disabled={!uuid} label={'Enable '} />
              </Row>
              {apiKey !== null && apiKey !== '' && uuid !== '' ? (
                <Row className="api-key-modal__content__warning-banner">
                  <WarningIcon className="api-key-modal__content__warning-banner__icon" />
                  <span className="api-key-modal__content__warning-banner__text">{API_KEY_DELETION_WARNING}</span>
                </Row>
              ) : (
                ''
              )}
            </Col>
            {uuid !== '' && uuid !== null ? (
              <Row className="api-key-modal__content__text-block">
                <span className="api-key-modal__content__text-block__display-uuid">
                  {uuid}
                  <CopyIcon
                    className="api-key-modal__content__text-block__display-uuid__img
                  "
                    onClick={copyId}
                  />
                </span>

                <TrashBin className="api-key-modal__content__text-block__delete" onClick={handleDelete} />
              </Row>
            ) : (
              <Row className="api-key-modal__content__gen-btn">
                <FlightButton label="Generate API Key" onClick={setUuidToState} />
              </Row>
            )}
          </Col>
        }
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                setUuid(apiKey);
                toggleModal();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton
              type="submit"
              label="Apply Changes"
              onClick={() => {
                handleCreate();
                resetModal();
              }}
            />
          </div>
        }
      />
    </div>
  );
}
