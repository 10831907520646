export const layout = [
  {
    id: '1',
    value: 'In-App Content Templates',
  },
  {
    id: '2',
    value: 'Content Category Filter',
  },
];
