import {
  OpenIDSettingsActionTypes,
  OpenIDSettingsActions,
  OpenIDSettings,
  ObjectForApi,
} from '../model/openIDSettings';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setOpenIDSettings(openIDSettings: OpenIDSettings): OpenIDSettingsActions {
  return {
    type: OpenIDSettingsActionTypes.SET_OPENIDSETTINGS_STATE,
    payload: openIDSettings,
  };
}

export function resetOpenIDSettings(): OpenIDSettingsActions {
  return {
    type: OpenIDSettingsActionTypes.RESET_OPENIDSETTINGS_STATE,
  };
}

export function fetchOpenIDSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getOpenIDSettings();
      dispatch(resetOpenIDSettings());
      dispatch(setOpenIDSettings(response.data.settings));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetOpenIDSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateOpenIDSettings(openIDSettings: OpenIDSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: JSON.stringify(openIDSettings),
        configName: {
          configName: 'oidc',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchOpenIDSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
