import {
  TenantAccessSettingsActions,
  TenantAccessSettingsActionTypes,
  TenantAccessSettings,
  ObjectForApi,
} from '../model/tenantAccessSettings';
import SettingsService from '../services/settings.service';

export function setTenantAccessSettings(facebookSettings: TenantAccessSettings): TenantAccessSettingsActions {
  return {
    type: TenantAccessSettingsActionTypes.SET_TENANTACCESSSETTINGS_STATE,
    payload: facebookSettings,
  };
}

export function resetTenantAccessSettings(): TenantAccessSettingsActions {
  return {
    type: TenantAccessSettingsActionTypes.RESET_TENANTACCESSSETTINGS_STATE,
  };
}

export function fetchTenantAccessSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getTenantAccessSettings();
      dispatch(resetTenantAccessSettings());
      dispatch(setTenantAccessSettings(response.data.settings));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetTenantAccessSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateTenantAccessSettings(tenantSettings: TenantAccessSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: JSON.stringify(tenantSettings),
        configName: {
          configName: 'tenant-access',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        dispatch(fetchTenantAccessSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
