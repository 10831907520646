import { ProjectAction, Project, ProjectActionTypes } from '../model/project';

import createReducer from './createReducer';

export const projects = createReducer<Project[]>([], {
  [ProjectActionTypes.SET_PROJECTS_STATE](state: Project[], action: ProjectAction) {
    return [...state, action.payload];
  },
  [ProjectActionTypes.RESET_PROJECTS_STATE](state: []) {
    return state.filter(item => !item);
  },
});
