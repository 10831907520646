import { Project, ProjectAction, ProjectActionTypes, ProjectResponseBody } from '../model/project';
import ProjectService from '../services/project.service';
import { showNotification } from './notifications';
import { generateUniqueId } from '../helpers/randomIdGenerator';
import { history } from 'configureStore';

interface CreateProject {
  name: string;
  subDomain: string;
}

export function setProjectsState(projects: Project[]): ProjectAction {
  return {
    type: ProjectActionTypes.SET_PROJECTS_STATE,
    payload: projects,
  };
}

export function setProjectState(project: Project): ProjectAction {
  return {
    type: ProjectActionTypes.SET_PROJECT_STATE,
    payload: project,
  };
}

export function resetProjectState(): ProjectAction {
  return {
    type: ProjectActionTypes.RESET_PROJECT_STATE,
  };
}

export function resetProjectsState(): ProjectAction {
  return {
    type: ProjectActionTypes.RESET_PROJECTS_STATE,
  };
}

export function fetchAllProjects() {
  const transformedResponse: Project[] = [];

  return async (dispatch: { (arg0: ProjectAction): void }) => {
    try {
      const req = new ProjectService();
      // permission level is set to 'owner' and passed as query params.
      const params = {
        min: 20,
        max: 10000,
        limit: 1000000,
      };
      const response = await req.getAllProjects(params);
      // Transform response body to align with view models
      response.data.data?.map((value: ProjectResponseBody) => {
        transformedResponse.push({
          id: value.id,
          name: value.Name,
          subDomain: value.Subdomain,
          creatorID: value.CreatorID,
          isActive: value.IsActive,
          tierID: value.TierID,
        });
      });
      dispatch(setProjectsState(transformedResponse));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function deleteProject(projectId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async () => {
    try {
      const req = new ProjectService();
      // permission level is set to 'owner' and passed as query params.
      const params = {
        projectId: projectId,
      };
      const response = await req.deleteProject(params);
      if (response.status === 200) {
        history.goBack();
      }
    } catch (error) {
      //* backend returns 400 bad request when you delete a project *//
      // this is a temporary fix to handle that
      if (error.response && error.response.status === 400) {
        history.push('/projects');
      }
    }
    return 'done';
  };
}

export function createNewProject(params: CreateProject) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ProjectService();
      const response = await req.createProject(params);
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Project Created!',
            type: 'success',
          }),
        );
        dispatch(resetProjectsState());
        dispatch(fetchAllProjects());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: false,
          header: 'Failed',
          body: e.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function updateExistingProject(params: Project) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ProjectService();
      const response = await req.updateProject(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Project updated',
            type: 'success',
          }),
        );
        //only reset state, projects page will fetch new projects if required.
        dispatch(resetProjectsState());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: e.response.data.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}
