import React from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { FlightButton } from '@flybits/webapp-design-system-react';
import './DatasourcesEmptyState.scss';

interface Props {
  showAvailableEmptyState: boolean;
  setShowAvailableDatasources(): void;
}

export default function DatasourcesEmptyState(props: Props) {
  const { showAvailableEmptyState, setShowAvailableDatasources } = props;
  const MAIN_CLASS = 'datasources-empty-state';

  return (
    <div className={MAIN_CLASS}>
      {showAvailableEmptyState ? (
        <div className={`${MAIN_CLASS}__browse-available`}>
          <PageHeader
            className={`${MAIN_CLASS}__browse-available__page-header`}
            title="Browse Datasources"
            isLarge={true}
          />
          <hr />
          <div className={`${MAIN_CLASS}__browse-available__content`}>
            <div className={`${MAIN_CLASS}__browse-available__content__image`}></div>
            <div className={`${MAIN_CLASS}__browse-available__content__description`}>
              No datasources are available to be used by this project.
            </div>
            <FlightButton
              label="Create a datasource"
              theme="link"
              iconRight="openInNew"
              //eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}} // Todo: Add link to SSO
            />
          </div>
        </div>
      ) : (
        <div className={`${MAIN_CLASS}__default`}>
          <div className={`${MAIN_CLASS}__default__content`}>
            <PageHeader className={`${MAIN_CLASS}__default__content__page-header`} title="Datasources" isLarge={true} />
            <div className={`${MAIN_CLASS}__default__content__description`}>
              Add datasources to your project to quickly connect to the datasets you need to power your experiences.
            </div>
            <FlightButton
              className={`${MAIN_CLASS}__default__content__flight-button`}
              label="Connect to available datasources"
              onClick={setShowAvailableDatasources}
            />
          </div>
          <div className={`${MAIN_CLASS}__default__image`}></div>
        </div>
      )}
    </div>
  );
}
