import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LandingPage } from '../pages/LandingPage';
import { ProjectsPage } from '../pages/ProjectsPage';
import Notifications from '../components/Shared/Notifications/Notifications';
import PushPage from '../pages/PushPage/PushPage';
import CombinedNavBar from 'components/CombinedNavBar/CombinedNavBar';
import AuthenticationPage from 'pages/AuthenticationPage/AuthenticationPage';
import AboutProjectPage from 'pages/AboutProjectPage/AboutProjectPage';
import ApiUsagePage from 'pages/ApiUsagePage/ApiUsagePage';
import ContentTemplatesPage from 'pages/ContentTemplatesPage/ContentTemplatesPage';
import ContextPluginsPage from 'pages/ContextPluginsPage/ContextPluginsPage';
import DatasourcesPage from 'pages/DatasourcesPage/DatasourcesPage';

export function Routes() {
  return (
    <div>
      <Notifications />
      <Switch>
        <Route exact={true} path="/" component={LandingPage} />
        <Route exact={true} path="/projects" component={ProjectsPage} />
        <CombinedNavBar>
          <Switch>
            <Route exact={true} path="/project/:id" component={AboutProjectPage} />
            <Route exact={true} path="/project/:id/api-usage" component={ApiUsagePage} />
            <Route exact={true} path="/project/:id/content-templates" component={ContentTemplatesPage} />
            <Route exact={true} path="/project/:id/context-plugins" component={ContextPluginsPage} />
            <Route exact={true} path="/project/:id/push" component={PushPage} />
            <Route exact={true} path="/project/:id/authentication" component={AuthenticationPage} />
            <Route exact={true} path="/project/:id/datasources" component={DatasourcesPage} />
          </Switch>
        </CombinedNavBar>
      </Switch>
    </div>
  );
}
