const VALUE_TYPE_OPTIONS = [
  {
    key: 'default',
    name: 'Any Type',
  },
  {
    key: 'string',
    name: 'String',
  },
  {
    key: 'int',
    name: 'Integer',
  },
  {
    key: 'bool',
    name: 'Boolean',
  },
  {
    key: 'float',
    name: 'Float',
  },
  {
    key: 'datetime',
    name: 'Date Time',
  },
]
export { VALUE_TYPE_OPTIONS };
