import { Notification, NotificationActionTypes, NotificationAction } from '../model/notifications';

export function showNotification(notification: Notification): NotificationAction {
  return {
    type: NotificationActionTypes.SHOW_NOTIFICATION,
    payload: notification,
  };
}

export function hideNotification(notification: Notification): NotificationAction {
  return {
    type: NotificationActionTypes.HIDE_NOTIFICATION,
    payload: notification,
  };
}

export function hideNotificationById(notification: Notification): NotificationAction {
  return {
    type: NotificationActionTypes.HIDE_NOTIFICATION_BY_ID,
    payload: notification,
  };
}
