import React, { useState } from 'react';
import { FlightModal, FlightButton, FlightTextInput } from '@flybits/webapp-design-system-react';
import './DeleteProject.scss';
import { Form, FormGroup } from 'reactstrap';
import { Project } from 'model/project';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { VALID_URL } from 'constants/errors/errors';
import { deleteProject } from 'actions/project';
import { useActions } from 'actions';

interface Props {
  projectFromState?: Project[];
}

const validationSchema = Yup.object().shape({
  confirmProjectName: Yup.string().required(VALID_URL),
});

export default function DeleteProject(props: Props) {
  const { projectFromState = [] } = props;
  let id = '';
  let name = '';
  let subDomain = '';
  if (projectFromState.length !== 0) {
    [{ id, name, subDomain }] = projectFromState;
  }
  const dispatchToDeleteProject = useActions(deleteProject);
  const [errorModal, setErrorModal] = useState(false);
  const errorToggle = () => setErrorModal(!errorModal);

  return (
    <div className="delete-project">
      <Formik
        initialValues={{ confirmProjectName: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          if (values.confirmProjectName === subDomain) {
            dispatchToDeleteProject(id);
          }
          resetForm();
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <div>
            <FlightModal
              isVisible={errorModal}
              toggleModalShown={errorToggle}
              size="medium"
              warning={true}
              header={
                <span className="delete-project__header">
                  Delete &apos;{name}&apos;. <br /> This action cannot be undone.
                </span>
              }
              content={
                <div>
                  To permanently delete your flybits project, enter &apos;{subDomain}&apos; below.
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="delete-project__field">
                      <Field
                        type="text"
                        name="confirmProjectName"
                        as={FlightTextInput}
                        width="332px"
                        id="confirmProjectName"
                        placeHolder="Enter projecrt Url"
                        value={values.confirmProjectName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="delete-project__header__error">
                        <ErrorMessage name={'confirmProjectName'} />
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              }
              footer={
                <div className="modal-footer">
                  <FlightButton theme="secondary" onClick={errorToggle} label="Cancel" />
                  <FlightButton type="submit" label="Delete" onClick={handleSubmit} />
                </div>
              }
            />
          </div>
        )}
      </Formik>
      <FlightButton className="delete-project__pill" label="Delete Project" iconLeft="trashCan" onClick={errorToggle} />{' '}
    </div>
  );
}
