import React, { useState } from 'react';
import { Form, FormGroup, Container, Row } from 'reactstrap';
import { FcmSettings } from 'model/fcmSettings';
import './FcmSettings.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SERVER_KEY_REQUIRED } from 'constants/errors/errors';
import * as FcmActions from 'actions/fcmSettings';
import { useActions } from 'actions';
import { ReactComponent as AndroidLogo } from 'assets/images/android.svg';
import { FlightButton, FlightModal, FlightTextArea } from '@flybits/webapp-design-system-react';
import { maskField } from 'helpers/maskField';

interface Props {
  fcmSettingsFromState?: FcmSettings[];
}

const validationSchema = Yup.object().shape({
  key: Yup.string().required(SERVER_KEY_REQUIRED),
});

export default function FcmSettingCard(props: Props) {
  const { fcmSettingsFromState = [] } = props;
  const pushActions = useActions(FcmActions);
  let key = '';

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const toggle = () => setOpen(!open);
  const confirmToggle = () => setOpenConfirm(!openConfirm);

  if (fcmSettingsFromState.length !== 0) {
    [{ key }] = fcmSettingsFromState;
  }

  function deleteFcmSettings() {
    pushActions.deleteFcmSettings();
    confirmToggle();
  }

  return (
    <div className="fcm-settings">
      <Formik
        enableReinitialize
        initialValues={{ key: key || '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          pushActions.addOrUpdateFcmServerKey(values.key);
          setSubmitting(true);
          resetForm();
          toggle();
        }}
      >
        {({ errors, values, handleChange, handleBlur, handleSubmit, isValid }) => (
          <div>
            <FlightModal
              isVisible={open}
              size="large"
              toggleModalShown={toggle}
              className="fcm-settings__modal"
              header={<span>FCM Server Key</span>}
              content={
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Field
                        type="text"
                        name="key"
                        id="key"
                        hideCharCounter={true}
                        label="Paste in your FCM server key"
                        as={FlightTextArea}
                        width="525px"
                        hasError={isValid ? false : true}
                        errorMessage={errors.key}
                        placeholderText="Paste in your FCM server key"
                        value={values.key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="fcm-settings__helper">
                        Your FCM server key can be found in your Firebase console settings cloud messaging tab.
                      </span>
                    </Row>
                  </FormGroup>
                </Form>
              }
              footer={
                <div className="modal-footer">
                  <FlightButton theme="secondary" onClick={toggle} label="Cancel" />
                  <FlightButton type="submit" label="Save" onClick={handleSubmit} disabled={!isValid} />
                </div>
              }
            />
          </div>
        )}
      </Formik>
      <div>
        <FlightModal
          isVisible={openConfirm}
          size="medium"
          toggleModalShown={confirmToggle}
          className="fcm-settings__modal"
          header={<span>Confirm</span>}
          content={
            <Row>
              <span>Are you sure you want to delete your FCM server key?</span>
            </Row>
          }
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={confirmToggle} label="Cancel" />
              <FlightButton type="submit" label="Continue" onClick={deleteFcmSettings} />
            </div>
          }
        />
      </div>
      <Container>
        <Row>
          <AndroidLogo />
          <span className="fcm-settings__header">FCM Server Key</span>
        </Row>
        <Row>
          {key !== '' ? (
            <span className="fcm-settings__body"></span>
          ) : (
            <span className="fcm-settings__body">You haven't specified your FCM server key.</span>
          )}
        </Row>
        <Row>
          <div className={key !== '' ? 'fcm-settings__key-con' : ''}>
            {key !== '' ? <div className="fcm-settings__key">{maskField(key).substring(0, key.length - 4)}</div> : null}
            {key !== '' ? (
              <span className="fcm-settings__key-end">{maskField(key).substring(key.length - 4, key.length)}</span>
            ) : null}
          </div>
        </Row>
        <Row>
          <FlightButton
            label={key === '' ? 'Add FCM server key' : 'Change'}
            size="medium"
            theme={key === '' ? 'secondary' : 'link'}
            iconRight={key === '' ? 'baselineArrowRight' : ''}
            className="fcm-settings__btn-blue"
            onClick={toggle}
          />
          <FlightButton
            label={key === '' ? '' : 'Remove'}
            size="medium"
            theme="link"
            className="fcm-settings__btn-red"
            onClick={confirmToggle}
          />
        </Row>
      </Container>
    </div>
  );
}
