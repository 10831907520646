import { NotificationAction, Notification, NotificationActionTypes } from '../model/notifications';
import createReducer from './createReducer';

export const notifications = createReducer<Notification[]>([], {
  [NotificationActionTypes.SHOW_NOTIFICATION](state: Notification[], action: NotificationAction) {
    return [
      ...state,
      {
        id: action.payload?.id,
        showNotification: action.payload?.showNotification,
        header: action.payload?.header,
        body: action.payload?.body,
        type: action.payload?.type,
      },
    ];
  },

  [NotificationActionTypes.HIDE_NOTIFICATION](state: Notification[]) {
    return [...state.splice(state.length)];
  },

  [NotificationActionTypes.HIDE_NOTIFICATION_BY_ID](state: Notification[], action: NotificationAction) {
    return [...state.filter(x => x.id !== action.payload?.id)];
  },
});
