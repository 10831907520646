import React, { ReactElement, useEffect } from 'react';
import { FlightNav, FlightButton } from '@flybits/webapp-design-system-react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import Jdenticon from 'components/Shared/Jdenticon/Jdenticon';
import * as AuthActions from 'actions/auth';
import { flatten } from 'lodash';
import { ProjectToken } from 'model/auth';
import {
  getProjectToken,
  setProjectTokenToStorage,
  deleteProjectTokenFromStorage,
  deleteAuthToken,
} from 'helpers/auth';
import { Project } from 'model/project';
import * as ProjectActions from 'actions/project';
import { history } from 'configureStore';

interface Props {
  children: ReactElement;
}

export default function CombinedNavBar(props: Props) {
  const { children } = props;
  const authActions = useActions(AuthActions);
  const projectFromState = useSelector((state: RootState) => state.project);
  const authState = useSelector((state: RootState) => state.auth);
  const projectTokenFromState = useSelector((state: RootState) => state.token);
  const projectsFromState = useSelector((state: RootState) => state.projects);
  const projectActions = useActions(ProjectActions);

  let id = '';
  let firstName = '';
  let lastName = '';
  let email = '';
  let level = 0;
  let tenantId = '';
  let name = '';
  let subDomain = '';
  let environment = '';

  if (authState.length !== 0) {
    [{ id, firstName, lastName, email, level }] = authState;
    if (level === 1) {
      authActions.resetAuthState();
    }
  } else {
    authActions.fetchSignedInUser();
  }

  if (projectFromState.length !== 0) {
    [{ id, name, subDomain }] = projectFromState;
    tenantId = id;
    if (subDomain) {
      environment = subDomain.split('-')[0];
    }
  }

  const routeParamsId = window.location.href.split('/')[4];
  function getToken() {
    if (projectTokenFromState.length !== 0) {
      const localTokenState = flatten(projectTokenFromState);
      const token = localTokenState.find(x => x.projectId === routeParamsId);
      return token as ProjectToken;
    }
    return null;
  }

  async function fetchToken() {
    const projectToken = await getProjectToken();
    if (projectToken) {
      return projectToken;
    } else {
      const projectTokenFromState = getToken();
      const token = projectTokenFromState?.projectToken || '';
      setProjectTokenToStorage(token, routeParamsId);
      return token || '';
    }
  }

  /* This function will retrieve the project from projects redux store,
      user will encounter this case if they're directed from projects page.
      If projects store is empty, it will fetch token from api, dispatch(fetch),
      user will encounter this case if they reload the page at anytime.
  */
  async function retrieveProject() {
    const localProjectsState = flatten(projectsFromState);
    if (localProjectsState.length !== 0) {
      const project = localProjectsState.find(project => project.id === routeParamsId);
      return project as Project;
    } else {
      projectActions.fetchAllProjects();
    }
  }

  /* Will set the project state
   */
  async function setProject() {
    const project = await retrieveProject();
    projectActions.resetProjectState();
    projectActions.setProjectState({
      id: project?.id,
      name: project?.name,
      subDomain: project?.subDomain,
      creatorId: project?.creatorID,
      isActive: project?.isActive,
      tierId: project?.tierID,
    });
  }

  const handleSignOut = () => {
    const envSsoUrl = process.env.REACT_APP_SSO_URL;
    const ssoUrl = `${envSsoUrl}#/unauthenticate`;
    window.location.replace(ssoUrl);
    deleteProjectTokenFromStorage();
    deleteAuthToken();
  };

  const handleGoBack = () => {
    deleteProjectTokenFromStorage();
    history.replace('/projects');
  };

  useEffect(() => {
    fetchToken();
    setProject();
    //eslint-disable-next-line
  }, [projectsFromState]);

  return (
    <FlightNav
      isActiveRouteExactMatch={true}
      user={{
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: level === 20 ? 'Owner' : 'User',
      }}
      routes={[
        {
          label: 'About this project',
          icon: 'userAttributes',
          location: `/project/${tenantId}`,
        },
        {
          label: 'API Usage',
          icon: 'artificialIntelligence',
          location: `/project/${tenantId}/api-usage`,
        },
        {
          label: 'Content Templates',
          icon: 'report',
          location: `/project/${tenantId}/content-templates`,
        },
        {
          label: 'Push Settings',
          icon: 'pushNotification',
          location: `/project/${tenantId}/push`,
        },
        {
          label: 'Context Plugins',
          icon: 'customerInsight',
          location: `/project/${tenantId}/context-plugins`,
        },
        {
          label: 'Connectors',
          icon: 'workflow',
          location: `/project/${tenantId}/connectors`,
        },
        {
          label: 'Datasources',
          icon: 'dataConfiguration',
          location: `/project/${tenantId}/datasources`,
        },
        {
          label: 'Authentication',
          icon: 'person',
          location: `/project/${tenantId}/authentication`,
        },
      ]}
      actionables={<FlightButton iconLeft="circleApps" theme="minor" onClick={() => undefined} />}
      project={{
        id: tenantId,
        name: name,
        environment: environment,
        image: <Jdenticon size="24" value={name} />,
      }}
      handleBackToProject={() => handleGoBack()}
      handleSignOut={() => handleSignOut()}
    >
      {children}
    </FlightNav>
  );
}
