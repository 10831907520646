import { LanguagesActions, LanguageActionTypes, Languages, ObjectForApi } from '../model/languages';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';

export function setLanguages(languages: Languages): LanguagesActions {
  return {
    type: LanguageActionTypes.SET_LANGUAGES_STATE,
    payload: languages,
  };
}

export function resetLanguages(): LanguagesActions {
  return {
    type: LanguageActionTypes.RESET_LANGUAGES_STATE,
  };
}

export function fetchAllLanguages() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getLanguages();
      dispatch(resetLanguages());
      dispatch(setLanguages(response.data.settings.data));
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        const errorObjectFromResponse = error.response.data.error.exceptionType;
        if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(addDefaultLanguage());
        }
      }
    }

    return 'done';
  };
}

export function addOrUpdateLanguage(languages: Languages[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: '{"data":' + JSON.stringify(languages) + '}',
        configName: {
          configName: 'languages',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchAllLanguages());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function addDefaultLanguage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const defaultLanguage: Languages[] = [{ id: 'en', isDefault: true, name: 'English' }];
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: '{"data":' + JSON.stringify(defaultLanguage) + '}',
        configName: {
          configName: 'languages',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        dispatch(fetchAllLanguages());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not add default language',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}
