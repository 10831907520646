import {
  NotificationTypes,
  NotificationTypesActions,
  NotificationTypesActionTypes,
  NotificationType,
  CreateNotificationType,
} from '../model/notificationTypes';
import { PushService } from '../services/push.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setNotificationTypes(notificationTypes: NotificationTypes): NotificationTypesActions {
  return {
    type: NotificationTypesActionTypes.SET_NOTIFICATION_TYPES_STATE,
    payload: notificationTypes,
  };
}

export function resetNotificationTypes(): NotificationTypesActions {
  return {
    type: NotificationTypesActionTypes.RESET_NOTIFICATION_TYPES_STATE,
  };
}

export function setNotificationType(notificationTypes: NotificationType): NotificationTypesActions {
  return {
    type: NotificationTypesActionTypes.SET_NOTIFICATION_TYPE_STATE,
    payload: notificationTypes,
  };
}

export function resetNotificationType(): NotificationTypesActions {
  return {
    type: NotificationTypesActionTypes.RESET_NOTIFICATION_TYPE_STATE,
  };
}

export function fetchNotificationTypes() {
  return async (dispatch: { (arg0: NotificationTypesActions): void }) => {
    try {
      const req = new PushService();
      const response = await req.getPushPayloadTypes();
      dispatch(resetNotificationTypes());
      dispatch(setNotificationTypes(response.data.data));
    } catch (error) {}
    return 'done';
  };
}

export function createNotificationType(params: CreateNotificationType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.createPushPayloadTypes(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Push Template created.',
            type: 'success',
          }),
        );
        dispatch(fetchNotificationTypes());
        dispatch(resetNotificationType());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Error creating notification type.',
          type: 'danger',
        }),
      );
    }
  };
}

export function updatePushNotificationType(params: NotificationType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.updatePushPayLoadTypes(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Status Updated.',
            type: 'success',
          }),
        );
        dispatch(fetchNotificationTypes());
        dispatch(resetNotificationType());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Error updating status.',
          type: 'danger',
        }),
      );
    }
  };
}

export function deleteNotificationType(notificationTypeId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.deletePushPayLoadTypes(notificationTypeId);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Notification Type Deleted.',
            type: 'success',
          }),
        );
        dispatch(fetchNotificationTypes());
        dispatch(resetNotificationType());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to delete type.',
          type: 'danger',
        }),
      );
    }
  };
}
