import React, { useState, useCallback, useEffect } from 'react';
import { FlightModal, FlightButton, FlightToggleSwitch } from '@flybits/webapp-design-system-react';
import { ReactComponent as SignedLoginLogo } from 'assets/images/idplogos/signed-login.svg';
import './IdpModal.scss';
import { Row, Col } from 'reactstrap';
import uploadIcon from 'assets/images/upload.svg';
import { useDropzone } from 'react-dropzone';
import * as SignedLoginActions from 'actions/signedLoginSettings';
import { useActions } from 'actions';
import { SignedLoginSettings } from 'model/signedLoginSettings';
import { ReactComponent as TrashIcon } from 'assets/images/trash_bin.svg';
import { ReactComponent as CopyIocn } from 'assets/images/copy-icon.svg';
import { maskField } from 'helpers/maskField';
import * as TenantSettingsActions from 'actions/tenantAccessSettings';
import { TenantAccessSettings, AllowedTenants } from 'model/tenantAccessSettings';
import * as NotificationsActions from 'actions/notifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  signedLoginSettingsFromState?: SignedLoginSettings[];
  tenantAccessSettingsFromState?: TenantAccessSettings[];
}

export default function SignedLoginModal(props: Props) {
  const { openModal, toggleModal, signedLoginSettingsFromState = [], tenantAccessSettingsFromState = [] } = props;
  const [checked, setChecked] = useState(false);
  const [checkedStateApi, setCheckedStateApi] = useState(false);
  const signedLoginSettings = useActions(SignedLoginActions);
  const tenantSettingsActions = useActions(TenantSettingsActions);
  const notficationActions = useActions(NotificationsActions);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFiles = new FormData();
      acceptedFiles.forEach((file: File) => {
        uploadedFiles.append('assetFile', file);
      }, signedLoginSettings.uploadSignedLoginCertificate(uploadedFiles));
    },
    [signedLoginSettings],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.crt',
  });

  let content = '';
  let isJustUploaded = false;
  if (signedLoginSettingsFromState.length !== 0) {
    [{ content, isJustUploaded }] = signedLoginSettingsFromState;
  }
  let allowed: AllowedTenants[] = [{ type: 0, value: '' }];
  if (tenantAccessSettingsFromState.length !== 0) {
    [{ allowed }] = tenantAccessSettingsFromState;
  }

  let allowedOidcSettings: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedOidcSettings = allowed.filter(item => item.value === 'signedlogin');
  }

  function handleDelete() {
    setChecked(false);
    const disableApiKeySettings = {
      allowed: allowed.filter(item => item.value !== 'signedlogin'),
    };
    tenantSettingsActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
    signedLoginSettings.deleteSignedLoginCertificate();
    signedLoginSettings.resetSignedLoginSettings();
  }

  function handleApplyChanges() {
    const allowedTenants: AllowedTenants[] = allowed;
    allowedTenants.push({
      type: 1,
      value: 'signedlogin',
    });
    if (checked) {
      const enableAPKeySettings = {
        allowed: allowedTenants,
      };
      tenantSettingsActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
    } else {
      const disableApiKeySettings = {
        allowed: allowed.filter(item => item.value !== 'signedlogin'),
      };
      tenantSettingsActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
    }
    signedLoginSettings.resetSignedLoginSettings();
    signedLoginSettings.setSignedLoginSettings({ content: content, isJustUploaded: false });
    toggleModal();
  }

  useEffect(() => {
    if (allowedOidcSettings.length !== 0 && allowedOidcSettings[0].value !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedOidcSettings]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  function copyContent() {
    navigator.clipboard.writeText(content);
    const uniqueId = generateUniqueId();
    notficationActions.showNotification({
      id: uniqueId,
      showNotfication: true,
      body: 'Copied to clipboard.',
      type: 'success',
    });
  }

  return (
    <div className="idp-modal">
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        content={
          <Col className="idp-modal__content">
            <Row className="idp-modal__content__content-header">
              <SignedLoginLogo className="idp-modal__content__content-header__header-logo" />
              <span className="idp-modal__content__content-header__header-text">Signed Login</span>
            </Row>
            <Col className="idp-modal__content__toggle">
              <Row>
                <FlightToggleSwitch checked={checked} onChange={setChecked} label={'Enable'} />
              </Row>
            </Col>
            <Col className="idp-modal__content__text-field-container">
              <Row className="idp-modal__content__text-field-container__text-field">Certificate</Row>
              {content === '' ? (
                <div>
                  <Row className="idp-modal__content__upload-box">
                    <section className="container">
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input name="file" id="fileValidation" {...getInputProps()} />
                        <img className="idp-modal__content__upload-box__img" src={uploadIcon} alt="uploadicon" />
                        <span className="idp-modal__content__upload-box__text" onClick={open}>
                          Upload certificate
                        </span>
                      </div>
                    </section>
                  </Row>
                  <Row>
                    <span className="idp-modal__content__upload-box__allowed-text">Only .crt files are accepted</span>
                  </Row>
                </div>
              ) : (
                <Row className="idp-modal__content__text-block">
                  <span className="idp-modal__content__text-block__display-uploaded">
                    {maskField(Object.values(content).toString()).substring(
                      Object.values(content).toString().length - 12,
                    )}
                    <CopyIocn
                      className="idp-modal__content__text-block__display-uploaded__img
                         "
                      onClick={copyContent}
                    />
                  </span>
                  <TrashIcon className="idp-modal__content__text-block__delete" onClick={handleDelete} />
                </Row>
              )}
            </Col>
          </Col>
        }
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                if (isJustUploaded) {
                  handleDelete();
                }
                toggleModal();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton type="primary" label="Apply Changes" disabled={content === ''} onClick={handleApplyChanges} />
          </div>
        }
      />
    </div>
  );
}
