import BaseService from './base.service';
import { ContentTemplateAttributes } from 'model/contentTemplates';

interface GetContentTemplates {
  limit: number;
}

export default class ContentService extends BaseService {
  CURRENT_PATH: string;
  CURRENT_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'kernel/';
    this.CURRENT_RESOURCE = 'content/templates';
  }

  async getAllContentTemplates(params: GetContentTemplates) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_RESOURCE, params);
  }

  async createContentTemplate(data: ContentTemplateAttributes) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }

  async deleteContentTemplates(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + id);
  }
}
