import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import * as DatasourceActions from 'actions/datasource';
import cloneDeep from 'lodash/cloneDeep';
import { FlightButton } from '@flybits/webapp-design-system-react';
import { Datasource, GetDatasourcesParams } from 'model/datasource';
import DatasourcesEmptyState from 'components/DatasourcesComponents/DatasourcesEmptyState';
import DatasourceInfo from 'components/DatasourcesComponents/DatasourceInfo';
import DatasourcesTable from 'components/DatasourcesComponents/DatasourcesTable';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import './DatasourcesPage.scss';
import { history } from 'configureStore';

export default function DatasourcesPage() {
  const authFromState = useSelector((state: RootState) => state.auth);
  const datasourcesDataFromState = useSelector((state: RootState) => state.datasources);
  const datasourceActions = useActions(DatasourceActions);
  const [currentDatasourceId, setCurrentDatasourceId] = useState('');
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [showAvailableDatasources, setShowAvailableDatasources] = useState(false);
  const [{ id }] = authFromState;
  const [searchTerm, setSearchTerm] = useState(
    history.location.search.substring(history.location.search.indexOf('=') + 1),
  );
  const pageHeaderTitle = showAvailableDatasources ? 'Browse Datasources' : 'Datasources';
  const MAIN_CLASS = 'datasources';

  let datasources = cloneDeep(datasourcesDataFromState).datasources || [];
  if (!showAvailableDatasources) {
    datasources = datasources.filter((datasource: Datasource) => {
      return datasource.hasTenantConnection;
    });
  }

  const currentDatasource = currentDatasourceId
    ? datasources.find(datasource => datasource.id === currentDatasourceId)
    : null;

  const queryParam = new URLSearchParams({
    searchTerm,
  });

  async function fetchDatasources(params: GetDatasourcesParams) {
    await datasourceActions.fetchAllDatasources(params);
  }

  function handleSearchTermChange(value: string) {
    setSearchTerm(value);
  }

  async function addDatasourceToProject(datasourceId: string) {
    await datasourceActions.createTenantConnection({
      connectorInstanceId: datasourceId,
      status: 'enabled',
      config: {},
    });
    fetchDatasources({ search: searchTerm });
  }

  async function removeDatasourceFromProject(tenantConnectionId: string) {
    await datasourceActions.deleteTenantConnection(tenantConnectionId);
    fetchDatasources({ search: searchTerm });
    if (currentDatasourceId) {
      setCurrentDatasourceId('');
    }
  }

  function handleCurrentDatasourceIdChange(datasourceId: string) {
    setCurrentDatasourceId(datasourceId);
  }

  function toggleShowAvailableDatasources() {
    setShowAvailableDatasources(!showAvailableDatasources);
  }

  useEffect(() => {
    fetchDatasources({ search: searchTerm });
    if (searchTerm !== '') {
      history.push({
        search: `${queryParam}`,
      });
    } else {
      history.push({
        search: ``,
      });
    }
    //eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    if (history.location.search === '' && datasources.length === 0) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
    //eslint-disable-next-line
  }, [datasources && datasources.length === 0]);

  return (
    <div>
      {showEmptyState ? (
        <DatasourcesEmptyState
          showAvailableEmptyState={showAvailableDatasources}
          setShowAvailableDatasources={toggleShowAvailableDatasources}
        />
      ) : (
        <div className={MAIN_CLASS}>
          {currentDatasource ? (
            <DatasourceInfo
              datasource={currentDatasource}
              addDatasourceToProject={addDatasourceToProject}
              removeDatasourceFromProject={removeDatasourceFromProject}
            />
          ) : (
            <div>
              <div className={`${MAIN_CLASS}__header`}>
                <PageHeader title={pageHeaderTitle} isLarge={true} className={`${MAIN_CLASS}__header__title`} />
                <div className={`${MAIN_CLASS}__header__links`}>
                  <FlightButton
                    label="Manage datasources"
                    theme="link"
                    iconRight="openInNew"
                    className={`${MAIN_CLASS}__header__link`}
                    //eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={() => {}} // Todo: add link to SSO
                  />
                  <FlightButton
                    label="Create a datasource"
                    theme="link"
                    iconRight="openInNew"
                    className={`${MAIN_CLASS}__header__link`}
                    //eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={() => {}} // Todo: add link to SSO
                  />
                  {!showAvailableDatasources && (
                    <FlightButton label="Browse available datasources" onClick={toggleShowAvailableDatasources} />
                  )}
                </div>
              </div>
              <SearchBar initialValue={searchTerm} handleSearchTermChange={handleSearchTermChange} width="296px" />
              <DatasourcesTable
                currentId={id}
                datasources={datasources}
                showAvailableDatasources={showAvailableDatasources}
                handleCurrentDatasourceIdChange={handleCurrentDatasourceIdChange}
                addDatasourceToProject={addDatasourceToProject}
                removeDatasourceFromProject={removeDatasourceFromProject}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
