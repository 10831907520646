import React, { useState, useEffect, useCallback } from 'react';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import { flatten, cloneDeep, uniqBy } from 'lodash';
import {
  ContentTemplate,
  ContentTemplatesDownload,
  ContentTemplateData,
  ContentTemplateAttributes,
} from 'model/contentTemplates';
import TemplateCard from 'components/Shared/TemplateCard/TemplateCard';
import { Row, Col } from 'reactstrap';
import './ContentTemplates.scss';
import { ReactComponent as FolderIcon } from 'assets/images/folder.svg';
import { ContentTemplateSettings } from 'model/contentTemplateSettings';
import {
  FlightButton,
  FlightTextInput,
  FlightModal,
  FlightSnackbar,
  FlightCheckbox,
} from '@flybits/webapp-design-system-react';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import ContentIframeParent from './ContentIframeParent';
import FullPageOverlay from './FullPageOverlay';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import { useActions } from 'actions';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import * as AuthActions from 'actions/auth';
import { useDropzone } from 'react-dropzone';
import { CONTENT_TEMPLATE_DUPLICATION } from 'constants/banners/banners';
import { Project } from 'model/project';
import { ProjectToken } from 'model/auth';
import { downloadAsJson } from 'helpers/downloadAsJson';
import { importHelper } from 'helpers/importHelper';

interface Props {
  contentTemplatesFromState?: ContentTemplate[];
  contentTemplateSettingsFromState?: ContentTemplateSettings[];
  projectsFromState?: Project[];
  projectTokenFromState?: ProjectToken[];
}

interface Localizations {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
  name?: string;
}

export default function ContentTemplatesList(props: Props) {
  const {
    contentTemplatesFromState = [],
    contentTemplateSettingsFromState = [],
    projectsFromState = [],
    projectTokenFromState = [],
  } = props;
  const localContentTemplatesFromState = flatten(cloneDeep(contentTemplatesFromState));
  const [isTemplateEditIframeVisible, setIsTemplateEditIframeVisible] = useState(false);
  const localContentTemplateSettingsFromState = cloneDeep(contentTemplateSettingsFromState);
  const localProjectsFromState = flatten(cloneDeep(projectsFromState));
  const [categories, setCategories] = useState('Starter Templates');
  const templateCategories = flatten(localContentTemplateSettingsFromState.map(({ categories }) => categories));
  const [selectCheckbox, setSelectCheckbox] = useState(['']);
  const [searchTerm, setSearchTerm] = useState('');
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const authActions = useActions(AuthActions);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectTemplatesName, setSelectedTemplateName] = useState<Localizations[]>([]);
  const [projectSelectCheckbox, setProjectSelectCheckbox] = useState(['']);
  const [templatesToRender, setTemplatesToRender] = useState<ContentTemplate[]>([]);
  const [templateId, setTemplateId] = useState('');
  let starterTemplates: ContentTemplate[] = [];
  let myTemplates: ContentTemplate[] = [];
  let searchedTerm: ContentTemplate[] = [];

  if (localContentTemplatesFromState.length !== 0) {
    starterTemplates = localContentTemplatesFromState.filter(template => {
      return template.labels.includes('starter');
    });
    myTemplates = localContentTemplatesFromState.filter(template => {
      return !template.labels.includes('starter');
    });
  }

  const toggleCheckbox = (id: string) => {
    if (selectCheckbox.includes(id)) {
      const cloneCheckboxState = selectCheckbox;
      const filterCheckboxState = cloneCheckboxState.filter(removeId => {
        return removeId !== id;
      });
      setSelectCheckbox(filterCheckboxState);
    } else {
      setSelectCheckbox(selectCheckbox.concat(id));
    }
    return 'done';
  };

  const toggleCategories = (displayName: string) => {
    setCategories(displayName);
    if (displayName === 'Starter Templates') {
      setTemplatesToRender(starterTemplates);
    } else {
      setTemplatesToRender(myTemplates);
    }
  };
  useEffect(() => {
    if (templatesToRender.length === 0) {
      if (templateCategories.length !== 0 && categories === 'Starter Templates') {
        setTemplatesToRender(starterTemplates);
      } else {
        setTemplatesToRender(myTemplates);
      }
    } else if (
      (templatesToRender.length !== myTemplates.length &&
        templateCategories.length !== 0 &&
        categories === 'My Templates') ||
      (templateCategories.length === 0 && categories === 'Starter Templates')
    ) {
      setTemplatesToRender(myTemplates);
    }
    // eslint-disable-next-line
  }, [contentTemplatesFromState]);

  if (searchTerm !== '') {
    searchedTerm = searchWithRegExp(localContentTemplatesFromState, searchTerm);
  }
  //* handler functions below
  const handleCancel = () => {
    setSelectCheckbox(['']);
  };

  const handleSelectAll = () => {
    const allIds = localContentTemplatesFromState.map(temp => {
      if (!temp.internal) {
        return temp.id;
      }
      return '';
    });
    allIds.push('');
    setSelectCheckbox(allIds);
  };

  const handleExport = (id?: string) => {
    const exportables: ContentTemplate[] = [];
    if (typeof id === 'string') {
      templatesToRender.forEach(template => {
        if (id === template.id) {
          exportables.push(template);
        }
      });
    } else {
      const selectedItems = selectCheckbox;
      templatesToRender.forEach(template => {
        if (selectedItems.includes(template.id)) {
          exportables.push(template);
        }
      });
    }

    const template: ContentTemplateData[] = [];
    exportables.forEach(item => {
      template.push({
        id: item.id,
        attributes: {
          createdAt: item.createdAt,
          iconUrl: item?.iconUrl,
          localizations: item?.localizations,
          modifiedAt: item?.modifiedAt,
          schema: item?.schema,
          templateId: item?.id,
          tenantId: item?.tenantId,
          type: item?.type,
          labels: item?.labels,
        },
        type: 'content-template',
      });
    });

    const downloadData: ContentTemplatesDownload = {
      data: template,
      meta: {
        downloadedAt: Date.now(),
        totalRecords: template.length,
      },
    };
    downloadAsJson(Date.now().toString(), JSON.stringify(downloadData), 'content-templates');
    setSelectCheckbox(['']);
  };

  const handleImport = async (args: string) => {
    const prepareUploadedDarta = importHelper(args);
    const parseData: ContentTemplatesDownload = JSON.parse(prepareUploadedDarta);
    const contentForUpload: ContentTemplateAttributes[] = [];
    parseData.data.forEach(item => {
      contentForUpload.push({
        createdAt: Date.now(),
        iconUrl: item.attributes.iconUrl,
        localizations: item.attributes.localizations,
        modifiedAt: Date.now(),
        schema: item.attributes.schema,
        type: item.attributes.type,
        tenantId: item.attributes.tenantId,
        templateId: item.attributes.templateId,
        labels: item.attributes.labels,
      });
    });

    if (contentForUpload.length !== 0) {
      contentForUpload.forEach(async template => {
        await contentTemplatesActions.createNewContentTemplate(template);
      });
    }
  };

  const selectedTemplates = (id?: string) => {
    const selectedItems = selectCheckbox;
    const exportables: Localizations[] = [];

    if (typeof id === 'string') {
      templatesToRender.forEach(template => {
        if (id === template.id) {
          exportables.push(template.localizations);
        }
      });
    } else {
      templatesToRender.forEach(template => {
        if (selectedItems.includes(template.id)) {
          exportables.push(template.localizations);
        }
      });
    }
    const templateNames: Localizations[] = [];
    exportables.map(langCode => {
      return templateNames.push(langCode.en.name);
    });
    setSelectedTemplateName(templateNames);
  };

  const triggerDeleteModal = (id?: string) => {
    setDeleteModal(deleteModal => !deleteModal);
    selectedTemplates(id);
  };

  const handleDelete = (id?: string) => {
    if (typeof id === 'string') {
      contentTemplatesActions.deleteContentTemplates(id);
    } else {
      const selectedTemplates = selectCheckbox.filter(checked => checked !== '');
      if (selectedTemplates.length === 0 && templateId) {
        contentTemplatesActions.deleteContentTemplates(templateId);
      }
      selectedTemplates.map(id => {
        return contentTemplatesActions.deleteContentTemplates(id);
      });
      setSelectCheckbox(['']);
    }
    triggerDeleteModal();
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const parseUploadedFile = new FileReader();
      parseUploadedFile.readAsDataURL(file);
      parseUploadedFile.onload = () => {
        if (!!parseUploadedFile.result) {
          const json = JSON.stringify(parseUploadedFile.result as string);
          handleImport(json);
        }
      };
    });
    // eslint-disable-next-line
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.content-templates',
  });

  const triggerDuplicateModal = (id?: string) => {
    setDuplicateModal(duplicateModal => !duplicateModal);
    selectedTemplates(id);
  };

  const [showProjectList, setShowProjectList] = useState(false);
  const continueToDuplication = () => {
    setShowProjectList(true);
  };

  const handleProjectSelectCheckbox = (id: string) => {
    if (projectSelectCheckbox.includes(id)) {
      const cloneCheckboxState = projectSelectCheckbox;
      const filterCheckboxState = cloneCheckboxState.filter(removeId => {
        return removeId !== id;
      });
      setProjectSelectCheckbox(filterCheckboxState);
    } else {
      setProjectSelectCheckbox(projectSelectCheckbox.concat(id));
    }
  };

  const handleCancelDuplicateModal = () => {
    setProjectSelectCheckbox(['']);
    setSelectCheckbox(['']);
    setShowProjectList(false);
    triggerDuplicateModal();
    setTemplateId('');
  };
  const handleCancelDeleteModal = () => {
    setProjectSelectCheckbox(['']);
    setSelectCheckbox(['']);
    setShowProjectList(false);
    triggerDeleteModal();
    setTemplateId('');
  };
  const onIframeSubmit = () => {
    setIsTemplateEditIframeVisible(false);
    contentTemplatesActions.fetchContentTemplates();
    setTemplateId('');
  };
  const onIframeCancel = () => {
    setIsTemplateEditIframeVisible(false);
    setTemplateId('');
  };
  const [projectToken, setProjectToken] = useState<ProjectToken[]>([]);
  const cloneProjectSelect = projectSelectCheckbox.filter(checked => checked !== '');
  useEffect(() => {
    projectTokenFromState.map(project => {
      if (cloneProjectSelect.includes(project.projectId)) {
        setProjectToken([...projectToken, { projectId: project.projectId, projectToken: project.projectToken }]);
      }
      return 'next';
    });
    //eslint-disable-next-line
  }, [projectTokenFromState]);

  const handleDuplicateNext = () => {
    if (!showProjectList) {
      continueToDuplication();
    } else {
      cloneProjectSelect.forEach(async tenantId => {
        await authActions.fetchProjectToken(tenantId);
      });
    }
  };

  const handleAfterTokenFetch = (id?: string) => {
    const exportables: ContentTemplate[] = [];
    const contentForUpload: ContentTemplateAttributes[] = [];
    if (id) {
      templatesToRender.forEach(template => {
        if (id === template.id) {
          exportables.push(template);
        }
      });
    } else {
      const selectedItems = selectCheckbox;
      templatesToRender.forEach(template => {
        if (selectedItems.includes(template.id)) {
          exportables.push(template);
        }
      });
    }

    const cloneProjectSelectState = projectSelectCheckbox;
    const filterCheckboxState = cloneProjectSelectState.filter(removeId => {
      return removeId !== '';
    });

    const projectTokenClone = uniqBy(projectToken, 'projectId');
    if (filterCheckboxState.length === projectTokenClone.length) {
      projectTokenClone.map(project => {
        return exportables.map(item => {
          return contentForUpload.push({
            createdAt: Date.now(),
            iconUrl: item.iconUrl,
            localizations: item.localizations,
            modifiedAt: Date.now(),
            schema: item.schema,
            type: item.type,
            tenantId: project?.projectId,
            templateId: '',
            labels: item.labels,
            projectTokenForApiCall: project?.projectToken,
          });
        });
      });

      contentForUpload.forEach(async template => {
        const response = await contentTemplatesActions.createNewContentTemplate(template);
        if (response === 'done') {
          setProjectSelectCheckbox(['']);
          setSelectCheckbox(['']);
          setShowProjectList(false);
          setDuplicateModal(false);
          setProjectToken([...projectToken.splice(projectToken.length)]);
        }
      });
    } else {
      return;
    }
    setTemplateId('');
  };

  useEffect(() => {
    let tempId;
    if (templateId !== '') {
      tempId = templateId;
    }
    handleAfterTokenFetch(tempId);
    //eslint-disable-next-line
  }, [projectToken]);

  const toggleExport = (id: string) => {
    handleExport(id);
  };

  const toggleDuplicate = (id: string) => {
    setTemplateId(id);
    triggerDuplicateModal(id);
  };

  const toggleDelete = (id: string) => {
    setTemplateId(id);
    triggerDeleteModal(id);
  };
  const toggleEdit = (id: string) => {
    setIsTemplateEditIframeVisible(true);
    setTemplateId(id);
  };
  const createTemplate = () => {
    setIsTemplateEditIframeVisible(true);
  };
  return (
    <div className="content-templates">
      <FlightModal
        isVisible={deleteModal}
        size="medium"
        toggleModalShown={triggerDeleteModal}
        className="content-templates__delete-modal"
        header={<span>Confirm Delete</span>}
        content={
          <span className="content-templates__delete-modal__text">
            Are you sure you want to delete these content templates?
          </span>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={handleCancelDeleteModal} label="Cancel" />
            <FlightButton type="submit" label="Continue" onClick={handleDelete} />
          </div>
        }
      />
      <FlightModal
        className="content-templates__duplicate-modal"
        isVisible={duplicateModal}
        toggleModalShown={triggerDuplicateModal}
        scrollable={false}
        size="medium"
        header={!showProjectList ? <span>Confirm</span> : <span>Select Projects</span>}
        content={
          !showProjectList ? (
            <div>
              <span>The following Content templates will be copied to another project. Do you wish to continue?</span>
              <FlightSnackbar
                isVisible={true}
                icon="infoFilled"
                title=""
                className="content-templates__duplicate-modal__banner"
                content={CONTENT_TEMPLATE_DUPLICATION}
                type="warning"
                isAutoDismiss={true}
                actionName=""
              />
              <div className="content-templates__duplicate-modal__template-container">
                {selectTemplatesName.map(templateName => (
                  <div key={Math.random()} className="content-templates__duplicate-modal__template-container__names">
                    {templateName}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <span className="content-templates__duplicate-modal__template-container__modal-header">
                Select which active Project(s) you would like to copy to:
              </span>
              {localProjectsFromState.map(project => (
                <div key={Math.random()} className="content-templates__duplicate-modal__template-container__names">
                  <span>
                    <FlightCheckbox
                      checkState={projectSelectCheckbox.includes(project.id) ? 'SELECTED' : 'UNSELECTED'}
                      className="content-templates__duplicate-modal__template-container__select"
                      onSelect={() => handleProjectSelectCheckbox(project.id)}
                    />
                  </span>
                  <span className="content-templates__duplicate-modal__template-container__text">{project.name}</span>
                </div>
              ))}
            </div>
          )
        }
        footer={
          <div className="modal-footer">
            <FlightButton onClick={handleCancelDuplicateModal} label="Cancel" theme="secondary" />
            <FlightButton
              type="submit"
              label={!showProjectList ? 'Continue' : 'Export'}
              onClick={handleDuplicateNext}
            />
          </div>
        }
      />
      <span className="pt-3 pb-3 content-templates__greeting-text">
        Hi there, this is where you manage the content templates that appear on Experience Studio.
      </span>
      <Row>
        {templateCategories.length !== 0 && searchTerm === '' ? (
          <Col xs="3" className="pt-3">
            <Row xs="1" className="content-templates__template-categories">
              <ReusableCard>
                <Row className="content-templates__template-categories__header">Template Categories</Row>
                {templateCategories.map(template => (
                  <Row
                    key={Math.random()}
                    className={`content-templates__template-categories__item${
                      template.displayName === categories ? '--active' : ''
                    }`}
                  >
                    <FolderIcon className="content-templates__template-categories__item__icon" />{' '}
                    <Row
                      className="content-templates__template-categories__item__text"
                      onClick={() => toggleCategories(template.displayName)}
                    >
                      {template.displayName}
                    </Row>
                  </Row>
                ))}
              </ReusableCard>
            </Row>
          </Col>
        ) : null}
        <Col xs="9" className="pt-5">
          {selectCheckbox.length <= 1 ? (
            <Row xs="2" className="pb-3">
              <FlightTextInput
                placeholderText="Search for a template"
                type="text"
                name="search"
                className="content-templates__search-field"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <SearchIcon className="content-templates__search-icon" />
              {categories.includes('My Templates') || templateCategories.length === 0 ? (
                <div>
                  <FlightButton
                    label={'New Content Template'}
                    size="medium"
                    type="primary"
                    iconLeft={'add'}
                    className={
                      !duplicateModal && !deleteModal ? 'content-templates__btn' : 'content-templates__btn--modal-open'
                    }
                    onClick={createTemplate}
                  />
                  <input name="file" id="fileValidation" {...getInputProps()} />

                  <FlightButton
                    label={'Import'}
                    size="medium"
                    theme="secondary"
                    className={
                      !duplicateModal && !deleteModal ? 'content-templates__btn' : 'content-templates__btn--modal-open'
                    }
                    onClick={open}
                  />
                </div>
              ) : null}
            </Row>
          ) : (
            <Row className="pb-3">
              <span className="content-templates__text">
                {' '}
                {selectCheckbox.length - 1}{' '}
                {selectCheckbox.length === 2 ? 'content template selected.' : 'content templates selected.'}
              </span>
              <span className="content-templates__cancel" onClick={handleCancel}>
                Cancel
              </span>
              <FlightButton
                label={'Select All'}
                size="medium"
                theme="secondary"
                className="content-templates__select-opts"
                onClick={handleSelectAll}
              />
              <FlightButton
                label={'Export'}
                size="medium"
                theme="secondary"
                className="content-templates__select-opts"
                onClick={handleExport}
              />
              <FlightButton
                label={'Duplicate'}
                size="medium"
                theme="secondary"
                className="content-templates__select-opts"
                onClick={triggerDuplicateModal}
              />
              <FlightButton
                label={'Delete'}
                size="medium"
                theme="secondary"
                className="content-templates__select-opts"
                onClick={triggerDeleteModal}
              />
            </Row>
          )}
          <div
            className={
              !duplicateModal && !deleteModal ? 'content-templates__shadow' : 'content-templates__shadow--modal-open'
            }
          ></div>
          <div className="content-templates__template-wrapper" id="wrapper">
            <div
              className={
                !duplicateModal && !deleteModal ? 'content-templates__cover' : 'content-templates__cover--modal-open'
              }
            ></div>
            <Row xs="3" className="content-templates__template-container">
              {searchTerm === ''
                ? templatesToRender.map(template => (
                    <div key={Math.random()}>
                      <TemplateCard
                        internal={template.internal}
                        checkBoxState={selectCheckbox?.includes(template.id) ? template.id : ''}
                        toggleCheckbox={toggleCheckbox}
                        toggleDelete={toggleDelete}
                        toggleEdit={toggleEdit}
                        toggleDuplicate={toggleDuplicate}
                        toggleExport={toggleExport}
                        templateState={template}
                      ></TemplateCard>
                    </div>
                  ))
                : searchedTerm.map(template => (
                    <div key={Math.random()}>
                      <TemplateCard
                        internal={template.internal}
                        checkBoxState={selectCheckbox?.includes(template.id) ? template.id : ''}
                        toggleCheckbox={toggleCheckbox}
                        toggleDelete={toggleDelete}
                        toggleEdit={toggleEdit}
                        toggleDuplicate={toggleDuplicate}
                        toggleExport={toggleExport}
                        templateState={template}
                      ></TemplateCard>
                    </div>
                  ))}
            </Row>
          </div>
        </Col>
      </Row>
      <FullPageOverlay isVisible={isTemplateEditIframeVisible}>
        <ContentIframeParent
          onSubmit={() => onIframeSubmit()}
          onCancel={() => onIframeCancel()}
          templateId={templateId}
          projectTokenFromState={projectTokenFromState}
        />
      </FullPageOverlay>
    </div>
  );
}
