import React, { useEffect } from 'react';
import ContentIframe from './ContentIframe';
import './ContentIframeParent.scss';
import { ProjectToken } from 'model/auth';

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  templateId: string;
  projectTokenFromState: ProjectToken[];
}

export default function ContentIframeParent(props: IProps) {
  useEffect(() => {
    window.addEventListener('message', function(evt) {
      if (evt.origin === 'http://127.0.0.1:4100' || evt.origin === 'https://devportal.flybits.com/') {
        props.onSubmit();
      }
    });
  });

  return (
    <>
      <div className="template-builder">
        <div className="main">
          <div className="iframe-container">
            <ContentIframe
              isVisible={true}
              templateId={props.templateId}
              projectTokenFromState={props.projectTokenFromState}
            />
          </div>
        </div>
      </div>
    </>
  );
}
