import { AuthAction, Auth, AuthActionTypes, ProjectToken } from '../model/auth';

import createReducer from './createReducer';

export const auth = createReducer<Auth[]>([], {
  [AuthActionTypes.SET_AUTH_STATE](state: Auth[], action: AuthAction) {
    return [...state, action.payload];
  },
  [AuthActionTypes.RESET_AUTH_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const token = createReducer<Auth[]>([], {
  [AuthActionTypes.SET_PROJECT_TOKEN](state: ProjectToken[], action: AuthAction) {
    return [...state, action.payload];
  },
  [AuthActionTypes.RESET_PROJECT_TOKEN](state: ProjectToken[]) {
    return state.filter(item => !item);
  },
});
