import BaseService from './base.service';
import { Subscription, GetRatelimits } from 'model/subscriptions';

export default class SubscriptionService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_RESOURCE: string;
  SECONDARY_RESOURCE: string;
  OPTIONAL_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'sso/';
    this.PRIMARY_RESOURCE = 'apikey-management/';
    this.SECONDARY_RESOURCE = 'subscriptions';
    this.OPTIONAL_RESOURCE = 'ratelimits/';
  }

  async getAllSubscriptions() {
    return this.request('GET', this.CURRENT_PATH + this.PRIMARY_RESOURCE + this.SECONDARY_RESOURCE);
  }

  async createNewSubscription(data: Subscription) {
    return this.request('POST', this.CURRENT_PATH + this.PRIMARY_RESOURCE + this.SECONDARY_RESOURCE, data);
  }

  async updateSubscription(data: Subscription) {
    return this.request(
      'PUT',
      this.CURRENT_PATH + this.PRIMARY_RESOURCE + this.SECONDARY_RESOURCE + '/' + data.id,
      data,
    );
  }

  async deleteSubscription(data: Subscription) {
    return this.request('DELETE', this.CURRENT_PATH + this.PRIMARY_RESOURCE + this.SECONDARY_RESOURCE + '/' + data.id);
  }

  async getRateLimits(params: GetRatelimits) {
    return this.request('GET', this.CURRENT_PATH + this.PRIMARY_RESOURCE + this.OPTIONAL_RESOURCE + params.id, params);
  }
}
