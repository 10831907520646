export interface ApiKeySettings {
  apiKey: string;
}

export interface ApiKeySettingsFromApi {
  apikey: string;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum ApiKeySettingsActionTypes {
  SET_APIKEYSETTINGS_STATE = 'SET_APIKEYSETTINGS_STATE',
  RESET_APIKEYSETTINGS_STATE = 'RESET_APIKEYSETTINGS_STATE',
}

interface ApiKeySettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type ApiKeySettingsActions =
  | ApiKeySettingsActionCreatorType<typeof ApiKeySettingsActionTypes.SET_APIKEYSETTINGS_STATE, ApiKeySettings>
  | ApiKeySettingsActionCreatorType<typeof ApiKeySettingsActionTypes.RESET_APIKEYSETTINGS_STATE, ApiKeySettings>;
