import { SubscriptionAction, Subscription, SubscriptionActionTypes } from '../model/subscriptions';
import createReducer from './createReducer';

export const subscriptions = createReducer<Subscription[]>([], {
  [SubscriptionActionTypes.SET_SUBSCRIPTIONS_STATE](state: Subscription[], action: SubscriptionAction) {
    return [...state, action.payload];
  },

  [SubscriptionActionTypes.RESET_SUBSCRIPTIONS_STATE](state: []) {
    return state.filter(item => !item);
  },
});
