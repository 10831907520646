import { LanguagesActions, LanguageActionTypes, Languages } from '../model/languages';
import createReducer from './createReducer';

export const languages = createReducer<Languages[]>([], {
  [LanguageActionTypes.SET_LANGUAGES_STATE](state: Languages[], action: LanguagesActions) {
    return [...state, action.payload];
  },

  [LanguageActionTypes.RESET_LANGUAGES_STATE](state: []) {
    return state.filter(item => !item);
  },
});
