import React from 'react';
import './GraphUsage.scss';

import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';

export default function GraphUsage() {



  return (
    <ReusableCard>
      <div className="graph-usage-card">Monthly chart showing the usage in a graph by category.</div>
    </ReusableCard>
  );
}
