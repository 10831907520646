export interface FacebookSettings {
  clientID: string;
  secretKey: string;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum FacebookSettingsActionTypes {
  SET_FACEBOOKSETTINGS_STATE = 'SET_FACEBOOKSETTINGS_STATE',
  RESET_FACEBOOKSETTINGS_STATE = 'RESET_FACEBOOKSETTINGS_STATE',
}

interface FacebookSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type FacebookSettingsActions =
  | FacebookSettingsActionCreatorType<typeof FacebookSettingsActionTypes.SET_FACEBOOKSETTINGS_STATE, FacebookSettings>
  | FacebookSettingsActionCreatorType<
      typeof FacebookSettingsActionTypes.RESET_FACEBOOKSETTINGS_STATE,
      FacebookSettings
    >;
