import { AuthAction, AuthActionTypes, Auth, ProjectToken } from '../model/auth';
import AuthService from '../services/auth.service';
import { getAuthOrProjectToken, setAuthToken } from '../helpers/auth';

export function setAuthState(auth: Auth): AuthAction {
  return {
    type: AuthActionTypes.SET_AUTH_STATE,
    payload: auth,
  };
}

export function resetAuthState(): AuthAction {
  return {
    type: AuthActionTypes.RESET_AUTH_STATE,
  };
}

export function setProjectToken(token: ProjectToken): AuthAction {
  return {
    type: AuthActionTypes.SET_PROJECT_TOKEN,
    payload: token,
  };
}

export function resetProjectToken(): AuthAction {
  return {
    type: AuthActionTypes.RESET_PROJECT_TOKEN,
  };
}

export function fetchSignedInUser() {
  return async (dispatch: { (arg0: AuthAction): void }) => {
    try {
      const req = new AuthService();
      const response = await req.getSignedInUser();
      dispatch(
        setAuthState({
          id: response.data.id,
          firstName: response.data.firstName,
          isConfirmed: response.data.isConfirmed,
          lastName: response.data.lastName,
          email: response.data.email,
          level: response.data.level,
        }),
      );
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function fetchProjectToken(projectId: string) {
  return async (dispatch: { (arg0: AuthAction): void }) => {
    try {
      const params = {
        projectId: projectId,
      };
      const req = new AuthService();
      const response = await req.getProjectToken(params);
      const projectToken = response.headers['x-authorization'];
      dispatch(setProjectToken({ projectId: projectId, projectToken: projectToken }));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function tokenExists() {
  return async (dispatch: { (arg0: Function): void }) => {
    try {
      const token = await getAuthOrProjectToken();
      if (token) {
        dispatch(fetchSignedInUser());
      } else {
        const envSsoUrl = process.env.REACT_APP_SSO_URL;
        const sourceDomain = window.location.origin;
        const ssoUrl = `${envSsoUrl}#/authenticate?projectDomain=&sourceDomain=${sourceDomain}&sourceRouteName=%2Fprojects&targetRouteName=`;
        const callingUrl = window.location.href;
        const params = new URLSearchParams(callingUrl);
        const token = params.get('token');
        if (token) {
          setAuthToken(token);
        } else {
          window.location.replace(ssoUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}
