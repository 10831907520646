import React, { useState, useCallback, useEffect } from 'react';
import { Form, FormGroup, Container, Row } from 'reactstrap';
import './ApnsSettings.scss';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { CERTIFICATE_REQUIRED, PASSWORD_REQUIRED } from 'constants/errors/errors';
import * as ApnsActions from 'actions/apnsSettings';
import { useActions } from 'actions';
import { ApnsSettings, ApnsSetting } from 'model/apnsSettings';
import { flatten, cloneDeep } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { FlightButton, FlightModal, FlightTextInput, FlightCheckbox } from '@flybits/webapp-design-system-react';
import { ReactComponent as AppleLogo } from 'assets/images/ios.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';

//TODO: SAI, this component is a major WIP, please ignore all commented out funtionality
// design revisions pending.
let openModal = true;
interface Props {
  apnsSettingFromState?: ApnsSetting[];
  apnsSettingsFromState?: ApnsSettings[];
}

const validationSchema = Yup.object().shape({
  certificate: Yup.string().required(CERTIFICATE_REQUIRED),
  password: Yup.string().required(PASSWORD_REQUIRED),
});

export default function ApnsSettingsCard(props: Props) {
  const { apnsSettingFromState = [], apnsSettingsFromState = [] } = props;
  const apnsActions = useActions(ApnsActions);
  let resetApnsStatus: ApnsSettings;
  let updateApnsStatus: ApnsSettings;
  let hasStatusBeenReset = false;
  let fileID = '';
  let name = '';
  let isUploaded = false;
  const [modal, setModal] = useState(false);

  function toggle() {
    setModal(!modal);
  }

  /* Redux states are passed as props */
  const localApnsSettingsState = flatten(cloneDeep(apnsSettingsFromState));
  if (apnsSettingFromState.length !== 0) {
    [{ fileID, name, isUploaded }] = apnsSettingFromState;
  }

  /* Manages local state for upload validations */
  const [uploadedCertificate, setUploadedCertificate] = useState('');

  /* Manages local state for create APNS settings modal */
  const [addApnsModal, setAddApnsModal] = useState(false);
  function toggleAddApnsModal() {
    setUploadedCertificate('');
    apnsActions.resetApnsSetting();
    setAddApnsModal(!addApnsModal);
    toggle();
  }

  /* Manages local state for delete APNS settings modal */
  // const [confirmDeleteApnsModal, setConfirmDeleteApnsModal] = useState(false);
  // const toggleDeleteApnsModal = () => {
  //   setConfirmDeleteApnsModal(!confirmDeleteApnsModal);
  // };

  /* Manages local state to change APNS settings modal */
  const [confirmStatusChangeModal, setConfirmStateChange] = useState(false);
  const toggleStatusModal = () => {
    setConfirmStateChange(!confirmStatusChangeModal);
  };

  /* Manages local state for status change APNS settings */
  // const [selectedFileIdForStatusChange, setSelectedFileIdForStatusChange] = useState('');
  /* Manages local state for deleting APNS settings */
  // const [selectedFileIdForDeletion, setSelectedFileIdForDeletion] = useState('');

  // function confirmStatusChange(fileId: string, isEnabled: boolean) {
  //   if (!isEnabled) {
  //     if (fileId !== '') {
  //       setSelectedFileIdForStatusChange(fileId || '');
  //     }

  //     toggleStatusModal();
  //   }
  //   return;
  // }

  // function confirmDelete(fileId?: string) {
  //   if (fileId !== '') {
  //     setSelectedFileIdForDeletion(fileId || '');
  //   }
  //   toggleDeleteApnsModal();
  // }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFiles = new FormData();
      setUploadedCertificate('uploaded');
      acceptedFiles.forEach((file: File) => {
        uploadedFiles.append('assetFile', file);
      }, apnsActions.uploadApnsCertificate(uploadedFiles));
    },
    [apnsActions],
  );

  /* The API expects the status of currently active setting to be rest before making a setting active
   * changeStatus function carries two parts, inactiveExistingActive which resets the status of any active(isEnabled)
   * setting and userIntendedStatusChange which changes the status of selected setting. These have to called in a async way,
   * i.e, inactiveExistingActive must finish before userIntendedStatusChange occurs, hence they're wrapped in a do,while using
   * hasStatusBeenReset flag, the function is reused when creating a new apns setting, isCreate is an optional argument that serves
   * this purpose */
  async function changeStatus(isCreate?: boolean, createApnsSettings?: ApnsSettings) {
    // const inactiveExistingActive = localApnsSettingsState.filter(setting => setting.isEnabled === true);
    // inactiveExistingActive.forEach(setting => {
    //   if (setting.fileID !== selectedFileIdForStatusChange) {
    //     resetApnsStatus = {
    //       fileID: setting.fileID,
    //       name: setting.name,
    //       isProduction: setting.isProduction,
    //       isEnabled: false,
    //       password: setting.password,
    //     };
    //   }
    // });
    if (!isCreate) {
      //   const userIntendedStatusChange = localApnsSettingsState.filter(
      //     setting => setting.fileID === selectedFileIdForStatusChange,
      //   );
      //   userIntendedStatusChange.forEach(setting => {
      //     updateApnsStatus = {
      //       fileID: setting.fileID,
      //       name: setting.name,
      //       isProduction: setting.isProduction,
      //       isEnabled: true,
      //       password: setting.password,
      //     };
      //   });
    }

    do {
      try {
        await apnsActions.addOrUpdateApnsSettings(resetApnsStatus);
        if (!isCreate) {
          await apnsActions.addOrUpdateApnsSettings(updateApnsStatus);
        } else {
          await apnsActions.addOrUpdateApnsSettings(createApnsSettings);
        }
        hasStatusBeenReset = false;
      } catch {
        hasStatusBeenReset = true;
      }
    } while (hasStatusBeenReset);
    if (!isCreate) {
      toggleStatusModal();
    } else {
      toggleAddApnsModal();
    }
  }

  // function changeStatusConfirmed() {
  //   changeStatus();
  // }
  //
  // function deleteApnsConfirmed() {
  //   // apnsActions.deleteApnsSetting(selectedFileIdForDeletion);
  //   toggleDeleteApnsModal();
  // }

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.p12',
  });
  const [checked, setChecked] = useState(false);
  const [select, setSelect] = useState('UNSELECTED');
  const handleSelect = (values: any) => {
    setChecked(!checked);
    checked ? setSelect('SELECTED') : setSelect('UNSELECTED');
    values.isProduction = checked;
  };
  const handleOpen = () => {
    setUploadedCertificate('');
    apnsActions.resetApnsSetting();
    openModal = true;
    open();
  };
  useEffect(() => {
    if (uploadedCertificate !== '' && openModal) {
      setModal(true);
      openModal = false;
    }
  }, [uploadedCertificate]);
  // const apnsSettingsHeader = [
  //   {
  //     id: 1,
  //     value: 'Certificate',
  //   },
  //   {
  //     id: 2,
  //     value: 'Environment',
  //   },
  //   {
  //     id: 3,
  //     value: 'Password',
  //   },
  //   {
  //     id: 4,
  //     value: 'Status',
  //   },
  //   {
  //     id: 5,
  //     value: '',
  //   },
  // ];

  const activeCertificate = localApnsSettingsState.find(x => x.isEnabled === true);
  return (
    <div className="apns-settings">
      <Formik
        enableReinitialize
        initialValues={{ certificate: uploadedCertificate, isProduction: false, password: '', isEnabled: true }}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.isEnabled === true) {
            const createApnsSetting: ApnsSettings = {
              fileID: fileID,
              name: name,
              isProduction: values.isProduction,
              isEnabled: values.isEnabled,
              password: values.password,
            };
            changeStatus(true, createApnsSetting);
          } else {
            const createApnsSetting: ApnsSettings = {
              fileID: fileID,
              name: name,
              isProduction: values.isProduction,
              isEnabled: values.isEnabled,
              password: values.password,
            };
            apnsActions.addOrUpdateApnsSettings(createApnsSetting);
          }

          setSubmitting(true);
          resetForm();
          toggleAddApnsModal();
          toggle();
        }}
      >
        {({ errors, values, handleChange, handleBlur, handleSubmit, isValid }) => (
          <FlightModal
            isVisible={modal}
            toggleModalShown={toggle}
            size="small"
            className="apns-settings__modal"
            scrollable={true}
            header={<span>New APNS Certificate</span>}
            content={
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <span className="apns-settings__certificate-title">Certificate</span>
                  </Row>
                  <FormGroup row>
                    <div className="apns-settings__error">
                      <ErrorMessage name={'certificate'} />
                    </div>
                    <div className="apns-settings__certificate">
                      {isUploaded ? <CheckIcon className="apns-settings__check" /> : ''}
                      <span className="apns-settings__certificate-name">{name}</span>
                      <FlightButton
                        className="apns-settings__certificate-btn"
                        theme="link"
                        label="Change"
                        onClick={handleOpen}
                      />
                    </div>
                    <span className="apns-settings__certificate-req">Only .p12 files are accepted</span>
                    <Field
                      component={FlightTextInput}
                      name="validateupd"
                      id="validateupd"
                      defaultValue={values.certificate}
                      className="apns-settings__validate-upload"
                    />
                  </FormGroup>
                  <FormGroup row>
                    <Field
                      type="text"
                      placeholderText="Type in your password"
                      name="password"
                      id="password"
                      label="Type in your password"
                      as={FlightTextInput}
                      width="385px"
                      hasError={isValid ? false : true}
                      errorMessage={errors.password}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <Field
                      type="checkbox"
                      name="isProduction"
                      id="isProduction"
                      label="This certificate is for a production environment"
                      as={FlightCheckbox}
                      checkState={select}
                      onChange={handleChange}
                      placeholder="isProduction"
                      onBlur={handleBlur}
                      onSelect={() => handleSelect(values)}
                    />
                  </FormGroup>
                </Form>
              </div>
            }
            footer={
              <div className="modal-footer">
                <FlightButton theme="secondary" onClick={toggleAddApnsModal} label="Cancel" />
                <FlightButton type="primary" onClick={handleSubmit} disabled={!isValid} label="Save" />
              </div>
            }
          />
        )}
      </Formik>
      <Container>
        <Row>
          <AppleLogo />
          <span className="apns-settings__header">APNS Certificate </span>
        </Row>
        <Row>
          {activeCertificate?.isEnabled ? (
            <span className="apns-settings__body"></span>
          ) : (
            <span className="apns-settings__body">You don't have any APNS certificates.</span>
          )}
        </Row>
        <Row>
          <div className={activeCertificate?.isEnabled ? 'apns-settings__key-con' : ''}>
            {activeCertificate?.isEnabled ? (
              <div>
                <span className="apns-settings__key">{activeCertificate.name}</span>
                <span className="apns-settings__key-prod">
                  ({activeCertificate.isProduction ? 'Production' : 'Test'})
                </span>
              </div>
            ) : null}
          </div>
        </Row>
        <Row>
          <input name="file" id="fileValidation" {...getInputProps()} />
          <FlightButton
            label={activeCertificate?.isEnabled ? 'Manage' : 'Add a certificate'}
            size="medium"
            theme="secondary"
            iconRight={activeCertificate?.isEnabled ? '' : 'baselineArrowRight'}
            className="apns-settings__btn"
            onClick={handleOpen}
          />
        </Row>
      </Container>
    </div>
  );
}
