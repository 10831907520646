import {
  ContentTemplate,
  ContentTemplatesActionTypes,
  ContentTemplatesActions,
  ContentTemplateAttributes,
} from '../model/contentTemplates';
import {
  ContentTemplateSettingsActions,
  ContentTemplateSettingsActionTypes,
  ContentTemplateSettings,
} from '../model/contentTemplateSettings';
import SettingsService from '../services/settings.service';
import ContentService from '../services/content.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setContentTemplateState(templates: ContentTemplate): ContentTemplatesActions {
  return {
    type: ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE,
    payload: templates,
  };
}

export function resetContentTemplateState(): ContentTemplatesActions {
  return {
    type: ContentTemplatesActionTypes.RESET_CONTENT_TEMPLATES_STATE,
  };
}

export function setContentTemplateSettingState(templates: ContentTemplateSettings): ContentTemplateSettingsActions {
  return {
    type: ContentTemplateSettingsActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE,
    payload: templates,
  };
}

export function resetContentTemplateSettingState(): ContentTemplateSettingsActions {
  return {
    type: ContentTemplateSettingsActionTypes.RESET_CONTENT_TEMPLATE_SETTINGS_STATE,
  };
}

export function fetchContentTemplates() {
  return async (dispatch: { (arg0: ContentTemplatesActions): void }) => {
    try {
      const req = new ContentService();
      const params = {
        limit: 1000000,
      };
      const response = await req.getAllContentTemplates(params);
      dispatch(resetContentTemplateState());
      dispatch(setContentTemplateState(response.data.data));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function fetchContentTemplateSettings() {
  return async (dispatch: { (arg0: ContentTemplateSettingsActions): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getProjectCustomContentTemplates();
      dispatch(resetContentTemplateSettingState());
      dispatch(setContentTemplateSettingState(response.data.settings));
    } catch (error) {}
    return 'done';
  };
}

export function createNewContentTemplate(data: ContentTemplateAttributes) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContentService();
      const response = await req.createContentTemplate(data);
      if (response.status === 201) {
        dispatch(fetchContentTemplates());
        dispatch(fetchContentTemplateSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: false,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function deleteContentTemplates(templateId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContentService();
      const response = await req.deleteContentTemplates(templateId);
      if (response.status === 200) {
        dispatch(fetchContentTemplates());
        dispatch(fetchContentTemplateSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}
