import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FlightButton, FlightModal, FlightTextInput, FlightTable } from '@flybits/webapp-design-system-react';
import { Subscription } from '../../../model/subscriptions';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import './EmailUsageAlerts.scss';
import { Formik, FieldArray, Field, ErrorMessage, isEmptyArray } from 'formik';
import { percentages } from 'constants/percentages';
import * as Yup from 'yup';
import * as SubscriptionActions from 'actions/subscriptions';
import { useActions } from 'actions';
import { orderBy } from 'lodash';
import { PERCENTAGE_REQUIRED, EMAIL_REQUIRED, EMAIL_VALIDATION } from 'constants/errors/errors';

interface Props {
  subscriptionsFromState?: Subscription[];
}

/* Local interface */
interface Subscriptions {
  id?: string;
  percentage: string;
  emails: Array<string>;
}
/* Validations */
const validationSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string()
      .required(EMAIL_REQUIRED)
      .email(EMAIL_VALIDATION),
  ),
  percentage: Yup.string().required(PERCENTAGE_REQUIRED),
});

export default function EmailUsageAlerts(props: Props) {
  const { subscriptionsFromState = [] } = props;
  /* decouple mapped state, remove any reference to store */
  const localSubscriptionState = flatten(cloneDeep(subscriptionsFromState));

  /* Link redux actions*/
  const subscriptionActions = useActions(SubscriptionActions);

  /* Local state to manage menu*/
  // const [menuIndex, setMenuIndex] = useState(-1);
  // function setIndex(index: number) {
  //   if (menuIndex !== index) {
  //     setMenuIndex(index);
  //   } else {
  //     setMenuIndex(-1);
  //   }
  // }

  /* Local state for form values */
  const [formValues, setFormValue] = useState<Subscriptions>({ percentage: '1', emails: [''] });

  /*Local state for delete */
  const [deleteState, setDeleteState] = useState<Subscription>({ percentage: 0, emails: [''] });

  /*Local states for modal*/
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const triggerModal = () => {
    setFormValue({ percentage: '1', emails: [''] });
    toggle();
  };

  // /*Local state for confirm modal*?
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  /* Edit handler - percentage is locally held as a string within this form, select type field converts
  all inputs into strings, the value is converted to a number before dispatching action
  */
  // function handleEdit(subscription: Subscription) {
  //   toggle();
  //   setFormValue({
  //     id: subscription.id,
  //     percentage: subscription.percentage.toString(),
  //     emails: subscription.emails,
  //   });
  // }

  /* Delete handler functions - opens toggle for confirmation */
  function confirmDelete() {
    subscriptionActions.deleteSubscriptionAlert(deleteState);
    confirmModalToggle();
    setDeleteState({ percentage: 0, emails: [''] });
  }

  // function handleDelete(subscription: Subscription) {
  //   confirmModalToggle();
  //   setDeleteState(subscription);
  // }

  /* Sort list by object property 'percentage' */
  const sortedSubscriptionState = orderBy(localSubscriptionState, 'percentage', 'asc');
  const tableHeaders = [
    {
      name: 'Percentage',
      key: 'percentage',
      isVisible: true,
    },
    {
      name: 'Email',
      key: 'email',
      isVisible: true,
    },
  ];
  const tableBody = new Array(sortedSubscriptionState.length);
  for (let i = 0; i < sortedSubscriptionState.length; i++){
    tableBody[i]={
      key: Math.random(),
      percentage: (<span>{sortedSubscriptionState[i].percentage + `${'%'}`}</span>),
      email: (<span>{sortedSubscriptionState[i].emails.length > 1 ? sortedSubscriptionState[i].emails + `${'\n'}` : sortedSubscriptionState[i].emails}</span>)
    }
  }

  return (
    <div className="email-usage-alerts">
      <FlightModal
      isVisible={confirmModal}
      size="medium"
      toggleModalShown={confirmModalToggle}
      header = {<span>Confirm Delete</span>}
      className="email-usage__confirm-modal"
      content={
        <div>
        <span>
          Are you sure you want to delete the alert? {'\n'}
          You cannot undo this action.
        </span>
        </div>
      }
      footer={
        <div className="modal-footer">
          <FlightButton theme="secondary" onClick={confirmModalToggle} label="Cancel" />
          <FlightButton type="submit" label="Save" onClick={confirmDelete} />
        </div>
      }
      />
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (values.id) {
              const params: Subscription = {
                id: values.id,
                percentage: parseInt(values.percentage),
                emails: values.emails,
              };
              subscriptionActions.updateSubscriptionAlert(params);
            } else {
              const params: Subscription = {
                percentage: parseInt(values.percentage),
                emails: values.emails,
              };
              subscriptionActions.createNewSubscriptionAlert(params);
            }

            resetForm();
            toggle();
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <FlightModal
                isVisible={modal}
                size="medium"
                toggleModalShown={toggle}
                header = {<span>Add Usage Alert</span>}
                content={
                <Form>
                  <FormGroup>
                    <Label for="percentage">When API usage reaches</Label>
                    <Input
                      type="select"
                      name="percentage"
                      id="percentage"
                      onChange={handleChange}
                      value={values.percentage}
                      onBlur={handleBlur}
                    >
                      {percentages.map((percentage: number) => (
                        <option key={percentage} value={percentage}>
                          {percentage + `${'%'}`}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    send an alert email to...
                    <FieldArray
                      name="emails"
                      render={arrayHelpers => (
                        <div>
                          {values.emails && values.emails.length > 0 ? (
                            values.emails.map((email, index) => (
                              <div key={index}>
                              <InputGroup style={{ padding: 5 }}>
                             <InputGroupAddon addonType="prepend">
                               <Button disabled>Email</Button>
                             </InputGroupAddon>
                                  <Field
                                    as={FlightTextInput}
                                    name={`emails.${index}`}
                                    id={`emails.${index}`}
                                    onChange={handleChange}
                                    value={email}
                                    onBlur={handleBlur}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button onClick={() => arrayHelpers.remove(index)}> - </Button>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="append">
                                    <Button
                                      onClick={() => (errors.emails?.length !== 0 ? arrayHelpers.push('') : null)}
                                    >
                                      {' '}
                                      +
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                                <div className="email-usage-alerts__error">
                                  <ErrorMessage name={`emails.${index}`} />
                                </div>
                              </div>
                            ))
                          ) : (
                            <FlightButton type="button" label="Add Email" onClick={() => arrayHelpers.push('')}/>

                          )}
                        </div>
                      )}
                    />
                  </FormGroup>
                </Form>
              }
              footer={
              <div className="modal-footer">
                <FlightButton theme="secondary" onClick={toggle} label="Cancel"/>
                <FlightButton type="submit" onClick={handleSubmit} disabled={isEmptyArray(values.emails)} label="Save"/>
              </div>
              }
            />
          )}
        </Formik>
      <ReusableCard>
        <Col>
        <Row>
          <div className="email-usage-alerts__header">API usage email alerts</div>
        </Row>
        <Row>
          <div className="email-usage-alerts__title">Get notified when aproaching a set api call threshold.</div>
        </Row>
        </Col>
        <FlightButton theme="secondary" className="email-usage-alerts__addbtn" onClick={triggerModal} label="Manage"/>
          <FlightTable
            className = "email-usage-alerts__table"
            tableHeaders = {tableHeaders}
            tableData = {tableBody}
            isShowHeader={false}
            hasPaginationBeforeTable={false}
          />
      </ReusableCard>
    </div>
  );
}
