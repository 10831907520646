import React, { ReactNode } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { ContentTemplate } from 'model/contentTemplates';
import { FlightCheckbox, FlightOverflowMenu } from '@flybits/webapp-design-system-react';
import { ReactComponent as MiscSvg } from 'assets/images/misc.svg';
import './TemplateCard.scss';

interface Props {
  checkBoxState?: string;
  internal: boolean;
  children?: ReactNode;
  templateState: ContentTemplate;
  toggleCheckbox: (id: string) => void;
  toggleExport: (id: string) => void;
  toggleDelete: (id: string) => void;
  toggleDuplicate: (id: string) => void;
  toggleEdit: (id: string) => void;
}

interface Localizations {
  description: string;
  name: string;
}

export default function TemplateCard(props: Props) {
  const {
    checkBoxState,
    children,
    templateState,
    internal,
    toggleCheckbox,
    toggleExport,
    toggleEdit,
    toggleDuplicate,
    toggleDelete,
  } = props;
  let name;
  let description;
  const localization: Array<Localizations> = Object.values(templateState.localizations);
  if (localization.length !== 0) {
    name = localization[0].name;
    description = localization[0].description;
  }

  const options = [
    [
      {
        key: 1,
        name: 'Edit',
        disabled: false,
        onClick: () => toggleEdit(templateState.id),
      },
      {
        key: 2,
        name: 'Duplicate',
        disabled: false,
        onClick: () => toggleDuplicate(templateState.id),
      },
      {
        key: 3,
        name: 'Export',
        disabled: false,
        onClick: () => toggleExport(templateState.id),
      },
      {
        key: 4,
        name: 'Delete',
        disabled: false,
        onClick: () => toggleDelete(templateState.id),
      },
    ],
  ];

  const optionsStarter = [
    [
      {
        key: 1,
        name: 'View',
        disabled: false,
        onClick: () => console.log('not implemented yet'),
      },
    ],
  ];
  return (
    <div>
      <Card className={checkBoxState === templateState.id ? 'template-card--active' : 'template-card'}>
        <Container>
          {!internal ? (
            <FlightCheckbox
              className="template-card__checkbox"
              checkState={checkBoxState?.includes(templateState.id) ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => toggleCheckbox(templateState.id)}
            />
          ) : (
            ''
          )}
          <FlightOverflowMenu
            className="template-card__more-items"
            optionGroups={!internal ? options : optionsStarter}
          />

          <Row className="pt-5">
            {templateState.iconUrl === '' ? (
              <MiscSvg className="template-card__img" />
            ) : (
              <img className="template-card__img" src={templateState.iconUrl} alt="logo" />
            )}
          </Row>
          <Row>
            <div className="template-card__name">{name}</div>
          </Row>
          <Row>
            <div className="template-card__description">{description}</div>
          </Row>
          <CardBody>{children}</CardBody>
        </Container>
      </Card>
    </div>
  );
}
