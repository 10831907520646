export interface DatasourceState {
  datasources: Datasource[] | undefined;
}

export interface Datasource {
  id: string;
  connectorId: string;
  creatorId: string;
  name: string;
  pluginId: string;
  providerName: string;
  description: string;
  imageUrl: string;
  documentationUrl: string;
  supportEmail: string;
  status: string;
  tenantViewScope: string[];
  auth: Auth;
  configs: Config;
  draftPlugin: DraftPlugin;
  createdAt: number;
  updatedAt: number;
  hasTenantConnection: boolean;
  tenantConnectionID?: string;
}

interface Auth {
  status: string;
}

interface Config {
  identityTag: string;
  definedConfigs: object; // Should be more specific on type
  dynamicConfigs: object; // Should be more specific on type
}

interface DraftPlugin {
  uid: string;
  id: string;
  isReserved: boolean;
  supportedPlatforms: string[];
  provider: string;
  category: string;
  userScope: boolean;
  isSaRestricted: boolean;
  name: string;
  description: string;
  iconUrl: string;
  values: object; // Should be more specific on type
  dataSource: string;
  tenantAccess: object; // Should be more specific on type
}

export interface GetDatasourcesParams {
  limit?: number;
  offset?: number;
  sort?: string;
  search?: string;
}

export interface CreateTenantConnectionParams {
  connectorInstanceId: string;
  status: string;
  configs: object; // Should be more specific on type
}

export enum DatasourceActionTypes {
  SET_DATASOURCES_STATE = 'SET_DATASOURCES_STATE',
  SET_DATASOURCE_SEARCH_TERM_STATE = 'SET_DATASOURCE_SEARCH_TERM_STATE',
}

interface DatasourceActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type DatasourceAction =
  | DatasourceActionCreatorType<typeof DatasourceActionTypes.SET_DATASOURCES_STATE, Datasource>
  | DatasourceActionCreatorType<typeof DatasourceActionTypes.SET_DATASOURCE_SEARCH_TERM_STATE, string>;
