import {
  SignedLoginSettingsActions,
  SignedLoginSettingsActionTypes,
  SignedLoginSettings,
} from '../model/signedLoginSettings';
import AuthService from 'services/auth.service';
import { SignedUploadService } from 'services/auth.service';

export function setSignedLoginSettings(samlSettings: SignedLoginSettings): SignedLoginSettingsActions {
  return {
    type: SignedLoginSettingsActionTypes.SET_SIGNEDLOGINSETTINGS_STATE,
    payload: samlSettings,
  };
}

export function resetSignedLoginSettings(): SignedLoginSettingsActions {
  return {
    type: SignedLoginSettingsActionTypes.RESET_SIGNEDLOGINSETTINGS_STATE,
  };
}

export function fetchSignedLoginSettings(isJustUploaded?: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new AuthService();
      const response = await req.getSignedLoginSettings();
      dispatch(resetSignedLoginSettings());
      dispatch(setSignedLoginSettings({ content: response.data.data, isJustUploaded: isJustUploaded || false }));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetSignedLoginSettings());
      }
    }
    return 'done';
  };
}

export function uploadSignedLoginCertificate(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SignedUploadService();
      await req.uploadSignedLoginCertificate(data);
    } catch (error) {
      /*TODO: sai.karthik@flybits.com:The api has a success status where it returns a 404 
      this request will be caught, this will be addressed and this will
      later have to be fixed*/
      dispatch(fetchSignedLoginSettings(true));
    }
    return 'done';
  };
}

export function deleteSignedLoginCertificate() {
  return async () => {
    try {
      const req = new AuthService();
      await req.deleteSignedLoginSettings();
    } catch (error) {}
    return 'done';
  };
}
