import React, { useState, useEffect } from 'react';
import { FlightModal, FlightButton, FlightTextInput, FlightToggleSwitch } from '@flybits/webapp-design-system-react';
import { ReactComponent as OktaLogo } from 'assets/images/idplogos/okta.svg';
import './IdpModal.scss';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SERVER_ID_REQUIRED, HOST_REQUIRED } from 'constants/errors/errors';
import { useActions } from 'actions';
import * as OktaSettingsActions from 'actions/oktaSettings';
import { OktaSettings } from 'model/oktaSettings';
import { TenantAccessSettings, AllowedTenants } from 'model/tenantAccessSettings';
import * as TenantSettingsActions from 'actions/tenantAccessSettings';
import { maskField } from 'helpers/maskField';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  oktaSettingsFromState?: OktaSettings[];
  tenantAccessSettingsFromState?: TenantAccessSettings[];
}

const validationSchema = Yup.object().shape({
  authorizationServerID: Yup.string().required(SERVER_ID_REQUIRED),
  host: Yup.string().required(HOST_REQUIRED),
});

export default function OktaModal(props: Props) {
  const { openModal, toggleModal, oktaSettingsFromState = [], tenantAccessSettingsFromState = [] } = props;
  const [checked, setChecked] = useState(false);
  const otkaSettingsActions = useActions(OktaSettingsActions);
  const tenantSettingsActions = useActions(TenantSettingsActions);
  const [checkedStateApi, setCheckedStateApi] = useState(false);

  let authorizationServerID = '';
  let clientID = '';
  let host = '';
  let secretKey = '';
  let maskedSecretKey = '';
  if (oktaSettingsFromState.length !== 0) {
    [{ authorizationServerID, clientID, host, secretKey }] = oktaSettingsFromState;
  }

  if (secretKey !== '') {
    maskedSecretKey = maskField(secretKey);
  }

  let allowed: AllowedTenants[] = [{ type: 0, value: '' }];
  if (tenantAccessSettingsFromState.length !== 0) {
    [{ allowed }] = tenantAccessSettingsFromState;
  }

  let allowedOktaSettings: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedOktaSettings = allowed.filter(item => item.value === 'okta');
  }

  useEffect(() => {
    if (allowedOktaSettings.length !== 0 && allowedOktaSettings[0].value !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedOktaSettings]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  return (
    <div className="idp-modal">
      <Formik
        initialValues={{
          authorizationServerID: authorizationServerID,
          host: host,
          clientID: clientID,
          secretKey: maskedSecretKey,
        }}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        validateOnMount={true}
        onSubmit={(values, { resetForm }) => {
          otkaSettingsActions.createOrUpdateOktaSetting(values);
          const allowedTenants: AllowedTenants[] = allowed;
          allowedTenants.push({
            type: 1,
            value: 'okta',
          });
          if (checked) {
            const enableAPKeySettings = {
              allowed: allowedTenants,
            };
            tenantSettingsActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
          } else {
            const disableApiKeySettings = {
              allowed: allowed.filter(item => item.value !== 'okta'),
            };
            tenantSettingsActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
          }
          resetForm();
          toggleModal();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isValid }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={() => {
              setChecked(checkedStateApi);
              handleReset();
              toggleModal();
            }}
            scrollable={false}
            size="small"
            content={
              <Col className="idp-modal__content">
                <Row className="idp-modal__content__content-header">
                  <OktaLogo className="idp-modal__content__content-header__header-logo" />
                  <span className="idp-modal__content__content-header__header-text">Okta</span>
                </Row>
                <Col className="idp-modal__content__toggle">
                  <Row>
                    <FlightToggleSwitch checked={checked} onChange={setChecked} label={'Enable '} />
                  </Row>
                </Col>
                <Col className="idp-modal__content__text-field-container">
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="authorizationServerID"
                      label="Server ID"
                      width="345px"
                      hasError={touched.authorizationServerID && errors.authorizationServerID ? true : false}
                      value={values.authorizationServerID}
                      onBlur={handleBlur}
                      errorMessage={<span>{errors.authorizationServerID}</span>}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="clientID"
                      label="Client ID"
                      width="345px"
                      value={values.clientID}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="host"
                      label="Host"
                      width="345px"
                      hasError={touched.host && errors.host ? true : false}
                      value={values.host}
                      onBlur={handleBlur}
                      errorMessage={<span>{errors.host}</span>}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="secretKey"
                      label="Secret Key"
                      width="345px"
                      value={values.secretKey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                </Col>
              </Col>
            }
            footer={
              <div className="modal-footer">
                <FlightButton
                  onClick={() => {
                    setChecked(checkedStateApi);
                    handleReset();
                    toggleModal();
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton type="primary" label="Apply Changes" onClick={handleSubmit} disabled={!isValid} />{' '}
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
