export interface SilentPushSettings {
  action: string;
  subscribed: boolean;
}

export enum SilentPushActionTypes {
  SET_SILENT_PUSH_SETTINGS = 'SET_SILENT_PUSH_SETTINGS',
  RESET_SILENT_PUSH_SETTINGS = 'RESET_SILENT_PUSH_SETTINGS',
}

interface ConfigName {
  configName: string;
}

export interface EntityTypes {
  actions: SilentPushSettings[];
  entityKeyName: string;
}

export interface Entities {
  entities: EntityTypes[];
}

export interface ObjectForSilentPushApi {
  settings: Entities;
  configName: ConfigName;
}

interface SilentPushActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type SilentPushActions =
  | SilentPushActionCreatorTypes<typeof SilentPushActionTypes.SET_SILENT_PUSH_SETTINGS, SilentPushSettings>
  | SilentPushActionCreatorTypes<typeof SilentPushActionTypes.RESET_SILENT_PUSH_SETTINGS, SilentPushSettings>;
