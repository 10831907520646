import { SubscriptionAction, SubscriptionActionTypes, Subscription, Ratelimits } from '../model/subscriptions';
import SubscriptionService from '../services/subscription.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setSubscriptionState(subscriptions: Subscription): SubscriptionAction {
  return {
    type: SubscriptionActionTypes.SET_SUBSCRIPTIONS_STATE,
    payload: subscriptions,
  };
}

export function resetSubscriptionsState(): SubscriptionAction {
  return {
    type: SubscriptionActionTypes.RESET_SUBSCRIPTIONS_STATE,
  };
}

export function setRatelimitsState(ratelimits: Ratelimits): SubscriptionAction {
  return {
    type: SubscriptionActionTypes.SET_RATELIMITS_STATE,
    payload: ratelimits,
  };
}

export function resetRatelimitsState(): SubscriptionAction {
  return {
    type: SubscriptionActionTypes.RESET_RATELIMITS_STATE,
  };
}

export function fetchAllSubscriptions() {
  return async (dispatch: { (arg0: SubscriptionAction): void }) => {
    try {
      const req = new SubscriptionService();
      const response = await req.getAllSubscriptions();
      dispatch(resetSubscriptionsState());
      dispatch(setSubscriptionState(response.data.data));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function createNewSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.createNewSubscription(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert added.',
            type: 'success',
          }),
        );
        dispatch(resetSubscriptionsState());
        dispatch(fetchAllSubscriptions());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed to add email alert.',
          body: e.response.data.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function updateSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.updateSubscription(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert modified.',
            type: 'success',
          }),
        );
        dispatch(resetSubscriptionsState());
        dispatch(fetchAllSubscriptions());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed to modify email alert.',
          body: e.response.data.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function deleteSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.deleteSubscription(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert deleted.',
            type: 'success',
          }),
        );
        dispatch(resetSubscriptionsState());
        dispatch(fetchAllSubscriptions());
      }
    } catch (e) {}
    return 'done';
  };
}

export function fetchRatelimitsForProject(args: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const params = {
        id: args,
        includeCatalogueUsages: true,
      };
      const req = new SubscriptionService();
      const response = await req.getRateLimits(params);
      if (response.status === 200) {
        dispatch(resetRatelimitsState());
        dispatch(setRatelimitsState(response.data.rateLimit));
      }
    } catch (e) {
      const randomId = generateUniqueId();
      showNotification({
        id: randomId,
        showNotification: true,
        header: 'Error',
        body: 'Failed to fetch rate limits.',
        type: 'danger',
      });
    }
    return 'done';
  };
}
