import BaseService from './base.service';
import { CreateContextPlugin, ContextPlugin } from '../model/contextPlugins';

interface GetContextPlugins {
  limit: number;
}

export default class ContextService extends BaseService {
  CURRENT_PATH: string;
  CURRENT_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'context/';
    this.CURRENT_RESOURCE = 'ctxschema/contextplugins';
  }

  async getAllContextPlugins(params: GetContextPlugins) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_RESOURCE, params);
  }
  async createContextPlugin(data: CreateContextPlugin) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }
  async deleteContextPlugin(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + id);
  }
  async updateContextPlugin(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }
  async disableContextPluginStatus(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + data.id + '/disable', data);
  }
  async enableContextPluginStatus(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + data.id + '/enable', data);
  }
}
