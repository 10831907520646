import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { FlightTextInput } from '@flybits/webapp-design-system-react';
import { FormGroup, Button } from 'reactstrap';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon.svg';
import './ArrayFields.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any) => void;
  initialValues: { values: Array<string> };
  placeHolderText?: string;
  validationSchema?: {};
  hasError: (data: boolean) => void;
}

interface ArrayForm {
  values: Array<string>;
}
export const ArrayFields = (props: Props) => {
  const { callback, initialValues, placeHolderText, hasError, validationSchema } = props;
  const onFormSubmit = (values: ArrayForm) => {
    const removeStrays = values.values.filter(function(str: string) {
      return /\S/.test(str);
    });
    callback(removeStrays);
  };
  function setUpdatedValue(values: ArrayForm) {
    onFormSubmit(values);
  }

  // eslint-disable-next-line
  const inputRef = useRef<any>();

  function handleDelete(values: ArrayForm, index: number) {
    values.values.splice(index, 1);
    setUpdatedValue(values);
  }

  const triggerAdd = () => {
    if (inputRef.current) {
      inputRef.current.onClick();
    }
  };

  const keyHandler = useCallback((event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      triggerAdd();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyHandler, false);
    return () => {
      document.removeEventListener('keydown', keyHandler, false);
    };
  });

  return (
    <div className="array-fields">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async () => onFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, setFieldError }) => (
          <div>
            <Form onBlur={() => setUpdatedValue(values)}>
              <FormGroup>
                <FieldArray
                  name="values"
                  render={arrayHelpers => (
                    <div>
                      <div>
                        <Button
                          type="button"
                          className="array-fields__btn-no-display"
                          ref={inputRef}
                          onClick={() => (errors.values ? null : arrayHelpers.push(''))}
                        />
                        {values.values && values.values.length > 0 ? (
                          values.values.map((field, index) => (
                            <div key={index} className="array-fields__field-array">
                              <div className="array-fields__index-box">{index + 1}</div>
                              <div> {errors?.values ? hasError(true) : hasError(false)}</div>
                              <div className="array-fields__input-wrapper">
                                <ClearIcon
                                  className="flight-text-input__input__clear-icon"
                                  onClick={() => {
                                    if (index === 0 && values.values.length === 1) {
                                      if (values.values[0] === '') {
                                        return;
                                      } else {
                                        arrayHelpers.handleRemove(index);
                                        handleDelete(values, 0);
                                        arrayHelpers.push('');
                                      }
                                    } else {
                                      setFieldError(`values.${index}`, '');
                                      arrayHelpers.handleRemove(index);
                                      handleDelete(values, index);
                                    }
                                  }}
                                />
                                <Field
                                  as={FlightTextInput}
                                  name={`values.${index}`}
                                  id={`values.${index}`}
                                  onChange={handleChange}
                                  placeholderText={placeHolderText || ''}
                                  value={field}
                                  hasError={errors?.values?.[index] ? true : false}
                                  width="430px"
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <Button type="button" ref={inputRef} onClick={() => arrayHelpers.push('')}>
                            Add Field
                          </Button>
                        )}
                      </div>
                      <div className="array-fields__error">
                        {errors.values
                          ?.toString()
                          .replace(/,/g, '')
                          .split('.', 1)}
                      </div>
                    </div>
                  )}
                />
              </FormGroup>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ArrayFields;
