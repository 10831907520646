import {
  SamlSettingsActionTypes,
  SamlSettingsActions,
  SamlSettings,
  SamlConfigs,
  SamlSettingsForApi,
} from '../model/samlSettings';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';
import AuthService from 'services/auth.service';

export function setSamlSettings(samlSettings: SamlSettings): SamlSettingsActions {
  return {
    type: SamlSettingsActionTypes.SET_SAMLSETTINGS_STATE,
    payload: samlSettings,
  };
}

export function resetSamlSettings(): SamlSettingsActions {
  return {
    type: SamlSettingsActionTypes.RESET_SAMLSETTINGS_STATE,
  };
}

export function fetchSamlSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new AuthService();
      const response = await req.getSamlSettings();
      dispatch(resetSamlSettings());
      dispatch(setSamlSettings(response.data.samlConfigs));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetSamlSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateSamlSettings(samlConfigs: SamlConfigs) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new AuthService();
      const samlConfigsToServer: SamlConfigs[] = [
        {
          acsURL: samlConfigs.acsURL,
          entityID: 'personal-test-sai.flybits.com',
          idpIssuer: samlConfigs.idpIssuer,
          idpMetadataURL: samlConfigs.idpMetadataURL,
          idpSSOURL: samlConfigs.idpSSOURL,
          publicCert: samlConfigs.publicCert,
          skipSignatureValidation: samlConfigs.skipSignatureValidation,
          tenantID: samlConfigs.tenantID,
        },
      ];
      const samlSettings: SamlSettingsForApi = { samlConfigs: samlConfigsToServer };
      const response = await req.createOrUpdateSamlSetting(samlSettings);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchSamlSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
