/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getAuthOrProjectToken } from '../helpers/auth';

/* This base service only supports POST for format
 * form-data, multipart uploads. To be used to upload assets
 * like images, files etc */

const AXIOS_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// For Requests
AXIOS_SERVICE.interceptors.request.use(
  async function(config) {
    const token = await getAuthOrProjectToken();
    if (token) {
      config.headers['X-Authorization'] = token;
    }
    return config;
  },
  function(error) {
    return error;
  },
);

// For Responses
AXIOS_SERVICE.interceptors.response.use(function(response) {
  // Add debug logging
  return response;
});

class UploadBaseService {
  axios: any;
  constructor() {
    this.axios = AXIOS_SERVICE;
  }

  post(endpoint: string, data: any) {
    return AXIOS_SERVICE.post(endpoint, data);
  }

  request(type: any, url: string, data?: any) {
    let promise = null;
    switch (type) {
      case 'POST':
        promise = this.post(url, data);
        break;

      default:
        promise = this.post(url, data);
        break;
    }
    return promise;
  }
}

export default UploadBaseService;
