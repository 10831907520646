import React from 'react';
import ContentLoader from 'react-content-loader';

const PlaceHolder = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={475}
    viewBox="0 0 400 475"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
    <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
    <rect x="0" y="60" rx="2" ry="2" width="100" height="100" />
  </ContentLoader>
);

export default PlaceHolder;
