import React, { useState, useEffect } from 'react';
import './SilentPushSettings.scss';
import { SilentPushSettings } from 'model/silentPushSettings';
import { cloneDeep, flatten } from 'lodash';
import * as SilentPushActions from 'actions/silentPushSettings';
import { useActions } from 'actions';
import { FlightToggleSwitch } from '@flybits/webapp-design-system-react';

interface Props {
  silentPushSettingsFromState?: SilentPushSettings[];
}

export default function SilentPushSettingsCard(props: Props) {
  const { silentPushSettingsFromState = [] } = props;
  const silentPushActions = useActions(SilentPushActions);
  const [subscribe, setSubscribe] = useState(false);

  let subscribed = false;
  const localSilentPushSettingsState = flatten(cloneDeep(silentPushSettingsFromState));
  if (localSilentPushSettingsState.length !== 0) {
    [{ subscribed }] = localSilentPushSettingsState;
  }

  useEffect(() => {
    setSubscribe(subscribed);
    //eslint-disable-next-line
  }, [subscribed]);

  const handleChange = () => {
    setSubscribe(!subscribe);
    silentPushActions.addOrUpdateSilentPushSettings(!subscribe);
  };

  return (
    <div className="silent-push-settings">
      <div className="silent-push-settings__header">Silent Push Notifications</div>
      <div className="silent-push-settings__description">
        Send silent push notifications where there are relevant content changes
        <FlightToggleSwitch className="silent-push-settings__btn" checked={subscribe} onChange={handleChange} />
      </div>
    </div>
  );
}
