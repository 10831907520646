import { ApiKeySettingsActions, ApiKeySettingsActionTypes, ApiKeySettings } from '../model/apikeySettings';
import { FacebookSettingsActions, FacebookSettingsActionTypes, FacebookSettings } from '../model/facebookSettings';
import { GoogleSettingsActions, GoogleSettingsActionTypes, GoogleSettings } from '../model/googleSettings';
import { OktaSettingsActions, OktaSettingsActionTypes, OktaSettings } from '../model/oktaSettings';
import { OpenIDSettingsActions, OpenIDSettingsActionTypes, OpenIDSettings } from '../model/openIDSettings';
import { SamlSettingsActions, SamlSettingsActionTypes, SamlSettings } from '../model/samlSettings';
import {
  SignedLoginSettings,
  SignedLoginSettingsActionTypes,
  SignedLoginSettingsActions,
} from '../model/signedLoginSettings';

import {
  TenantAccessSettings,
  TenantAccessSettingsActionTypes,
  TenantAccessSettingsActions,
} from '../model/tenantAccessSettings';

import createReducer from './createReducer';

export const apiKeySettings = createReducer<ApiKeySettings[]>([], {
  [ApiKeySettingsActionTypes.SET_APIKEYSETTINGS_STATE](state: ApiKeySettings[], action: ApiKeySettingsActions) {
    return [...state, action.payload];
  },

  [ApiKeySettingsActionTypes.RESET_APIKEYSETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const facebookSettings = createReducer<FacebookSettings[]>([], {
  [FacebookSettingsActionTypes.SET_FACEBOOKSETTINGS_STATE](state: FacebookSettings[], action: FacebookSettingsActions) {
    return [...state, action.payload];
  },

  [FacebookSettingsActionTypes.RESET_FACEBOOKSETTINGS_STATE](state: []) {
    return [...state.splice(state.length)];
  },
});

export const googleSettings = createReducer<GoogleSettings[]>([], {
  [GoogleSettingsActionTypes.SET_GOOGLESETTINGS_STATE](state: GoogleSettings[], action: GoogleSettingsActions) {
    return [...state, action.payload];
  },

  [GoogleSettingsActionTypes.RESET_GOOGLESETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const oktaSettings = createReducer<OktaSettings[]>([], {
  [OktaSettingsActionTypes.SET_OKTASETTINGS_STATE](state: OktaSettings[], action: OktaSettingsActions) {
    return [...state, action.payload];
  },

  [OktaSettingsActionTypes.RESET_OKTASETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const openIDSettings = createReducer<OpenIDSettings[]>([], {
  [OpenIDSettingsActionTypes.SET_OPENIDSETTINGS_STATE](state: OpenIDSettings[], action: OpenIDSettingsActions) {
    return [...state, action.payload];
  },

  [OpenIDSettingsActionTypes.RESET_OPENIDSETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const tenantAccessSettings = createReducer<TenantAccessSettings[]>([], {
  [TenantAccessSettingsActionTypes.SET_TENANTACCESSSETTINGS_STATE](
    state: TenantAccessSettings[],
    action: TenantAccessSettingsActions,
  ) {
    return [...state, action.payload];
  },

  [TenantAccessSettingsActionTypes.RESET_TENANTACCESSSETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const samlSettings = createReducer<SamlSettings[]>([], {
  [SamlSettingsActionTypes.SET_SAMLSETTINGS_STATE](state: SamlSettings[], action: SamlSettingsActions) {
    return [...state, action.payload];
  },

  [SamlSettingsActionTypes.RESET_SAMLSETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const signedLoginSettings = createReducer<SignedLoginSettings[]>([], {
  [SignedLoginSettingsActionTypes.SET_SIGNEDLOGINSETTINGS_STATE](
    state: SignedLoginSettings[],
    action: SignedLoginSettingsActions,
  ) {
    return [...state, action.payload];
  },

  [SignedLoginSettingsActionTypes.RESET_SIGNEDLOGINSETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});
