import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { useActions } from 'actions';
import * as ProjectActions from 'actions/project';
import * as NotificationsActions from 'actions/notifications';
import './CreateProjectModal.scss';
import esPlaceholder from 'assets/images/es.svg';
import pencil from 'assets/images/edit-pencil.svg';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import {
  NAME_REQUIRED,
  ONLY_LOWER_CASE,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  NAME_TWO_CHARS_REQUIRED,
  SUBDOMAIN_REQUIRED,
} from 'constants/errors/errors';
import {
  FlightModal,
  FlightTextInput,
  FlightButton,
  FlightTooltip,
  FlightRadioButton,
} from '@flybits/webapp-design-system-react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Jdenticon from 'components/Shared/Jdenticon/Jdenticon';
import {
  CREATE_PROJECT_JDENTICOON,
  CREATE_PROJECT_DEVELOPMENT_ENV,
  CREATE_PROJECT_PERSONAL_ENV,
  CREATE_PROJECT_STAGING_ENV,
  CREATE_PROJECT_PRODUCTION_ENV,
} from 'constants/tooltips/tooltips';
// create project validation
const subDomainRegex = /^[a-zA-Z0-9-]+$/;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(NAME_REQUIRED)
    .min(2, NAME_TWO_CHARS_REQUIRED),
  subdomain: Yup.string()
    .required(SUBDOMAIN_REQUIRED)
    .matches(subDomainRegex, ONLY_LOWER_CASE)
    .min(2, SUBDOMAIN_TWO_CHARS_REQUIRED),
});

interface Props {
  openModal: boolean;
  toggleModal: () => void;
}

interface CreateProject {
  name: string;
  subDomain: string;
}

export function CreateProjectModal(props: Props) {
  const { openModal, toggleModal } = props;
  /* Redux imports */
  const projectActions = useActions(ProjectActions);
  const notficationActions = useActions(NotificationsActions);

  const [editSubDomain, setEditSubDomain] = useState(false);
  function toggleEditSubDomain() {
    setEditSubDomain(editSubDomain => !editSubDomain);
  }

  const initialFormValues = {
    name: 'My Awesome Project',
    environment: 'personal',
    subdomain: 'my-awesome-project',
  };

  const notificationState = useSelector((state: RootState) => state.notifications);
  let type = '';
  let body = '';
  if (notificationState.length !== 0) {
    [{ type, body }] = notificationState;
  }

  const [apiError, setApiError] = useState('');
  const [apiMessageType, setApiMessageType] = useState('');
  const [initialValuess, setInitialValues] = useState(initialFormValues);

  function setApiErrorMessage(body: string) {
    if (body !== '') {
      setEditSubDomain(true);
    }
    setApiError(body);
  }

  function setApiStatusMessage(type: string) {
    setApiMessageType(type);
  }

  function resetApiErrorMessage() {
    setApiError('');
  }

  useEffect(() => {
    setApiErrorMessage(body);
  }, [body]);

  useEffect(() => {
    setApiStatusMessage(type);
  }, [type]);

  if (apiMessageType === 'success') {
    setInitialValues(initialFormValues);
    toggleModal();
    setEditSubDomain(false);
    setApiStatusMessage('');
  }

  return (
    <div className="projects-page__modal">
      {/* {Begin New Project Modal} */}
      <Formik
        initialValues={initialValuess}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let subDomainValueForServer = '';
          if (values.subdomain.indexOf(values.environment) === -1) {
            subDomainValueForServer =
              values.environment.toLocaleLowerCase() + '-' + values.subdomain.toLocaleLowerCase();
          }
          const submitData: CreateProject = {
            name: values.name,
            subDomain: subDomainValueForServer,
          };
          projectActions.createNewProject(submitData);
          setSubmitting(true);
          if (type !== '') {
            notficationActions.hideNotification();
            return;
          }
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, handleReset }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={(e: React.FormEvent<HTMLInputElement>) => {
              handleReset(e);
              toggleModal();
            }}
            scrollable={false}
            size="large"
            content={
              <div className="projects-page__modal__content">
                <span className="projects-page__modal__header">Create New Project</span>
                <div>
                  <Col>
                    <FormGroup>
                      <Col className="projects-page__modal">
                        <div className="projects-page__modal__container">
                          {values.subdomain === '' ? (
                            <div className="projects-page__modal__placeholder__text">
                              {values.environment.toLocaleLowerCase() +
                                '-' +
                                values.name.replace(/\s+/g, '-').toLowerCase()}
                              .flybits.com/#/experiences
                            </div>
                          ) : (
                            <div className="projects-page__modal__placeholder__text">
                              {values.environment.toLocaleLowerCase() + '-' + values.subdomain}
                              .flybits.com/#/experiences
                            </div>
                          )}
                          <Jdenticon
                            size="24"
                            value={values.name}
                            className="projects-page__modal__placeholder__jdenticon"
                          />
                          <div className="projects-page__modal__placeholder__name">
                            {values.name + ' '}
                            {values.name !== '' ? (
                              <span className="projects-page__modal__placeholder__name__env">
                                ({values.environment})
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <Row>
                            <img
                              height="100%"
                              width="100%"
                              src={esPlaceholder}
                              alt="dafds"
                              className="projects-page__modal__placeholder__img"
                            />
                          </Row>
                        </div>
                      </Col>
                    </FormGroup>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Row className="projects-page__modal__row">
                          <FlightTooltip
                            direction="right bottom"
                            isEnabled={true}
                            description={CREATE_PROJECT_JDENTICOON}
                          >
                            <Jdenticon size="48.12" value={values.name} className="projects-page__modal__row__icon" />
                          </FlightTooltip>
                          <Label for="name" className="projects-page__modal__row__label">
                            Project Name
                            <Field
                              type="text"
                              name="name"
                              as={FlightTextInput}
                              hasError={touched.name && errors.name ? true : false}
                              className="projects-page__modal__row__label__field"
                              value={values.name}
                              errorMessage={<span>{errors.name}</span>}
                              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                handleChange(e);
                                if (
                                  values.subdomain !== '' &&
                                  values.subdomain === values.name.replace(/\s+/g, '-').toLowerCase()
                                ) {
                                  setFieldValue('subdomain', e.currentTarget.value.replace(/\s+/g, '-').toLowerCase());
                                }
                              }}
                              onBlur={handleBlur}
                            />{' '}
                          </Label>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <div className="projects-page__modal__radio">
                          <Label for="environment" className="projects-page__modal__radio__label">
                            Environment
                            <Col>
                              <Row>
                                <FlightTooltip
                                  direction="bottom"
                                  isEnabled={true}
                                  description={CREATE_PROJECT_PERSONAL_ENV}
                                >
                                  <Field
                                    type="radio"
                                    name="environment"
                                    label="Personal"
                                    checked={values.environment === 'personal' ? true : false}
                                    value="personal"
                                    className="projects-page__modal__radio__group__icon"
                                    as={FlightRadioButton}
                                    onSelect={() => setFieldValue('environment', 'personal')}
                                  />
                                </FlightTooltip>
                                <FlightTooltip
                                  direction="bottom"
                                  isEnabled={true}
                                  description={CREATE_PROJECT_DEVELOPMENT_ENV}
                                >
                                  <Field
                                    type="radio"
                                    name="environment"
                                    label="Development"
                                    checked={values.environment === 'development' ? true : false}
                                    value="development"
                                    className="projects-page__modal__radio__group__icon"
                                    as={FlightRadioButton}
                                    onSelect={() => setFieldValue('environment', 'development')}
                                  />
                                </FlightTooltip>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <FlightTooltip
                                  direction="bottom"
                                  isEnabled={true}
                                  description={CREATE_PROJECT_STAGING_ENV}
                                >
                                  <Field
                                    type="radio"
                                    name="environment"
                                    label="Staging"
                                    checked={values.environment === 'staging' ? true : false}
                                    value="personal"
                                    className="projects-page__modal__radio__group__icon"
                                    as={FlightRadioButton}
                                    onSelect={() => setFieldValue('environment', 'staging')}
                                  />
                                </FlightTooltip>
                                <FlightTooltip
                                  direction="bottom"
                                  isEnabled={true}
                                  description={CREATE_PROJECT_PRODUCTION_ENV}
                                >
                                  <Field
                                    type="radio"
                                    name="environment"
                                    label="Production"
                                    checked={values.environment === 'production' ? true : false}
                                    value="production"
                                    className="projects-page__modal__radio__group__icon"
                                    as={FlightRadioButton}
                                    onSelect={() => setFieldValue('environment', 'production')}
                                  />
                                </FlightTooltip>
                              </Row>
                            </Col>{' '}
                          </Label>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="subdomain" className="projects-page__modal__subdomain__label">
                          Subdomain
                        </Label>
                        <div className="projects-page__modal__subdomain__description">
                          This is your experience studio subdomain. You cannot change your project URL after project
                          creation
                        </div>
                        {!editSubDomain ? (
                          <div className="projects-page__modal__subdomain__edit">
                            <img
                              src={pencil}
                              className="projects-page__modal__subdomain__edit__icon"
                              alt="edit-pencil"
                              onClick={() => toggleEditSubDomain()}
                            />
                            <span className="projects-page__modal__subdomain__edit__text">
                              {values.name === ''
                                ? ''
                                : `${values.environment}-${values.name.replace(/\s+/g, '-').toLowerCase()}-flybits.com`}
                            </span>
                          </div>
                        ) : (
                          <Row>
                            <div className="projects-page__modal__subdomain__edit__label">{values.environment}-</div>
                            <Field
                              type="text"
                              name="subdomain"
                              as={FlightTextInput}
                              hasError={
                                (touched.subdomain && errors.subdomain ? true : false) ||
                                (apiError !== '' ? true : false)
                              }
                              className="projects-page__modal__subdomain__edit__field"
                              value={values.subdomain.toLowerCase()}
                              errorMessage={<div> {errors.subdomain || apiError}</div>}
                              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                handleChange(e);
                                resetApiErrorMessage();
                              }}
                              onBlur={handleBlur}
                            />
                            <div className="projects-page__modal__subdomain__edit__label">.flybits.com</div>
                          </Row>
                        )}
                      </FormGroup>
                    </Form>
                  </Col>
                </div>
              </div>
            }
            footer={
              <div className="modal-footer">
                <FlightButton
                  onClick={(e: React.FormEvent<HTMLInputElement>) => {
                    handleReset(e);
                    setInitialValues(initialFormValues);
                    resetApiErrorMessage();
                    toggleModal();
                    setEditSubDomain(false);
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton type="primary" label="Create Project" onClick={handleSubmit} disabled={!isValid} />
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
