import { SubscriptionAction, SubscriptionActionTypes, Ratelimits } from '../model/subscriptions';
import createReducer from './createReducer';

export const ratelimits = createReducer<Ratelimits[]>([], {
  [SubscriptionActionTypes.SET_RATELIMITS_STATE](state: Ratelimits[], action: SubscriptionAction) {
    return [...state, action.payload];
  },

  [SubscriptionActionTypes.RESET_RATELIMITS_STATE](state: []) {
    return state.filter(item => !item);
  },
});
