export interface OktaSettings {
  authorizationServerID: string;
  clientID: string;
  host: string;
  secretKey: string;
}

export interface OktaSettingsForApi {
  AuthorizationServerID: string;
  ClientID: string;
  Host: string;
  SecretKey: string;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum OktaSettingsActionTypes {
  SET_OKTASETTINGS_STATE = 'SET_OKTASETTINGS_STATE',
  RESET_OKTASETTINGS_STATE = 'RESET_OKTASETTINGS_STATE',
}

interface OktaSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type OktaSettingsActions =
  | OktaSettingsActionCreatorType<typeof OktaSettingsActionTypes.SET_OKTASETTINGS_STATE, OktaSettings>
  | OktaSettingsActionCreatorType<typeof OktaSettingsActionTypes.RESET_OKTASETTINGS_STATE, OktaSettings>;
