import {
  SilentPushActionTypes,
  SilentPushSettings,
  SilentPushActions,
  ObjectForSilentPushApi,
} from '../model/silentPushSettings';
import SettingsService from 'services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

interface Entities {
  actions: SilentPushSettings[];
  entityKey?: string;
}

export function setSilentPushSettings(apnsSettings: SilentPushSettings): SilentPushActions {
  return {
    type: SilentPushActionTypes.SET_SILENT_PUSH_SETTINGS,
    payload: apnsSettings,
  };
}

export function resetSilentPushSettings(): SilentPushActions {
  return {
    type: SilentPushActionTypes.RESET_SILENT_PUSH_SETTINGS,
  };
}

export function fetchSilentPushSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getSilentPushSettings();
      dispatch(resetSilentPushSettings());
      const flattenedResponse = response.data.settings.entities.flatMap((entity: Entities) =>
        entity.actions.map(action => action),
      );
      dispatch(setSilentPushSettings(flattenedResponse));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function addOrUpdateSilentPushSettings(subscribed: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForSilentPushApi: ObjectForSilentPushApi = {
        settings: {
          entities: [{ actions: [{ action: 'updated', subscribed: subscribed }], entityKeyName: 'relevantContent' }],
        },
        configName: {
          configName: 'entity-event-silent-push',
        },
      };
      const response = await req.createSilentPushSetting(objectForSilentPushApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Silent Push Settings Saved.',
            type: 'success',
          }),
        );
        dispatch(fetchSilentPushSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
