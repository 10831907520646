import React, { useEffect, useState } from 'react';
import { useActions } from 'actions';
import * as AuthActions from 'actions/auth';
import { ContextPlugin } from 'model/contextPlugins';
import { Row } from 'reactstrap';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import * as ContextPluginsActions from 'actions/contextPlugins';
import { history } from 'configureStore';
import {
  FlightButton,
  FlightTable,
  FlightSnackbar,
  FlightSelect,
  FlightTextInput,
} from '@flybits/webapp-design-system-react';
import { Project } from 'model/project';
import { ReactComponent as TrashBin } from 'assets/images/trash_bin.svg';
import { Attributes, Attribute } from 'model/contextPlugins/contextPlugins';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import { VALUE_TYPE_OPTIONS } from 'constants/valueTypes';
import { stringTimeConverter, dataExpires } from 'helpers/timeConverters';
import './ViewContextPlugin.scss';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ProjectToken } from 'model/auth';

interface SelectOptionProps {
  key: string;
  name: string;
}
interface Props {
  contextPluginFromState?: ContextPlugin[];
  changed?: boolean;
  projectFromState?: Project[];
  projectTokenFromState?: ProjectToken[];
}
interface TableBody {
  name: JSX.Element;
  description?: string;
  dataexpiry?: string;
  dataformat?: string;
  trash?: JSX.Element;
  key: string;
}
export default function ViewContextPlugin(props: Props) {
  const { contextPluginFromState = [], projectFromState = [], projectTokenFromState = [], changed } = props;
  const [showBanner, setShowBanner] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const authActions = useActions(AuthActions);
  const contextPluginActions = useActions(ContextPluginsActions);
  let listOfNames: Array<string> = [];
  let searchedTerm: Attribute[] = [];
  let isReserved = undefined;
  let refreshRate = undefined;
  let values = undefined;
  if (contextPluginFromState?.length !== 0) {
    [{ isReserved, refreshRate, values }] = contextPluginFromState;
  }
  if (refreshRate) {
    refreshRate = stringTimeConverter(refreshRate);
  }
  const hideBanner = () => {
    setShowBanner(showBanner => !showBanner);
  };
  function filterAttributes(myAttributes: Attribute[]) {
    if (dataType.key !== 'default') {
      if (scope.key !== 'default') {
        myAttributes = myAttributes.filter(attribute => {
          return attribute.valueType === dataType.key && attribute.isTenantScoped.toString() === scope.key;
        });
      } else {
        myAttributes = myAttributes.filter(attribute => {
          return attribute.valueType === dataType.key;
        });
      }
    } else {
      if (scope.key !== 'default') {
        myAttributes = myAttributes.filter(attribute => {
          return attribute.isTenantScoped.toString() === scope.key;
        });
      }
    }
    return myAttributes;
  }
  let attributes: Attribute[] = [];
  let attributesExist = false;
  if (values) {
    attributesExist = Object.keys(values).length !== 0;
    attributes = Object.values(values);
  }
  const [myAttributes, setAttributes] = useState<Attributes[]>([]);
  if (contextPluginFromState.length !== 0 && myAttributes.length === 0 && values) {
    setAttributes([...myAttributes, Object.assign(values)]);
  }
  if (searchTerm !== '' && values) {
    searchedTerm = searchWithRegExp(attributes, searchTerm);
  }
  const createAttribute = () => {
    history.action = 'PUSH';
    contextPluginActions.setCreateAttribute(contextPluginFromState && contextPluginFromState[0], true);
  };
  const tableHeaders = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
    },
    {
      name: 'Description',
      key: 'description',
      isVisible: true,
    },
    {
      name: 'Data expiry',
      key: 'dataexpiry',
      isVisible: true,
    },
    {
      key: 'trash',
      name: '',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  const [scope, setScope] = useState<SelectOptionProps>({
    key: 'default',
    name: 'Any Scope',
  });
  const handleScopeOptionClick = (option: SelectOptionProps) => {
    setScope(option);
  };
  const [dataType, setDataType] = useState<SelectOptionProps>({
    key: 'default',
    name: 'Any Type',
  });
  const handleDataOptionClick = (option: SelectOptionProps) => {
    setDataType(option);
  };
  const [isToggleStatusConfirm, setIsToggleStatusConfirm] = useState(false);
  const toggleStatusConfirm = () => {
    setIsToggleStatusConfirm(isToggleStatusConfirm => !isToggleStatusConfirm);
  };
  const handleCancelStatus = () => {
    toggleStatusConfirm();
  };
  const toggleStatus = () => {
    const status = !(contextPluginFromState && contextPluginFromState[0]).tenantConfig.isEnabled;
    (contextPluginFromState && contextPluginFromState[0]).tenantConfig.isEnabled = status;
    if (status) {
      contextPluginActions.enableContextPluginStatus((contextPluginFromState && contextPluginFromState[0]));
    } else {
      contextPluginActions.disableContextPluginStatus((contextPluginFromState && contextPluginFromState[0]));
    }
    setIsToggleStatusConfirm(false);
  };
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        authActions.fetchProjectToken(projectFromState[0].id);
        history.push('/project/' + projectTokenFromState[0].projectId + '/context-plugins');
        contextPluginActions.resetContextPluginState();
      }
    };
    // eslint-disable-next-line
  }, []);
  const savePlugin = () => {
    let attributesForApi: Attributes = {};
    for (const i in attributes) {
      const attributeValues = {
        uid: 'ctx.' + attributes[i].name,
        name: attributes[i].name,
        description: attributes[i].description,
        valueType: attributes[i].valueType,
        valueKeys: attributes[i].valueKeys,
        inputAttrExpiryRate: attributes[i].inputAttrExpiryRate,
        inputAttrExpiryRateUnit: attributes[i].inputAttrExpiryRateUnit,
        defaultExpDuration: attributes[i].defaultExpDuration,
        isNeverExpires: attributes[i].isNeverExpires,
        valueTypeOptions: attributes[i].valueTypeOptions,
        parameters: attributes[i].parameters,
        isTenantScoped: attributes[i].isTenantScoped,
        isTimeContext: attributes[i].isTimeContext,
        shouldBeSaved: attributes[i].shouldBeSaved,
        isShowValue: attributes[i].isShowValue,
        isShowDisplayName: attributes[i].isShowDisplayName,
      };
      attributesForApi[attributes[i].name] = attributeValues;
    }
    contextPluginFromState[0].values = attributesForApi;
    contextPluginActions.updateContextPlugin((contextPluginFromState && contextPluginFromState[0]));
  };
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [idDelete, setIdForDelete] = useState('');
  const toggleDeleteConfirm = (id: string) => {
    if (idDelete === '') {
      setIdForDelete(id);
    } else {
      setIdForDelete('');
    }
    setIsDeleteConfirm(isDeleteConfirm => !isDeleteConfirm);
  };
  const handleCancelDelete = () => {
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  const deleteAttribute = (data: Attribute) => {
    contextPluginActions.deleteAttribute(contextPluginFromState && contextPluginFromState[0], idDelete);
    contextPluginActions.setChangedPlugin(contextPluginFromState && contextPluginFromState[0]);
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  const viewAttribute = (name: string) => {
    history.action = 'PUSH';
    contextPluginActions.setViewAttribute(contextPluginFromState && contextPluginFromState[0], name);
  };
  attributes = [];
  const tableBody: TableBody[] = [];
  for (const name in myAttributes) {
    const iterator = myAttributes[name];
    for (const innerName in iterator) {
      attributes.push(iterator[innerName]);
    }
  }
  if (searchTerm === '') {
    attributes = filterAttributes(attributes);
    for (const i in attributes) {
      tableBody.push({
        name: (
          <div className="context-plugins__table__name__text">
            <span onClick={() => viewAttribute(attributes[i].uid)} className="context-plugins__table__name__text__name">
              {' '}
              {attributes[i].name}{' '}
            </span>
            <span className="context-plugins__table__name__text__id">{attributes[i].uid} </span>
          </div>
        ),
        description: attributes[i].description,
        dataexpiry: !attributes[i].isNeverExpires ? dataExpires(attributes[i].defaultExpDuration) : 'never',
        trash: (
          <TrashBin
            className={isReserved ? 'view-context-plugin__nodisplay' : ''}
            onClick={() => toggleDeleteConfirm(attributes[i].name)}
          />
        ),
        key: attributes[i].uid,
      });
    }
  } else {
    searchedTerm = filterAttributes(Object.values(searchedTerm));
    for (const i in searchedTerm) {
      listOfNames.push(searchedTerm[i].name);
      tableBody.push({
        name: (
          <div className="context-plugins__table__name__text">
            <span onClick={() => viewAttribute(attributes[i].uid)} className="context-plugins__table__name__text__name">
              {' '}
              {searchedTerm[i].name}{' '}
            </span>
            <span className="context-plugins__table__name__text__id">{searchedTerm[i].uid} </span>
          </div>
        ),
        description: searchedTerm[i].description,
        dataexpiry: !searchedTerm[i].isNeverExpires ? dataExpires(searchedTerm[i].defaultExpDuration) : 'never',
        trash: (
          <TrashBin
            className={isReserved ? 'view-context-plugin__nodisplay' : ''}
            onClick={() => toggleDeleteConfirm(searchedTerm[i].name)}
          />
        ),
        key: searchedTerm[i].uid,
      });
    }
  }
  if (tableBody.length === 0) {
    tableBody.push({
      name: <span>No Results</span>,
      key: '',
    });
  }
  return (
    <div>
      <div className="view-context-plugin">
        <div>
          <div className="view-context-plugin__plugin">
            <span className="view-context-plugin__context-labels">Context Id</span>
            <span className="view-context-plugin__context-labels">Status</span>
            <span className="view-context-plugin__context-labels">Scope</span>
            <span className="view-context-plugin__context-labels">Refresh Rate</span>
            <span className="view-context-plugin__context-settings">{(contextPluginFromState && contextPluginFromState[0]).id}</span>
            <span
              onClick={toggleStatusConfirm}
              className={
                (contextPluginFromState && contextPluginFromState[0])?.tenantConfig.isEnabled
                  ? 'view-context-plugin__active'
                  : 'view-context-plugin__inactive'
              }
            >
              {(contextPluginFromState && contextPluginFromState[0])?.tenantConfig.isEnabled ? 'Active' : 'Inactive'}
            </span>
            <span className="view-context-plugin__context-settings">
              {(contextPluginFromState && contextPluginFromState[0])?.userScope ? 'User' : 'Project'}
            </span>
            <span className="view-context-plugin__context-settings">{refreshRate ? refreshRate : ''}</span>
          </div>
        </div>
        <div className="view-context-plugin__tablecontainer">
          {attributesExist ? (
            <div>
              <hr></hr>
              <div className="view-context-plugin__title">Attributes</div>
              <FlightSnackbar
                isVisible={showBanner}
                className="context-plugins__snackbar"
                isFloating
                icon="infoFilled"
                title=""
                content={'Attributes store context information'}
                type="info"
                action={hideBanner}
                actionName="Got it!"
                isAutoDismiss={true}
              />
              <div className="context-plugins__container">
                <div className="context-plugins__filer">
                  <span className="context-plugins__filer__text">Filter by: </span>
                  <FlightSelect
                    label="Any Status"
                    className="context-plugins__filer__status"
                    handleOptionClick={handleDataOptionClick}
                    selected={dataType}
                    options={VALUE_TYPE_OPTIONS}
                    width="110px"
                  />
                  <FlightSelect
                    label="Created by anyone"
                    className="context-plugins__filer__created-by"
                    selected={scope}
                    handleOptionClick={handleScopeOptionClick}
                    options={[
                      {
                        key: 'default',
                        name: 'Any Scope',
                      },
                      {
                        key: 'false',
                        name: 'Project Scope',
                      },
                      {
                        key: 'true',
                        name: 'User Scope',
                      },
                    ]}
                    width="160px"
                  />
                </div>
                <div className="context-plugins__buttons">
                  <Row>
                    <FlightTextInput
                      placeholderText="Search for a plugin"
                      type="text"
                      name="search"
                      className="context-plugins__search-field"
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                    <SearchIcon className="context-plugins__search-icon" />
                    <FlightButton
                      className="context-plugins__buttons__import__new-plugin"
                      label="+  New Attribute"
                      disabled={isReserved}
                      type="primary"
                      onClick={createAttribute}
                    />
                    <FlightButton
                      className="view-context-plugin__save"
                      label="Save"
                      disabled={isReserved || (!changed && !(contextPluginFromState && contextPluginFromState[0]).isChanged)}
                      type="primary"
                      onClick={savePlugin}
                    />
                  </Row>
                </div>
              </div>
              <FlightTable
                className="view-context-plugin__table"
                tableHeaders={tableHeaders}
                tableData={tableBody}
                hasPaginationBeforeTable={false}
              />
            </div>
          ) : null}
        </div>
        <ConfirmModal
          isVisible={isToggleStatusConfirm}
          title="Confirm Change Status"
          message="Are you sure you want to change the status of this plugin?"
          toggleCallback={toggleStatusConfirm}
          continueCallback={toggleStatus}
          cancelCallback={handleCancelStatus}
        />
        <ConfirmModal
          isVisible={isDeleteConfirm}
          title="Delete Attribute"
          message="Are you sure you want to delete this attribute?"
          toggleCallback={toggleDeleteConfirm}
          continueCallback={deleteAttribute}
          cancelCallback={handleCancelDelete}
        />
      </div>
    </div>
  );
}
