import React, { useEffect, useState } from 'react';
import {
  FlightTextInput,
  FlightButton,
  FlightSelect,
  FlightCheckbox,
  FlightNumberInput,
  FlightRadioButton,
  FlightTable,
} from '@flybits/webapp-design-system-react';
import { Row, Col } from 'reactstrap';
import { getTimeMinutes, minuteTimeConverter, getTimeUnitMinutes } from 'helpers/timeConverters';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Project } from 'model/project';
import { useActions } from 'actions';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import * as ContextPluginActions from 'actions/contextPlugins';
import { ReactComponent as TrashBin } from 'assets/images/trash_bin.svg';
import { Attributes, Attribute, ContextPlugin } from 'model/contextPlugins';
import {
  NAME_REQUIRED,
  NO_SPECIAL_CHARACTERS,
  GENERIC_REQUIRED,
  MUST_BE_INT,
  MUST_BE_FLOAT,
  MUST_BE_BOOLEAN,
} from 'constants/errors/errors';
import DoubleArrayFields from 'components/Shared/DoubleArrayFields/DoubleArrayFields';
import './CreateContextAttribute.scss';
import CreateParameterModal from './CreateParameterModal';
import { history } from 'configureStore';

interface SelectOptionProps {
  key: string;
  name: string;
}
interface CheckboxState {
  state: 'SELECTED' | 'UNSELECTED' | string;
}
interface ValueTypeOptions {
  value: any;
  displayName: string;
}
interface RadioButtonState {
  state: 'string' | 'int' | 'float' | string;
}
interface Parameter {
  key: string;
  valueTypeOptions: Array<ValueTypeOptions>;
}
interface Parameters {
  [key: string]: Parameter;
}
const nameRegex = /^[A-Za-z0-9-]*$/;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(NAME_REQUIRED)
    .matches(nameRegex, NO_SPECIAL_CHARACTERS),
});
const integerRegex = /^\+?\d+$/;
const validateInteger = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(GENERIC_REQUIRED)
      .matches(integerRegex, MUST_BE_INT),
  ),
  key: Yup.string().required(GENERIC_REQUIRED),
});

const validateString = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED)),
  key: Yup.string().required(GENERIC_REQUIRED),
});

const floatRegex = /^[+-]?\d+(\.\d+)?$/;
const validateFloat = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(GENERIC_REQUIRED)
      .matches(floatRegex, MUST_BE_FLOAT),
  ),
  key: Yup.string().required(GENERIC_REQUIRED),
});

const booleanRegex = /^(true|false)$/;
const validateBoolean = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(GENERIC_REQUIRED)
      .matches(booleanRegex, MUST_BE_BOOLEAN),
  ),
  key: Yup.string().required(GENERIC_REQUIRED),
});
interface Props {
  callback: (data: Attributes, name: string) => void;
  contextAttributeFromState?: Attribute[];
  isReserved?: boolean;
  projectFromState?: Project[];
  contextPluginFromState?: ContextPlugin[];
}
interface TableBody {
  keyName: JSX.Element;
  inputType: string;
  key: number;
  trash: JSX.Element;
}
interface ParametersForApi {
  index: number;
  key: string;
  isShowValue: boolean;
  isShowDisplayName: boolean;
  valueTypeOptions: Array<ValueTypeOptions>;
  valueType: string;
  valueKeys: any;
  performsExtraProcessing: boolean;
  extraProcessingAddress: string;
  isTenantScoped: boolean;
  shouldBeSaved: boolean;
  isTimeContext: boolean;
}
export default function CreateContextAttribute(props: Props) {
  const {
    callback,
    projectFromState = [],
    contextPluginFromState = [],
    isReserved,
    contextAttributeFromState = [],
  } = props;
  const contextPluginActions = useActions(ContextPluginActions);
  let arrValues: Array<string> = [];
  let nameValues: Array<string> = [];
  let valueTypeOptions: ValueTypeOptions[] = [];
  const [parameterState, setParameterState] = useState<Parameter[]>();
  const [valuesFromArrayFields, setValuesFromArrayFields] = useState<Array<string>>([]);
  const [displayNamesFromArrayFields, setDisplayNamesFromArrayFields] = useState<Array<string>>([]);
  const getValuesFromArrayFields = (data: string, nameData: string) => {
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
    setDisplayNamesFromArrayFields([...displayNamesFromArrayFields.splice(0)]);
    setDisplayNamesFromArrayFields([nameData]);
    valueTypeOptions = [];
    if (data && isViewAttribute) {
      for (let i = 0; i < data.length; i++) {
        valueTypeOptions.push({
          value: data[i],
          displayName: nameData[i],
        });
      }
      values[isViewAttribute].valueTypeOptions = valueTypeOptions;
      contextPluginFromState[0].values[isViewAttribute].valueTypeOptions = valueTypeOptions;
    }
  };
  let subDomain = undefined;
  let uid: string = '';
  if (projectFromState.length !== 0) {
    [{ subDomain }] = projectFromState;
  }
  let values: Attributes = {};
  let isViewAttribute = '';
  if (contextPluginFromState?.length !== 0) {
    [{ values }] = contextPluginFromState;
  }
  isViewAttribute = (contextPluginFromState[0] && contextPluginFromState[0])?.isViewAttribute!;
  if (isViewAttribute) {
    uid = isViewAttribute;
    isViewAttribute = isViewAttribute.substring(isViewAttribute.lastIndexOf('.') + 1, isViewAttribute.length);
  }
  let projectName = subDomain?.slice(0, subDomain.length - 12);
  let parameters: any = [];
  let defaultExpDuration = undefined;
  if (isViewAttribute) {
    parameters = values[isViewAttribute].parameters;
    valueTypeOptions = values[isViewAttribute].valueTypeOptions;
    defaultExpDuration = values[isViewAttribute].defaultExpDuration;
    if (defaultExpDuration < 0) {
      defaultExpDuration = 1;
    }
    if (Object.keys(valueTypeOptions).length !== 0) {
      for (const value in valueTypeOptions) {
        arrValues.push(valueTypeOptions[value].value);
        nameValues.push(valueTypeOptions[value].displayName);
      }
      if (arrValues.length < 1) {
        arrValues.push('');
        nameValues.push('');
      }
    } else {
      arrValues.push('');
      nameValues.push('');
    }
  } else {
    arrValues.push('');
    nameValues.push('');
  }
  const initialFormValues = {
    name: values[isViewAttribute]?.name ? values[isViewAttribute].name : '',
    description: values[isViewAttribute]?.description ? values[isViewAttribute].description : '',
    valueType: values[isViewAttribute]?.valueType ? values[isViewAttribute].valueType : '',
    enableOptions:
      values[isViewAttribute]?.valueTypeOptions && values[isViewAttribute].valueTypeOptions.length !== 0 ? true : false,
    enableValues: false,
    tenantScoped: false,
    historicalPersistence: values[isViewAttribute]?.shouldBeSaved ? values[isViewAttribute].shouldBeSaved : true,
    dateExpiresAt:
      values[isViewAttribute]?.defaultExpDuration && values[isViewAttribute]?.defaultExpDuration > 0
        ? getTimeMinutes(values[isViewAttribute].defaultExpDuration)
        : 1,
  };
  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };
  const setValidationSchema = (valueType: string) => {
    if (valueType === 'float') {
      return validateFloat;
    } else if (valueType === 'int') {
      return validateInteger;
    } else if (valueType === 'boolean') {
      return validateBoolean;
    } else {
      return validateString;
    }
  };
  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(showCreateModal => !showCreateModal);
  };
  const [newParameters, setNewParameters] = useState<Parameters[]>([]);
  const getParameterFromModal = (data: Parameters) => {
    if (parameterState?.length !== 0) {
      const editParameters: Parameter[] = [];
      for (const key in newParameters) {
        const iterator = newParameters[key];
        for (const innerKey in iterator) {
          if (parameterState?.[0] !== iterator[innerKey]) {
            editParameters.push(iterator[innerKey]);
          } else {
            editParameters.push({
              key: iterator[innerKey].key,
              valueTypeOptions: data[iterator[innerKey].key].valueTypeOptions,
            });
          }
        }
      }
      setNewParameters([]);
      setNewParameters([Object.assign(editParameters)]);
    } else {
      setNewParameters([...newParameters, Object.assign(data)]);
    }
  };
  if (parameters && parameters?.length !== 0) {
    if (isViewAttribute && newParameters.length === 0) {
      setNewParameters([...newParameters, Object.assign(parameters)]);
    }
  }
  const deleteParameter = (data: Parameter) => {
    const delParameters: Parameter[] = [];
    for (const key in newParameters) {
      const iterator = newParameters[key];
      for (const innerKey in iterator) {
        if (iterator[innerKey] !== parameterDelete) {
          delParameters.push(iterator[innerKey]);
        }
      }
    }
    setNewParameters([Object.assign(delParameters)]);
    setParameterForDelete({ key: '', valueTypeOptions: [] });
    toggleDeleteConfirm({ key: '', valueTypeOptions: [] });
  };
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const toggleCancelConfirm = () => {
    setIsCancelConfirm(!isCancelConfirm);
  };
  const handleCancel = () => {
    contextPluginActions.setCreateAttribute(contextPluginFromState && contextPluginFromState[0], false);
    contextPluginActions.setViewAttribute(contextPluginFromState && contextPluginFromState[0], '');
  };
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [parameterDelete, setParameterForDelete] = useState<Parameter>({ key: '', valueTypeOptions: [] });
  const toggleDeleteConfirm = (parameter: Parameter) => {
    if (parameterDelete.key === '') {
      setParameterForDelete(parameter);
    } else {
      setParameterForDelete({ key: '', valueTypeOptions: [] });
    }
    setIsDeleteConfirm(isDeleteConfirm => !isDeleteConfirm);
  };
  const handleCancelDelete = () => {
    setParameterForDelete({ key: '', valueTypeOptions: [] });
    toggleDeleteConfirm({ key: '', valueTypeOptions: [] });
  };
  const [expiryDateTime, setExpiryDateTime] = useState<SelectOptionProps>({
    key: 'neverexpires',
    name: 'Never Expires',
  });
  const timeOptions = [
    {
      key: 'neverexpires',
      name: 'Never Expires',
    },
    {
      key: 'minutes',
      name: 'Minutes',
    },
    {
      key: 'hours',
      name: 'Hours',
    },
    {
      key: 'days',
      name: 'Days',
    },
    {
      key: 'weeks',
      name: 'Weeks',
    },
    {
      key: 'months',
      name: 'Months',
    },
    {
      key: 'years',
      name: 'Years',
    },
  ];
  if (isViewAttribute && values[isViewAttribute].defaultExpDuration) {
    if (values[isViewAttribute].defaultExpDuration > 1 && expiryDateTime.key === 'neverexpires') {
      setExpiryDateTime(minuteTimeConverter(values[isViewAttribute].defaultExpDuration));
    }
  }
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.go(1);
        contextPluginActions.setCreateAttribute(contextPluginFromState && contextPluginFromState[0], false);
        contextPluginActions.setViewAttribute(contextPluginFromState && contextPluginFromState[0], '');
        history.action = 'PUSH';
      }
    };
    //eslint-disable-next-line
  }, []);
  const handleTimeOptionClick = (option: SelectOptionProps) => {
    setExpiryDateTime(option);
  };
  const addParameter = () => {
    setParameterState([]);
    toggleCreateModal();
  };
  const tableHeaders = [
    {
      key: 'keyName',
      name: 'Key',
      isVisible: true,
      hideTooltip: true,
    },
    {
      key: 'inputType',
      name: 'Input Type',
      isVisible: true,
      hideTooltip: true,
    },
    {
      key: 'trash',
      name: '',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  let tableBody: TableBody[] = [];
  for (const parameter in newParameters) {
    const iterator = newParameters[parameter];
    for (const innerKey in iterator) {
      tableBody.push({
        keyName: (
          <span
            className="context-plugins__table__name__text__name"
            onClick={() => {
              setParameterState([Object.assign(iterator[innerKey])]);
              toggleCreateModal();
            }}
          >
            {iterator[innerKey].key}
          </span>
        ),
        inputType:
          iterator[innerKey].valueTypeOptions?.length !== 0 &&
          iterator[innerKey].valueTypeOptions?.[0]?.value?.length !== 0
            ? 'dropdown'
            : 'freeform',
        key: Math.random(),
        trash: <TrashBin className="trash" onClick={() => toggleDeleteConfirm(iterator[innerKey])} />,
      });
    }
  }
  return (
    <div className="create-context-attribute">
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            let parametersForApi: ParametersForApi[] = [];
            let index = 0;
            for (const parameter in newParameters) {
              const iterator = newParameters[parameter];
              for (const innerKey in iterator) {
                parametersForApi.push({
                  index: index,
                  key: iterator[innerKey].key,
                  isShowValue: values.enableValues,
                  isShowDisplayName: true,
                  valueTypeOptions: iterator[innerKey].valueTypeOptions,
                  valueType: !values.valueType ? 'string' : values.valueType === 'DateTime' ? 'int' : values.valueType,
                  valueKeys: null,
                  performsExtraProcessing: false,
                  extraProcessingAddress: '',
                  isTenantScoped: values.tenantScoped,
                  shouldBeSaved: values.historicalPersistence,
                  isTimeContext: false,
                });
                index++;
              }
            }
            var timeUnit = getTimeUnitMinutes(expiryDateTime.key);
            if (values.valueType === 'DateTime') {
              values.valueType = 'int';
            }
            const createContextAttribute = {
              uid: contextAttributeFromState[0]?.uid
                ? contextAttributeFromState[0].uid
                : 'ctx.' + projectName + '.' + (contextPluginFromState && contextPluginFromState[0])?.category + '.' + values.name,
              name: values.name,
              description: values.description,
              valueType: values.valueType ? values.valueType : 'string',
              valueKeys: [],
              inputAttrExpiryRate: values.dateExpiresAt,
              inputAttrExpiryRateUnit: expiryDateTime.key !== 'neverexpires' ? expiryDateTime.key : '',
              defaultExpDuration: expiryDateTime.key === 'neverexpires' ? -1 : values.dateExpiresAt * timeUnit,
              isNeverExpires: expiryDateTime.key === 'neverexpires',
              valueTypeOptions: valueTypeOptions,
              parameters: parametersForApi,
              isTenantScoped: values.tenantScoped,
              isTimeContext: false,
              shouldBeSaved: values.historicalPersistence,
              isShowValue: values.enableValues,
              isShowDisplayName: true,
            };
            callback({ [values.name]: createContextAttribute }, values.name);
            resetForm();
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue }) => (
            <div className="create-attribute-form">
              <hr></hr>
              <div className="pt-5 create-attribute__fields">Data Format</div>
              <Row>
                {!isViewAttribute ? (
                  <div className="create-attribute__options">
                    <Field
                      type="radio"
                      name="datatype"
                      label="String"
                      disabled={contextAttributeFromState?.length !== 0}
                      checked={values.valueType === 'string' || !values.valueType}
                      value="string"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('valueType', 'string')}
                    />
                    <Field
                      type="radio"
                      name="datatype"
                      label="Integer"
                      disabled={contextAttributeFromState?.length !== 0}
                      checked={values.valueType === 'int'}
                      value="int"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('valueType', 'int')}
                    />
                    <Field
                      type="radio"
                      name="datatype"
                      label="Float"
                      disabled={contextAttributeFromState?.length !== 0}
                      checked={values.valueType === 'float'}
                      value="float"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('valueType', 'float')}
                    />
                    <Field
                      type="radio"
                      name="datatype"
                      label="Boolean"
                      disabled={contextAttributeFromState?.length !== 0}
                      checked={values.valueType === 'bool'}
                      value="bool"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('valueType', 'bool')}
                    />
                    <Field
                      type="radio"
                      name="datatype"
                      label="DateTime"
                      disabled={contextAttributeFromState?.length !== 0}
                      checked={values.valueType === 'DateTime'}
                      value="DateTime"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('valueType', 'DateTime')}
                    />
                  </div>
                ) : (
                  <Field
                    type="radio"
                    name="datatype"
                    label={values.valueType}
                    disabled={true}
                    checked={true}
                    value={values.valueType}
                    className={'create-attribute__options__option'}
                    as={FlightRadioButton}
                    onSelect={() => setFieldValue('valueType', '')}
                  />
                )}
              </Row>
              <div>
                <div className="pt-5 create-context-plugin__fields">Scope</div>
              </div>
              {!isViewAttribute ? (
                <Row>
                  <Field
                    type="radio"
                    name="tenantScoped"
                    label="User"
                    checked={!values.tenantScoped}
                    disabled={contextAttributeFromState.length !== 0}
                    value="tenantScoped"
                    className={'create-attribute__options__option'}
                    as={FlightRadioButton}
                    onSelect={() => setFieldValue('tenantScoped', false)}
                  />
                  <Field
                    type="radio"
                    name="tenantScoped"
                    label="Project"
                    checked={values.tenantScoped}
                    disabled={contextAttributeFromState.length !== 0}
                    value="scoped"
                    as={FlightRadioButton}
                    className={'create-attribute__options__option'}
                    onSelect={() => setFieldValue('tenantScoped', true)}
                  />
                </Row>
              ) : (
                <Row>
                  <Field
                    type="radio"
                    name="tenantScoped"
                    label={values.tenantScoped ? 'Project' : 'User'}
                    checked={true}
                    disabled={true}
                    value="scoped"
                    as={FlightRadioButton}
                    className={'create-attribute__options__option'}
                    onSelect={() => undefined}
                  />
                </Row>
              )}
              <Row>
                <FlightCheckbox
                  label="Historical Persistence"
                  disabled={isReserved}
                  className="create-attribute__hist"
                  checkState={values.historicalPersistence ? 'SELECTED' : 'UNSELECTED'}
                  onSelect={() => setFieldValue('historicalPersistence', !values.historicalPersistence)}
                />
              </Row>
              <span className="create-context-plugin__id-preview">
                This allows you to make conditions referencing past submitted data e.g. “has been walking”
              </span>
              <div className="pt-5 create-context-plugin__fields">Data freshness</div>
              <span className="create-context-plugin__id-preview">
                How long is the data being sent to this plugin going to be valid for? When new data is sent it will
                overwrite the existing data
              </span>
              <Row>
                {expiryDateTime.key !== 'neverexpires' ? (
                  <Field
                    type="number"
                    name="dateExpiresAt"
                    className="create-attribute__expiry"
                    as={FlightNumberInput}
                    disabled={isReserved}
                    placeholderText="Expires after"
                    width="100px"
                    maxValue={1000}
                    minValue={0}
                    value={values.dateExpiresAt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : null}
                <FlightSelect
                  label=""
                  className="create-attribute__expiry"
                  width="100px"
                  disabled={isReserved}
                  selected={expiryDateTime}
                  options={timeOptions}
                  handleOptionClick={handleTimeOptionClick}
                />
              </Row>
              {values.valueType !== 'DateTime' ? (
                <div>
                  <hr></hr>
                  <div className="pt-5 create-attribute__fields">Input Field Details</div>
                  <span>How will a marketer interact with data on the experience studio?</span>
                  <Row>
                    <Field
                      type="radio"
                      name="enableoptions"
                      label="A freeform input field"
                      checked={!values.enableOptions}
                      disabled={isReserved}
                      value="enableOptions"
                      className={'create-attribute__options__option'}
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('enableOptions', false)}
                    />
                    <Field
                      type="radio"
                      name="enableoptions"
                      label="A dropdown input field"
                      checked={values.enableOptions}
                      disabled={isReserved}
                      value="enableOptions"
                      as={FlightRadioButton}
                      className={'create-attribute__options__option'}
                      onSelect={() => setFieldValue('enableOptions', true)}
                    />
                  </Row>
                </div>
              ) : null}
              {!isViewAttribute ? (
                <div>
                  <div className="pt-5 create-attribute__fields">Label</div>
                  <span className="create-context-plugin__id-preview">
                    Used in the context schema and for the experience studio configuration. It cannot be changed after
                    creation
                  </span>
                  <Row>
                    <Field
                      type="text"
                      name="name"
                      className="mt-3 create-attribute__inputs"
                      as={FlightTextInput}
                      disabled={contextAttributeFromState?.length !== 0}
                      width="332px"
                      hasError={
                        (touched.name && errors.name) ||
                        Object.keys((contextPluginFromState && contextPluginFromState[0])?.values)?.includes(values.name)
                          ? true
                          : false
                      }
                      value={values.name}
                      errorMessage={
                        <span>
                          {Object.keys((contextPluginFromState && contextPluginFromState[0])?.values)?.includes(values.name)
                            ? 'Attribute with that label already exists '
                            : errors.name}
                        </span>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Row>
                  <span className="create-context-plugin__id-preview">
                    ctx.{projectName}.{(contextPluginFromState && contextPluginFromState[0]).category}.{values.name}
                  </span>
                </div>
              ) : (
                <div>
                  <div className="pt-5 create-attribute__fields">Attribute ID</div>
                  <Row>
                    <span className="create-context-plugin__id-preview2">{uid}</span>
                  </Row>
                </div>
              )}
              {values.description || !isViewAttribute ? (
                <div className="pt-5 create-attribute__fields">Description</div>
              ) : null}
              {!isViewAttribute ? (
                <Row>
                  <Field
                    type="text"
                    name="description"
                    className="mt-3 create-attribute__inputs"
                    as={FlightTextInput}
                    width="332px"
                    disabled={contextAttributeFromState?.length !== 0}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
              ) : (
                <span className="create-context-plugin__id-preview">{values.description}</span>
              )}
              {values.valueType !== 'DateTime' ? (
                <div className="pt-5 create-attribute__fields">
                  {values.enableOptions ? 'Dropdown selection options' : null}
                </div>
              ) : null}
              <Row>
                {values.enableOptions && values.valueType !== 'DateTime' ? (
                  <div className="create-attribute__inputs">
                    <div>
                      <span className="create-attribute__values">Values</span>
                      <span className="create-attribute__displaynames">Display Names</span>
                    </div>
                    <DoubleArrayFields
                      callback={getValuesFromArrayFields}
                      removeListener={showCreateModal}
                      initialValues={{ values: arrValues, names: nameValues }}
                      hasError={getErrorState}
                      placeHolderText="Enter value"
                      secondPlaceHolderText="Enter display name"
                      validationSchema={setValidationSchema(values.valueType)}
                    />
                  </div>
                ) : null}
              </Row>
              <Row></Row>
              <Row>
                <CreateParameterModal
                  parameterState={parameterState}
                  toggleModal={toggleCreateModal}
                  openModal={showCreateModal}
                  validationSchema={setValidationSchema(values.valueType)}
                  callback={getParameterFromModal}
                />
              </Row>
              {tableBody.length !== 0 ? (
                <FlightTable
                  className="create-attribute__table"
                  tableHeaders={tableHeaders}
                  tableData={tableBody}
                  hasPaginationBeforeTable={false}
                />
              ) : null}
              <Row>
                <FlightButton
                  onClick={addParameter}
                  className="create-attribute__inputs"
                  disabled={isReserved}
                  label="+ New Parameter"
                  theme="link"
                />
              </Row>
              <Row className="create-push-template__page-ftr">
                <FlightButton
                  onClick={() => {
                    if (initialFormValues === values) {
                      handleCancel();
                    } else {
                      toggleCancelConfirm();
                    }
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton
                  onClick={handleSubmit}
                  disabled={
                    errors.name === NO_SPECIAL_CHARACTERS ||
                    isReserved ||
                    (hasError && values.valueType !== 'DateTime' && values.enableOptions) ||
                    values.name.length < 1 ||
                    (!isViewAttribute && Object.keys((contextPluginFromState && contextPluginFromState[0])?.values)?.includes(values.name))
                  }
                  className="create-push-template__page-ftr__save-btn"
                  label="Save"
                  theme="primary"
                />
              </Row>
            </div>
          )}
        </Formik>
        <ConfirmModal
          isVisible={isDeleteConfirm}
          title="Delete Parameter"
          message="Are you sure you want to delete this parameter?"
          toggleCallback={toggleDeleteConfirm}
          continueCallback={deleteParameter}
          cancelCallback={handleCancelDelete}
        />
        <ConfirmModal
          isVisible={isCancelConfirm}
          title="Confirm Exit"
          message="Are you sure you want to exit without saving?"
          toggleCallback={toggleCancelConfirm}
          continueCallback={handleCancel}
          cancelCallback={toggleCancelConfirm}
        />
      </Col>
    </div>
  );
}
