import React, { useState } from 'react';
import { Form, Container, Col, Row } from 'reactstrap';
import './ProjectDetails.scss';
import { Project } from '../../../model/project';
import { getProjectToken } from 'helpers/auth';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import * as NotificationsActions from 'actions/notifications';
import * as ProjectActions from 'actions/project';
import * as Yup from 'yup';
import { useActions } from 'actions';
import pen from 'assets/images/pen.svg';
import { NAME_REQUIRED, NAME_TWO_CHARS_REQUIRED } from 'constants/errors/errors';
import { Formik, Field } from 'formik';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import Jdenticon from 'components/Shared/Jdenticon/Jdenticon';
import ViewBoxLoader from 'components/Shared/ContentLoader/ViewBoxLoader';
import { FlightModal, FlightButton, FlightTextInput } from '@flybits/webapp-design-system-react';

interface Props {
  projectFromState?: Project[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(NAME_REQUIRED)
    .min(2, NAME_TWO_CHARS_REQUIRED),
});

export default function ProjectDetails(props: Props) {
  const { projectFromState = [] } = props;
  let id = '';
  let name = '';
  let subDomain = '';
  let isActive = false;
  let creatorID = '';
  let tierID = '';

  const notficationActions = useActions(NotificationsActions);
  const projectActions = useActions(ProjectActions);
  const [open, setOpen] = useState(false);
  const toggleEditNameModal = () => setOpen(!open);
  if (projectFromState.length !== 0) {
    [{ id, name, subDomain, creatorID, isActive, tierID }] = projectFromState;
  }

  async function copyId() {
    navigator.clipboard.writeText(id);
    const uniqueId = generateUniqueId();
    notficationActions.showNotification({
      id: uniqueId,
      showNotfication: true,
      body: 'Copied to clipboard.',
      type: 'success',
    });
  }

  async function toExperienceStudio() {
    const projectToken = await getProjectToken();
    const url = `https://${subDomain}/#/authenticate?sourceRouteName=experiences&token=${projectToken}`;
    return window.open(url, '_blank');
  }

  // /*Local state for confirm modal
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  function changeStatus(state: boolean) {
    //build project for API
    const data: Project = {
      id: id,
      name: name,
      subDomain: subDomain,
      creatorID: creatorID,
      isActive: state,
      tierID: tierID,
    };
    projectActions.updateExistingProject(data);
  }

  function toggleStates() {
    if (isActive) {
      changeStatus(false);
    } else {
      changeStatus(true);
    }
    confirmModalToggle();
  }

  return (
    <div>
      <FlightModal
        isVisible={confirmModal}
        toggleModalShown={confirmModalToggle}
        scrollable={false}
        size="small"
        header={<span>Confirm</span>}
        content={<span>Are you sure you want to change the status?</span>}
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                confirmModalToggle();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton type="submit" label="Change Status" onClick={toggleStates} />
          </div>
        }
      />
      <Formik
        enableReinitialize
        initialValues={{ id: id, name: name || '', isActive: isActive }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          projectActions.updateExistingProject(values);
          setSubmitting(true);
          resetForm();
          toggleEditNameModal();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
          <FlightModal
            isVisible={open}
            toggleModalShown={toggleEditNameModal}
            header={<span> Edit Project Name</span>}
            content={
              <div>
                <Form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    as={FlightTextInput}
                    placeholder="Project Name"
                    value={values.name}
                    onChange={handleChange}
                    width="332px"
                    hasError={touched.name && errors.name ? true : false}
                    onBlur={handleBlur}
                  />
                  {touched.name && errors.name ? (
                    <div className="project-details-card__error">{errors.name}</div>
                  ) : null}
                </Form>
              </div>
            }
            footer={
              <div className="modal-footer">
                <FlightButton
                  onClick={() => {
                    toggleEditNameModal();
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton type="submit" label="Save" onClick={handleSubmit} disabled={!isValid} />
              </div>
            }
          />
        )}
      </Formik>
      <ReusableCard>
        <Container>
          <Row>
            <Col xs={3}>
              <Jdenticon value={name} size="80px" className="project-details-card__jdenticon" />
            </Col>
            <Col xs={7} className="pt-4">
              <Row className="project-details-card__name">
                {name ? name : <ViewBoxLoader />}
                <img
                  src={pen}
                  alt="edit-pen"
                  className="project-details-card__name__edit"
                  onClick={toggleEditNameModal}
                />
              </Row>
              {/* TODO:sai.karthik@flybits.com / temporarily deactivated toggle Status */}
              {/* we need to figure out how to manage this inside the project as backend has changed */}
              <Row className="project-details-card__status">
                {isActive ? (
                  <div>
                    <span className="project-details-card__status__text">Active</span>
                    <span className="project-details-card__status__action">Deactivate</span>
                  </div>
                ) : (
                  <div>
                    <span className="project-details-card__status__text--inactive">Inactive</span>
                    <span className="project-details-card__status__action">Activate</span>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="pt-4 ">
            <Col className="project-details-card__details">
              <Row className="project-details-card__details__fields">
                <Col xs={4}>Project ID</Col>
                <Col xs={8} className="project-details-card__details__fields--id" onClick={() => copyId()}>
                  {name ? id : <ViewBoxLoader />}
                  <span className="project-details-card__details__fields--id__copy">Copy</span>
                </Col>
              </Row>
              <Row className="project-details-card__details__fields">
                <Col xs={4}>Experience Studio URL</Col>
                <Col
                  xs={8}
                  className="project-details-card__details__fields--link"
                  onClick={() => toExperienceStudio()}
                >
                  {subDomain ? `https://${subDomain}` : <ViewBoxLoader />}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ReusableCard>
    </div>
  );
}
