export interface FcmSettings {
  key: string;
}

export interface FcmSettingsServerObject {
  Key: string;
}

export enum FcmActionTypes {
  SET_FCM_SETTINGS = 'SET_FCM_SETTINGS',
  RESET_FCM_SETTINGS = 'RESET_FCM_SETTINGS',
}

interface FcmActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type FcmActions =
  | FcmActionCreatorTypes<typeof FcmActionTypes.SET_FCM_SETTINGS, FcmSettings>
  | FcmActionCreatorTypes<typeof FcmActionTypes.RESET_FCM_SETTINGS, FcmSettings>;
