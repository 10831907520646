import {
  CreateTenantConnectionParams,
  Datasource,
  DatasourceAction,
  DatasourceActionTypes,
  GetDatasourcesParams,
} from '../model/datasource';
import ConnectorService from '../services/connector.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setDatasourcesState(datasources: Datasource): DatasourceAction {
  return {
    type: DatasourceActionTypes.SET_DATASOURCES_STATE,
    payload: datasources,
  };
}

export function fetchAllDatasources(params: GetDatasourcesParams) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ConnectorService();
      const response = await req.getAllDatasources(params);
      dispatch(setDatasourcesState(response.data.data));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function createTenantConnection(params: CreateTenantConnectionParams) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ConnectorService();
      const response = await req.createTenantConnection(params);
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Datasource added to project.',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function deleteTenantConnection(id: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ConnectorService();
      const response = await req.deleteTenantConnection(id);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Datasource removed from project.',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
