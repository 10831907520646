import React, { useState } from 'react';
import { FlightModal, FlightButton, FlightTextInput, FlightRadioButton } from '@flybits/webapp-design-system-react';
import { Row } from 'reactstrap';
import { Formik, Field } from 'formik';
import './CreateParameterModal.scss';
import DoubleArrayFields from 'components/Shared/DoubleArrayFields/DoubleArrayFields';

interface ValueTypeOptions {
  value: any;
  displayName: string;
}
interface Parameter {
  key: string;
  valueTypeOptions: Array<ValueTypeOptions>;
}
interface ParametersList {
  [key: string]: Parameter;
}
interface Props {
  openModal: boolean;
  toggleModal: () => void;
  callback: (data: ParametersList) => void;
  validationSchema: {};
  parameterState?: Parameter[];
}
export default function CreateParameterModal(props: Props) {
  const { openModal, parameterState = [], toggleModal, callback, validationSchema } = props;
  const arrValues: Array<string> = [];
  const nameValues: Array<string> = [];
  let valueTypeOptions: Array<ValueTypeOptions> = [];
  const [valuesFromArrayFields, setValuesFromArrayFields] = useState<Array<String>>([]);
  const [displayNamesFromArrayFields, setDisplayNamesFromArrayFields] = useState<Array<string>>([]);
  const getValuesFromArrayFields = (data: string, nameData: string) => {
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
    setDisplayNamesFromArrayFields([...displayNamesFromArrayFields.splice(0)]);
    setDisplayNamesFromArrayFields([nameData]);
    valueTypeOptions = [];
    if (data && parameterState.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        valueTypeOptions.push({
          value: data[i],
          displayName: nameData[i],
        });
      }
      parameterState[0].valueTypeOptions = valueTypeOptions;
    }
  };
  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };
  let key: string = '';
  if (parameterState?.length !== 0) {
    [{ key, valueTypeOptions }] = parameterState;
    if (valueTypeOptions) {
      for (const value in valueTypeOptions) {
        arrValues.push(valueTypeOptions[value].value);
        nameValues.push(valueTypeOptions[value].displayName);
      }
    } else {
      arrValues.push('');
      nameValues.push('');
    }
  } else {
    arrValues.push('');
    nameValues.push('');
  }
  const initialFormValues = {
    key: key ? key : '',
    options: [],
    enableOptions: valueTypeOptions?.length !== 0 ? true : false,
  };
  if (arrValues.length === 0) {
    arrValues.push('');
    nameValues.push('');
  }
  const handleCancel = () => {
    toggleModal();
  };
  return (
    <div className="create-parameter">
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          let valueTypeOptions: ValueTypeOptions[] = [];
          if (valuesFromArrayFields[0]) {
            for (let i = 0; i < valuesFromArrayFields[0].length; i++) {
              valueTypeOptions.push({
                value: valuesFromArrayFields[0][i],
                displayName: displayNamesFromArrayFields[0][i],
              });
            }
          }
          const fieldValues: Parameter = {
            key: values.key,
            valueTypeOptions: values.enableOptions ? valueTypeOptions : values.options,
          };
          callback({ [values.key]: fieldValues });
          resetForm();
          toggleModal();
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, isValid, errors, touched }) => (
          <div>
            <FlightModal
              isVisible={openModal}
              size="large"
              className="create-parameter__modal"
              toggleModalShown={() => {
                resetForm();
                handleCancel();
              }}
              header={<span>{parameterState?.length !== 0 ? 'Edit Parameter' : 'Add Parameter'}</span>}
              content={
                <div className="create-parameter__modal__content">
                  <Row>
                    <Field
                      type="text"
                      name="key"
                      label="key"
                      as={FlightTextInput}
                      className="create-parameter__key"
                      width="332px"
                      hasError={touched.key && errors.key ? true : false}
                      value={values.key}
                      disabled={key.length !== 0 ? true : false}
                      errorMessage={<span>{errors.key}</span>}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Row>
                  <div className="pt-5 create-attribute__fields">Input Field Details</div>
                  <span className="create-context-plugin__id-preview">
                    How will a marketer interact with data on the experience studio?
                  </span>
                  <Row>
                    <Field
                      type="radio"
                      className="create-parameter__modal__check"
                      name="enableoptions"
                      label="A freeform input field"
                      checked={!values.enableOptions}
                      value="enableOptions"
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('enableOptions', false)}
                    />
                    <Field
                      type="radio"
                      name="enableoptions"
                      className="create-parameter__modal__check"
                      label="A dropdown input field"
                      checked={values.enableOptions}
                      value="enableOptions"
                      as={FlightRadioButton}
                      onSelect={() => setFieldValue('enableOptions', true)}
                    />
                  </Row>
                  <Row>
                    <div className="pt-5 create-attribute__fields">
                      {values.enableOptions ? 'Dropdown selection options' : null}
                    </div>
                  </Row>
                  <Row>
                    {values.enableOptions ? (
                      <div>
                        <div className="create-parameter__header">
                          <span className="create-parameter__values">Values</span>
                          <span className="create-parameter__displaynames">Display Names</span>
                        </div>
                        <DoubleArrayFields
                          callback={getValuesFromArrayFields}
                          initialValues={{ values: arrValues, names: nameValues }}
                          hasError={getErrorState}
                          placeHolderText="Enter value"
                          secondPlaceHolderText="Enter display name"
                          validationSchema={validationSchema}
                        />
                      </div>
                    ) : null}
                  </Row>
                </div>
              }
              footer={
                <div className="create-push-template__modal__footer">
                  <FlightButton
                    onClick={() => {
                      handleCancel();
                      resetForm();
                    }}
                    label="Cancel"
                    theme="secondary"
                  />
                  <FlightButton
                    type="submit"
                    label="Save Parameter"
                    disabled={!values.key || (hasError && values.enableOptions)}
                    className="create-push-template__modal__footer__save-btn"
                    onClick={handleSubmit}
                  />
                </div>
              }
            />
          </div>
        )}
      </Formik>
    </div>
  );
}
