import localforage from 'localforage';

export async function setAuthToken(token: string) {
  await localforage.setItem('token', token);
  return 'success';
}

export async function setProjectTokenToStorage(projectToken: string, tenantId: string) {
  await localforage.setItem(`projectToken+${tenantId}`, projectToken);
  return 'success';
}

export async function deleteProjectTokenFromStorage() {
  const tenantId = window.location.href.split('/')[4];
  await localforage.removeItem(`projectToken+${tenantId}`);
  return 'success';
}

export async function deleteAuthToken() {
  await localforage.removeItem(`token`);
  return 'success';
}

export async function getProjectToken() {
  const tenantId = window.location.href.split('/')[4];
  const projectToken = await localforage.getItem(`projectToken+${tenantId}`);
  if (projectToken !== null) {
    return projectToken;
  } else {
    return false;
  }
}

export async function getAuthToken() {
  const authToken = await localforage.getItem('token');
  return authToken;
}

export async function getAuthOrProjectToken() {
  const tenantId = window.location.href.split('/')[4];
  const projectToken = await localforage.getItem(`projectToken+${tenantId}`);
  if (projectToken) {
    return projectToken;
  } else {
    const authToken = await localforage.getItem('token');
    return authToken;
  }
}

export default {
  setAuthToken,
  setProjectTokenToStorage,
  deleteProjectTokenFromStorage,
  getProjectToken,
  getAuthOrProjectToken,
  getAuthToken,
};
