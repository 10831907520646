export interface ApnsSettings {
  fileID: string;
  password: string;
  isProduction: boolean;
  isEnabled: boolean;
  name: string;
}

export interface ApnsSetting {
  fileID: string;
  name: string;
  isUploaded: boolean;
}

export enum ApnsSettingsActionTypes {
  SET_APNS_SETTINGS = 'SET_APNS_SETTINGS',
  RESET_APNS_SETTINGS = 'RESET_APNS_SETTINGS',
  SET_APNS_SETTING = 'SET_APNS_SETTING',
  RESET_APNS_SETTING = 'RESET_APNS_SETTING',
}

interface ApnsSettingsActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type ApnsActions =
  | ApnsSettingsActionCreatorTypes<typeof ApnsSettingsActionTypes.SET_APNS_SETTINGS, ApnsSettings[]>
  | ApnsSettingsActionCreatorTypes<typeof ApnsSettingsActionTypes.RESET_APNS_SETTINGS, ApnsSettings[]>
  | ApnsSettingsActionCreatorTypes<typeof ApnsSettingsActionTypes.SET_APNS_SETTING, ApnsSetting>
  | ApnsSettingsActionCreatorTypes<typeof ApnsSettingsActionTypes.RESET_APNS_SETTING, ApnsSetting>;
