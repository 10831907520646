import UploadBaseService from './uploadbase.service';
import { CustomEmail } from 'model/customEmail';
import BaseService from './base.service';

class CustomEmailUploadService extends UploadBaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'kernel/file-manager/';
    this.SECONDARY_RESOURCE = 'files/upload?';
  }

  async uploadHeaderLogo(data: FormData) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }
}

class CustomEmailService extends BaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'sso/auth/';
    this.SECONDARY_RESOURCE = 'customEmail';
  }

  async getCustomEmailSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_RESOURCE);
  }

  async createOrUpdateCustomEmailSettings(data: CustomEmail) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }
}

export { CustomEmailUploadService, CustomEmailService };
