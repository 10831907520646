import React from 'react';
import './PageHeader.scss';
interface Props {
  title: string;
  isLarge?: boolean;
  className?: string;
}

export default function PageHeader(props: Props) {
  const { title, isLarge, className } = props;
  const pageHeaderClasses = 'page-header' + (className ? ` ${className}` : '');

  return (
    <div className={pageHeaderClasses}>
      <div className={isLarge ? 'large' : 'small'}>{title}</div>
    </div>
  );
}
