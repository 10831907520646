import BaseService from './base.service';
import { FcmSettingsServerObject } from 'model/fcmSettings';
import UploadBaseService from './uploadbase.service';
import { ApnsSetting } from 'model/apnsSettings';
import { CreateNotificationType, NotificationType } from 'model/notificationTypes';

class PushUploadService extends UploadBaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'kernel/file-manager/';
    this.SECONDARY_RESOURCE = 'files/upload?';
  }

  async uploadApnsCertificate(data: FormData) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }
}

class PushService extends BaseService {
  CURRENT_PATH: string;
  GCM_RESOURCE: string;
  APNS_RESOURCE: string;
  PUSH_PAYLOAD_TYPES: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'push/';
    this.GCM_RESOURCE = 'gcm/tenantsettings';
    this.APNS_RESOURCE = 'apns/apnssettings';
    this.PUSH_PAYLOAD_TYPES = 'push/payloadtypes';
  }

  async createFcmSettings(params: FcmSettingsServerObject) {
    return this.request('POST', this.CURRENT_PATH + this.GCM_RESOURCE, params);
  }

  async getFcmSettings() {
    return this.request('GET', this.CURRENT_PATH + this.GCM_RESOURCE);
  }

  async deleteFcmSetings(params: FcmSettingsServerObject) {
    return this.request('POST', this.CURRENT_PATH + this.GCM_RESOURCE, params);
  }

  async getApnsSettings() {
    return this.request('GET', this.CURRENT_PATH + this.APNS_RESOURCE);
  }

  async createOrUpdateApnsSettings(params: ApnsSetting) {
    return this.request('POST', this.CURRENT_PATH + this.APNS_RESOURCE, params);
  }

  async deleteApnsSettings(fileID: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.APNS_RESOURCE + '/' + fileID);
  }

  async getPushPayloadTypes() {
    return this.request('GET', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES);
  }

  async createPushPayloadTypes(data: CreateNotificationType) {
    return this.request('POST', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES, data);
  }

  async updatePushPayLoadTypes(data: NotificationType) {
    return this.request('PUT', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES + '/' + data.id, data);
  }

  async deletePushPayLoadTypes(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES + '/' + id);
  }
}

export { PushUploadService, PushService };
