import React from 'react';
import ContentLoader from 'react-content-loader';

const ViewBoxLoader = () => {
  return (
    <ContentLoader
      height={30}
      width={220}
      speed={1}
      viewBox="0 0 220 44"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
      <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
      <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
      <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
    </ContentLoader>
  );
};

export default ViewBoxLoader;
