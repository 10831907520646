import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Jumbotron, Container, Row, Col, Alert, Button } from 'reactstrap';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { useActions } from 'actions';
import * as ProjectActions from 'actions/project';
import * as LanguagesActions from 'actions/languages';
import * as CustomEmailActions from 'actions/customEmails';
import * as FcmSettings from 'actions/fcmSettings';
import * as UserSubscriptionSettings from 'actions/userSubscriptions';
import * as SilentPushSettings from 'actions/silentPushSettings';
import * as FacebookSetings from 'actions/facebookSettings';
import * as AuthActions from 'actions/auth';
import * as contentTemplatesActions from 'actions/contentTemplates';
import * as contextPluginsActions from 'actions/contextPlugins';

import { NavBar } from 'components/NavBar';
import './ProjectsPage.scss';
import { Project } from 'model/project';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import PlaceHolder from 'components/Shared/ContentLoader/ContentLoader';
import { deleteProjectTokenFromStorage } from 'helpers/auth';

import { CreateProjectModal } from 'components/CreateProjectModal/CreateProjectModal';
export function ProjectsPage() {
  /*lazy imports*/
  const ProjectCard = lazy(() => import('components/ProjectCard/ProjectCard'));
  const AddNewCard = lazy(() => import('components/Shared/AddNewCard/AddNewCard'));
  /*end lazy imports*/

  /* Redux imports */
  const projectActions = useActions(ProjectActions);
  const languagesActions = useActions(LanguagesActions);
  const customEmailActions = useActions(CustomEmailActions);
  const fcmSettingsActions = useActions(FcmSettings);
  const userSubscriptionsActions = useActions(UserSubscriptionSettings);
  const silentPushSettings = useActions(SilentPushSettings);
  const facebookSettings = useActions(FacebookSetings);
  const authActions = useActions(AuthActions);
  const contentTemplateActions = useActions(contentTemplatesActions);
  const contextPluginActions = useActions(contextPluginsActions);


  const projectsState = useSelector((state: RootState) => state.projects);
  useEffect(() => {
    authActions.resetProjectToken();
    projectActions.resetProjectsState();
    languagesActions.resetLanguages();
    customEmailActions.resetCustomEmails();
    facebookSettings.resetFacebookSettings();
    fcmSettingsActions.resetFcmSettings();
    silentPushSettings.resetSilentPushSettings();
    userSubscriptionsActions.resetUserSubscriptions();
    contentTemplateActions.resetContentTemplateState();
    contentTemplateActions.resetContentTemplateSettingState();
    contextPluginActions.resetContextPluginState();
    contextPluginActions.resetContextPluginsState();
    projectActions.fetchAllProjects();
    deleteProjectTokenFromStorage();
    // eslint-disable-next-line
  }, []);

  // Remove any reference to redux state
  const localProjectsState = flatten(cloneDeep(projectsState));
  /* End Redux imports */

  // New Project Modal
  const [modal, setModal] = useState(false);

  const triggerModal = () => {
    setModal(!modal);
  };
  interface CreateProject {
    name: string;
    subDomain: string;
  }

  return (
    <div>
      <CreateProjectModal openModal={modal} toggleModal={triggerModal} />
      <NavBar />
      <Jumbotron className="projects-page jumbotron-fluid">
        <Container className="col-sm">
          <Row>
            <Col>
              <h2>Projects</h2>
              Need help? <a href="/#">Learn</a> more about why you need to create a project (approx. 10 mins)
            </Col>
            <Col className="col-sm col-lg-4">
              <Alert color="warning" className="projects-page__email-alert">
                Open your inbox and click on a verification link to verify your email. If you don&apos;t verify, you
                won&apos;t able to create a project.
                <hr />
                <div className="projects-page__button">
                  <span className="projects-page__text-italic  btn-sm disabled">Didn&apos;t receive an email?</span>
                  <Button color="primary" className="btn btn-primary">
                    Resend Email
                  </Button>
                </div>
              </Alert>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container xs="3">
        <Row xs="3">
          <div className="projects-page__project-card">
            <Suspense fallback={<PlaceHolder />}>
              <AddNewCard callback={triggerModal} title={'Project'} />
            </Suspense>
          </div>
          {localProjectsState.map((project: Project) => (
            <div className="projects-page__project-card" key={project.id}>
              <Suspense fallback={<PlaceHolder />}>
                <ProjectCard
                  name={project.name}
                  isActive={project.isActive}
                  projectId={project.id}
                  subDomain={project.subDomain}
                />
              </Suspense>
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
}
