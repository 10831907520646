import {
  GoogleSettingsActions,
  GoogleSettingsActionTypes,
  GoogleSettings,
  ObjectForApi,
} from '../model/googleSettings';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setGoogleSettings(googleSettings: GoogleSettings): GoogleSettingsActions {
  return {
    type: GoogleSettingsActionTypes.SET_GOOGLESETTINGS_STATE,
    payload: googleSettings,
  };
}

export function resetGoogleSettings(): GoogleSettingsActions {
  return {
    type: GoogleSettingsActionTypes.RESET_GOOGLESETTINGS_STATE,
  };
}

export function fetchGoogleSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getGoogleSettings();
      dispatch(resetGoogleSettings());
      dispatch(setGoogleSettings(response.data.settings));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetGoogleSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateGoogleSettings(googleSettings: GoogleSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: JSON.stringify(googleSettings),
        configName: {
          configName: 'gplus',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchGoogleSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
