import { History } from 'history';
import { combineReducers } from 'redux';
import { Auth, ProjectToken } from '../model/auth';
import { auth, token } from './auth';
import { ContentTemplate } from '../model/contentTemplates';
import { contentTemplates, contentTemplateSettings } from './contentTemplates';
import { ContextPlugin, ContextPlugins } from '../model/contextPlugins';
import { contextPlugins, contextPlugin } from './contextPlugins';
import { Project } from '../model/project';
import { project } from './project';
import { projects } from './projects';
import { notifications } from './notifications';
import { Notification } from '../model/notifications';
import { Subscription, Ratelimits } from 'model/subscriptions';
import { subscriptions } from './subscriptions';
import { ratelimits } from './ratelimits';
import { Languages } from 'model/languages';
import { languages } from './languages';
import { customEmails, customEmailHeaderLogo } from './customEmails';
import { CustomEmail, CustomEmailHeaderLogo } from 'model/customEmail';
import {
  fcmSettings,
  apnsSettings,
  apnsSetting,
  silentPushSettings,
  notificationTypes,
  notificationType,
} from './push';
import { SilentPushSettings } from '../model/silentPushSettings';
import { FcmSettings } from 'model/fcmSettings';
import { ApnsSettings, ApnsSetting } from 'model/apnsSettings';
import { userSubscriptions } from './userSubscriptions';
import { UserSubscription } from 'model/userSubscriptions';
import { NotificationTypes, NotificationType } from 'model/notificationTypes';
import {
  apiKeySettings,
  facebookSettings,
  googleSettings,
  oktaSettings,
  openIDSettings,
  samlSettings,
  tenantAccessSettings,
  signedLoginSettings,
} from './accountAccess';
import { FacebookSettings } from 'model/facebookSettings';
import { OpenIDSettings } from 'model/openIDSettings';
import { OktaSettings } from 'model/oktaSettings';
import { SamlSettings } from 'model/samlSettings';
import { GoogleSettings } from 'model/googleSettings';
import { ApiKeySettings } from 'model/apikeySettings';
import { TenantAccessSettings } from 'model/tenantAccessSettings';
import { SignedLoginSettings } from 'model/signedLoginSettings';
import { ContentTemplateSettings } from 'model/contentTemplateSettings';
import { DatasourceState } from 'model/datasource';
import { datasources } from './datasources';

export interface RootState {
  auth: Auth[];
  apiKeySettings: ApiKeySettings[];
  apnsSettings: ApnsSettings[];
  apnsSetting: ApnsSetting[];
  contentTemplates: ContentTemplate[];
  contextPlugin: ContextPlugin[];
  contextPlugins: ContextPlugins[];
  contentTemplateSettings: ContentTemplateSettings[];
  customEmails: CustomEmail[];
  customEmailHeaderLogo: CustomEmailHeaderLogo[];
  datasources: DatasourceState;
  facebookSettings: FacebookSettings[];
  fcmSettings: FcmSettings[];
  googleSettings: GoogleSettings[];
  languages: Languages[];
  oktaSettings: OktaSettings[];
  openIDSettings: OpenIDSettings[];
  token: ProjectToken[];
  notifications: Notification[];
  notificationType: NotificationType[];
  notificationTypes: NotificationTypes[];
  projects: Project[];
  project: Project[];
  ratelimits: Ratelimits[];
  samlSettings: SamlSettings[];
  silentPushSettings: SilentPushSettings[];
  signedLoginSettings: SignedLoginSettings[];
  subscriptions: Subscription[];
  tenantAccessSettings: TenantAccessSettings[];
  userSubscriptions: UserSubscription[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (history: History) =>
  combineReducers({
    auth,
    apiKeySettings,
    apnsSetting,
    apnsSettings,
    contentTemplates,
    contentTemplateSettings,
    contextPlugin,
    contextPlugins,
    customEmails,
    customEmailHeaderLogo,
    datasources,
    facebookSettings,
    fcmSettings,
    googleSettings,
    languages,
    oktaSettings,
    openIDSettings,
    notifications,
    notificationType,
    notificationTypes,
    projects,
    project,
    ratelimits,
    samlSettings,
    silentPushSettings,
    signedLoginSettings,
    subscriptions,
    token,
    tenantAccessSettings,
    userSubscriptions,
  });
