import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ContextPluginsTable from 'components/ContextPluginComponents/ContextPlugins';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { RootState } from 'reducers';
import './ContextPluginsPage.scss';
import * as ContextPluginsActions from 'actions/contextPlugins';
import { useActions } from 'actions';
import ManageContextPlugins from 'components/ContextPluginComponents/ManageContextPlugins';
import { flatten, cloneDeep } from 'lodash';

export default function ContextPluginsPage() {
  const contextPluginsActions = useActions(ContextPluginsActions);
  const contextPluginsFromState = useSelector((state: RootState) => state.contextPlugins);
  const contextPluginFromState = useSelector((state: RootState) => state.contextPlugin);
  const projectFromState = useSelector((state: RootState) => state.project);
  const projectsFromState = useSelector((state: RootState) => state.projects);
  const projectTokenFromState = useSelector((state: RootState) => state.token);

  async function fetchProjectDependencies() {
    await contextPluginsActions.fetchContextPlugins();
  }
  let isView = false;
  let isPreview = false;
  let isViewAttribute = undefined;
  let isCreateAttribute = undefined;
  if (contextPluginFromState.length !== 0) {
    [{ isView, isPreview, isViewAttribute, isCreateAttribute }] = contextPluginFromState;
  }
  if (!isViewAttribute) {
    isViewAttribute = '';
  }
  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);
  const localContextPluginsFromState = flatten(cloneDeep(contextPluginsFromState));
  let categories = new Array(localContextPluginsFromState.length);
  if (contextPluginsFromState.length !== 0) {
    for (let i = 0; i < categories.length; i++) {
      if (localContextPluginsFromState[i].category) {
        categories[i] = localContextPluginsFromState[i].category;
      }
    }
  }
  return (
    <div
      className={
        (!isView && !isCreateAttribute && !isViewAttribute) || (isPreview && !isCreateAttribute && !isViewAttribute)
          ? 'context-plugins-page'
          : ''
      }
    >
      {!isView && !isCreateAttribute ? (
        <div>
          <PageHeader title="Context Plugins" isLarge={true} />
          <ContextPluginsTable
            projectFromState={projectFromState}
            projectsFromState={projectsFromState}
            projectTokenFromState={projectTokenFromState}
            contextPluginsFromState={contextPluginsFromState}
          />
        </div>
      ) : (
        <ManageContextPlugins
          projectFromState={projectFromState}
          contextPluginFromState={contextPluginFromState}
          projectTokenFromState={projectTokenFromState}
          categories={categories}
        />
      )}
    </div>
  );
}
