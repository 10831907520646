import React from 'react';
import { Datasource } from 'model/datasource';
import { FlightButton, FlightOverflowMenu, FlightTable } from '@flybits/webapp-design-system-react';
import './DatasourcesTable.scss';

interface Props {
  currentId: string;
  datasources: Datasource[];
  showAvailableDatasources: boolean;
  handleCurrentDatasourceIdChange(datasourceId: string): void;
  addDatasourceToProject(datasourceId: string): void;
  removeDatasourceFromProject(tenantConnectionId: string): void;
}

export default function DatasourcesTable(props: Props) {
  const {
    currentId,
    datasources,
    showAvailableDatasources,
    handleCurrentDatasourceIdChange,
    addDatasourceToProject,
    removeDatasourceFromProject,
  } = props;

  const MAIN_CLASS = 'datasources-table';

  const tableHeaders = [
    {
      name: '',
      key: 'image',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'content',
      isVisible: true,
      hideTooltip: true,
    },
    ...(showAvailableDatasources ? [{ name: '', key: 'addToProject', isVisible: true, hideTooltip: true }] : []),
    {
      name: '',
      key: 'menu',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const tableBody = datasources.map(datasource => {
    const isCreator = currentId === datasource.creatorId;
    const isAddedToProject = datasource.hasTenantConnection;
    const label = isAddedToProject ? 'Added to project' : 'Add to project';

    const menuOptionGroups = [
      [
        {
          key: 1,
          name: 'View details',
          onClick: () => {
            handleCurrentDatasourceIdChange(datasource.id);
          },
        },
        {
          key: 2,
          name: 'Contact Support',
          onClick: () => {
            window.location.href = 'mailto:support@flybits.com';
          },
        },
        ...(isCreator
          ? [
              {
                key: 3,
                name: 'Manage',
                onClick: () => {
                  console.log('Todo: Add link for Manage');
                },
              },
            ]
          : []),
        ...(isAddedToProject
          ? [
              {
                key: 4,
                name: 'Remove',
                onClick: () => {
                  if (datasource.tenantConnectionID) {
                    removeDatasourceFromProject(datasource.tenantConnectionID);
                  }
                },
              },
            ]
          : []),
      ],
    ];

    return {
      key: datasource.id,
      image: <img src={datasource.imageUrl} alt={datasource.providerName} className={`${MAIN_CLASS}__table__image`} />,
      content: (
        <div className={`${MAIN_CLASS}__table__content`}>
          <div className={`${MAIN_CLASS}__table__content__name`}>{datasource.name}</div>
          {datasource.providerName && (
            <div className={`${MAIN_CLASS}__table__content__text`}>By {datasource.providerName}</div>
          )}
          {datasource.description && (
            <div className={`${MAIN_CLASS}__table__content__text`}>{datasource.description}</div>
          )}
        </div>
      ),
      addToProject: (
        <FlightButton
          label={label}
          disabled={isAddedToProject}
          onClick={() => {
            addDatasourceToProject(datasource.id);
          }}
        />
      ),
      menu: <FlightOverflowMenu className={`${MAIN_CLASS}__table__overflow-menu`} optionGroups={menuOptionGroups} />,
    };
  });

  return (
    <div className={MAIN_CLASS}>
      {tableBody.length > 0 ? (
        <FlightTable
          className={`${MAIN_CLASS}__table`}
          tableHeaders={tableHeaders}
          tableData={tableBody}
          isShowHeader={false}
          hasPaginationBeforeTable={false}
        />
      ) : (
        <div className={`${MAIN_CLASS}__no-results`}>No results found.</div>
      )}
    </div>
  );
}
