import React, { useState, useEffect } from 'react';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import * as LanguageActions from 'actions/languages';
import { useActions } from 'actions';
import { Languages } from 'model/languages';
import { flatten, cloneDeep, differenceWith, isEqual, pickBy } from 'lodash';
import './Languages.scss';
import { FlightButton, FlightModal, FlightSelectSearchable } from '@flybits/webapp-design-system-react';
import { DEFAULT_LANGUAGE_WARNING } from 'constants/banners/banners';
import warningIcon from 'assets/images/warning-icon.svg';
import clearIcon from 'assets/images/clear-icon.svg';
import langmap from 'langmap';
import { Col, Row, Container } from 'reactstrap';

interface Props {
  languagesFromState?: Languages[];
}

interface SelectOptionProps {
  key: string;
  name: string;
}

interface Language {
  name: string;
  id: string;
}

export default function LanguagesCard(props: Props) {
  const { languagesFromState = [] } = props;
  const [modal, setModal] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState('');
  const languageActions = useActions(LanguageActions);
  const [selected, setSelected] = useState<SelectOptionProps>({ key: '', name: '' });
  const languageForDropdown: SelectOptionProps[] = [];
  const localLanguages = flatten(cloneDeep(languagesFromState));
  const sortedLanguages = localLanguages.sort(function(languageOne, languageTwo) {
    return languageOne.isDefault === languageTwo.isDefault ? 0 : languageOne.isDefault ? -1 : 1;
  });
  const allLanguages = langmap;
  // we ignore country specific languages
  const filteredLanguagues = pickBy(allLanguages, (value, key) => key.indexOf('-') < 1);
  const mapForSearchableSelect = Object.entries(filteredLanguagues);
  mapForSearchableSelect.forEach(language => {
    languageForDropdown.push({
      key: language[0],
      name: language[1]?.englishName,
    });
  });

  const handleOptionClick = (option: SelectOptionProps) => {
    setErrorDialogState('');
    setSelected(option);
  };

  const [options, setOptions] = useState<SelectOptionProps[]>([{ key: '', name: '' }]);
  const handleSearch = (value: string) => {
    const searchedTerm = languageForDropdown.filter(opt =>
      opt.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    );
    setOptions(searchedTerm);
  };

  function toggle() {
    setErrorDialogState('');
    setSelected({ key: '', name: '' });
    setModal(!modal);
  }

  function handleSubmit() {
    //build object for api
    const langExists = sortedLanguages.find(languageInArray => languageInArray.id === selected.key);
    if (langExists) {
      setErrorDialogState('Language already exists');
      return;
    } else {
      const configLanguage: Languages = {
        id: selected.key,
        isDefault: false,
        name: selected.name,
      };
      sortedLanguages.push(configLanguage);
      languageActions.addOrUpdateLanguage(sortedLanguages);
    }
    toggle();
  }

  function handleSetDefault(language: Language) {
    const replacePreviousDefaultValue = sortedLanguages.find(
      languageInArray => languageInArray.id !== language.id && languageInArray.isDefault === true,
    ) || {
      id: '',
      isDefault: false,
      name: '',
    };

    if (replacePreviousDefaultValue.isDefault) {
      replacePreviousDefaultValue.isDefault = false;
    } else {
      return;
    }
    const mutateDefaultValueToTrue = sortedLanguages.find(languageInArray => languageInArray.id === language.id) || {
      id: '',
      isDefault: false,
      name: '',
    };

    if (mutateDefaultValueToTrue.isDefault) {
      return;
    } else {
      mutateDefaultValueToTrue.isDefault = true;
    }
    languageActions.addOrUpdateLanguage(sortedLanguages);
  }

  function handleDelete(language: Language) {
    const deleteLanguage = sortedLanguages.filter(
      languageInArray => languageInArray.id === language.id && languageInArray.isDefault !== true,
    );
    if (deleteLanguage.length !== 0) {
      const languageToBeDeleted = differenceWith(sortedLanguages, deleteLanguage, isEqual);
      languageActions.addOrUpdateLanguage(languageToBeDeleted);
    }
  }
  const isDisabled = (selected: SelectOptionProps) => {
    if (selected === undefined) {
      return true;
    } else {
      if (selected.key === '') {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    setOptions(languageForDropdown);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="languages">
      <FlightModal
        isVisible={modal}
        toggleModalShown={toggle}
        size="small"
        scrollable={true}
        header={<span>Language Settings</span>}
        content={
          <div className="languages__modal">
            <div className="languages__modal__warning-banner">
              <img className="languages__modal__warning-banner__icon" src={warningIcon} alt="warningicon" />
              <span className="languages__modal__warning-banner__text">{DEFAULT_LANGUAGE_WARNING}</span>
            </div>
            <div className="languages__modal__description">
              Languages listed here will be reflected as localization options for content and push notifications on
              experience studio.
            </div>
            <FlightSelectSearchable
              label="Select a langauge to add"
              options={options}
              selected={selected}
              handleOptionClick={handleOptionClick}
              handleSearch={handleSearch}
            />
            <div className="languages__modal__value">
              {localLanguages.map((language: Languages) => (
                <div key={language.id}>
                  <div
                    className={`${
                      language.isDefault
                        ? `languages__modal__value__values`
                        : `languages__modal__value__values--not-default`
                    }`}
                  >
                    {language.name} {language.isDefault ? '(Default)' : ''}
                    <img
                      className="languages__modal__value__actions--delete"
                      src={clearIcon}
                      alt="clear"
                      onClick={() => handleDelete(language)}
                    />
                    <div
                      className="languages__modal__value__actions--set-default"
                      onClick={() => handleSetDefault(language)}
                    >
                      Set as default
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        footer={
          <div className="modal-footer">
            <div className="languages__error">{errorDialogState}</div>
            <FlightButton
              onClick={() => {
                toggle();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton type="submit" label="Apply Changes" disabled={isDisabled(selected)} onClick={handleSubmit} />
          </div>
        }
      />
      <ReusableCard>
        <Container>
          <Col xs={8}>
            <Row>
              <div className="languages__card__header">Channel Languages</div>
            </Row>
            <Row>
              <div className="languages__card__subheader">
                Languages listed here will be reflected as localization options for content and push notifications in
                experience studio.
              </div>
            </Row>
            <Row xs={12}>
              <div className="pt-3 languages__card__container">
                {sortedLanguages.map(language => (
                  <span key={Math.random()}>
                    <span className="languages__card__container__tabs">
                      {language.name}
                      <span className="languages__card__container__tabs--not-bold">
                        {language.isDefault ? '(Default)' : ''}
                      </span>
                    </span>
                    <span className="languages__card__container__tabs--spacer" />
                  </span>
                ))}
              </div>
            </Row>
          </Col>
          <Col xs={12}>
            <FlightButton className="languages__btn" label="Manage" onClick={() => toggle()} theme="secondary" />
          </Col>
        </Container>
      </ReusableCard>
    </div>
  );
}
