/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getAuthOrProjectToken, getAuthToken } from '../helpers/auth';

const AXIOS_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// For Requests
AXIOS_SERVICE.interceptors.request.use(
  async function(config) {
    if (config.url === 'sso/auth/saml/config' && config.method === 'post') {
      const token = await getAuthToken();
      if (token) {
        config.headers['X-Authorization'] = token;
      }
      return config;
    } else {
      const token = await getAuthOrProjectToken();
      if (config.data?.projectTokenForApiCall) {
        config.headers['X-Authorization'] = config.data.projectTokenForApiCall;
        delete config.data.projectTokenForApiCall;
      } else if (token) {
        config.headers['X-Authorization'] = token;
      }
      return config;
    }
  },
  function(error) {
    return error;
  },
);

// For Responses
AXIOS_SERVICE.interceptors.response.use(function(response) {
  // Add debug logging
  return response;
});

class BaseService {
  axios: any;
  constructor() {
    this.axios = AXIOS_SERVICE;
  }
  get(endpoint: string, data: any) {
    return AXIOS_SERVICE.get(endpoint, data);
  }

  post(endpoint: string, data: any) {
    return AXIOS_SERVICE.post(endpoint, data);
  }

  put(endpoint: string, data: any) {
    return AXIOS_SERVICE.put(endpoint, data);
  }

  delete(endpoint: string, data: any) {
    return AXIOS_SERVICE.delete(endpoint, data);
  }

  request(type: any, url: string, data?: any) {
    let promise = null;
    switch (type) {
      case 'GET':
        promise = this.get(url, { params: data });
        break;
      case 'POST':
        promise = this.post(url, data);
        break;
      case 'PUT':
        promise = this.put(url, data);
        break;
      case 'DELETE':
        promise = this.delete(url, { params: data });
        break;
      default:
        promise = this.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default BaseService;
