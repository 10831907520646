export interface AllowedTenants {
  type: number;
  value: string;
}

export interface TenantAccessSettings {
  allowed: AllowedTenants[];
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum TenantAccessSettingsActionTypes {
  SET_TENANTACCESSSETTINGS_STATE = 'SET_TENANTACCESSSETTINGS_STATE',
  RESET_TENANTACCESSSETTINGS_STATE = 'RESET_TENANTACCESSSETTINGS_STATE',
}

interface TenantAccessSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type TenantAccessSettingsActions =
  | TenantAccessSettingsActionCreatorType<
      typeof TenantAccessSettingsActionTypes.SET_TENANTACCESSSETTINGS_STATE,
      TenantAccessSettings
    >
  | TenantAccessSettingsActionCreatorType<
      typeof TenantAccessSettingsActionTypes.RESET_TENANTACCESSSETTINGS_STATE,
      TenantAccessSettings
    >;
