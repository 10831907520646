import React, { useEffect } from 'react';
import PageHeader from '../../components/Shared/PageHeader/PageHeader';
import SignInProviders from '../../components/AuthenticateComponents/SignInProviders';
import AccountDomainAccess from '../../components/AuthenticateComponents/AccountDomainAccess';
import * as FacebookSettings from 'actions/facebookSettings';
import * as GoogleSettings from 'actions/googleSettings';
import * as OpenIDSettings from 'actions/openIDSettings';
import * as OktaSettings from 'actions/oktaSettings';
import * as SamlSettings from 'actions/samlSettings';
import * as ApiKeySettings from 'actions/apiKeySettings';
import * as TenantAccessSettings from 'actions/tenantAccessSettings';
import * as SignedLoginSettings from 'actions/signedLoginSettings';
import { useActions } from 'actions';

export default function AuthenticationPage() {
  const facebookSettings = useActions(FacebookSettings);
  const googleSettings = useActions(GoogleSettings);
  const oktaSettings = useActions(OktaSettings);
  const openIDSettings = useActions(OpenIDSettings);
  const samlSettings = useActions(SamlSettings);
  const apiKeySettings = useActions(ApiKeySettings);
  const tenantAccessSettings = useActions(TenantAccessSettings);
  const signedLoginSettings = useActions(SignedLoginSettings);

  async function fetchProjectDependencies() {
    await apiKeySettings.fetchApiKeySettings();
    await facebookSettings.fetchFacebookSettings();
    await googleSettings.fetchGoogleSettings();
    await oktaSettings.fetchOktaSettings();
    await openIDSettings.fetchOpenIDSettings();
    await samlSettings.fetchSamlSettings();
    await tenantAccessSettings.fetchTenantAccessSettings();
    await signedLoginSettings.fetchSignedLoginSettings();
  }

  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <PageHeader title="Authentication" isLarge={true} />
      <div>
        <PageHeader title="Sign-in providers" isLarge={false} />
      </div>
      <div className="pt-3">
        <SignInProviders />
      </div>
      <div className="pt-5">
        <AccountDomainAccess />
      </div>
    </div>
  );
}
