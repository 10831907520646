import React, { useState } from 'react';
import { FlightTable } from '@flybits/webapp-design-system-react';
import './SignInProviders.scss';
import { ReactComponent as EditPen } from 'assets/images/editpenblue.svg';
import { ReactComponent as SignedLoginLogo } from 'assets/images/idplogos/signed-login.svg';
import { ReactComponent as FacebookLogo } from 'assets/images/idplogos/facebook.svg';
import { ReactComponent as GoogleLogo } from 'assets/images/idplogos/google.svg';
import { ReactComponent as ApiKeyLogo } from 'assets/images/idplogos/api.svg';
import { ReactComponent as SamlLogo } from 'assets/images/idplogos/saml.svg';
import { ReactComponent as OpenIdLogo } from 'assets/images/idplogos/openid.svg';
import { ReactComponent as OktaLogo } from 'assets/images/idplogos/okta.svg';

import ApiKeyModal from './Modals/ApiKeyModal';
import FaceBookModal from './Modals/FaceBookModal';
import GoogleModal from './Modals/GoogleModal';
import OpenIdConnectModal from './Modals/OpenIdConnectModal';
import OktaModal from './Modals/OktaModal';
import SAMLModal from './Modals/SAMLModal';
import SignedLoginModal from './Modals/SignedLoginModal';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AllowedTenants } from 'model/tenantAccessSettings';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';

export default function SignInProviders() {
  const [apiModal, setApiModal] = useState(false);
  const [faceBookModal, setFaceBookModal] = useState(false);
  const [googleModal, setGoogleModal] = useState(false);
  const [openIdModal, setOpenIdModal] = useState(false);
  const [oktaModal, setOktaModal] = useState(false);
  const [samlModal, setSamlModal] = useState(false);
  const [signedLogin, setSignedLoginModal] = useState(false);

  const facebookSettingsFromState = useSelector((state: RootState) => state.facebookSettings);
  const googleSettingsFromState = useSelector((state: RootState) => state.googleSettings);
  const oktaSettingsFromState = useSelector((state: RootState) => state.oktaSettings);
  const openIDSettingsFromState = useSelector((state: RootState) => state.openIDSettings);
  const samlSettingsFromState = useSelector((state: RootState) => state.samlSettings);
  const apiKeySettingsFromState = useSelector((state: RootState) => state.apiKeySettings);
  const tenantAccessSettingsFromState = useSelector((state: RootState) => state.tenantAccessSettings);
  const signedLoginSettingsFromState = useSelector((state: RootState) => state.signedLoginSettings);

  const triggerFaceBookModal = () => {
    setFaceBookModal(!faceBookModal);
  };
  const triggerApiModal = () => {
    setApiModal(!apiModal);
  };

  const triggerGoogleModal = () => {
    setGoogleModal(!googleModal);
  };

  const triggerOpenIdModal = () => {
    setOpenIdModal(!openIdModal);
  };

  const triggerOktaModal = () => {
    setOktaModal(!oktaModal);
  };

  const triggerSamlModal = () => {
    setSamlModal(!samlModal);
  };

  const triggerSignedLoginModal = () => {
    setSignedLoginModal(!signedLogin);
  };

  const tableHeaders = [
    {
      name: 'Provider',
      key: 'provider',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'actions',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  let allowed: AllowedTenants[] = [{ type: 0, value: '' }];
  if (tenantAccessSettingsFromState.length !== 0) {
    [{ allowed }] = tenantAccessSettingsFromState;
  }

  const getStatus = (key: string) => {
    if (allowed.length !== 0) {
      const isEnabled = allowed.find(item => (item.value === key ? true : false));
      if (isEnabled) {
        return <span className="status-text--enabled">Enabled</span>;
      } else {
        return 'Disabled';
      }
    } else {
      return 'Disabled';
    }
  };

  const tableBody = [
    {
      provider: (
        <span>
          <ApiKeyLogo className="logo" />
          <span>API Key</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('apikey')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerApiModal} />,
      key: 1,
    },
    {
      provider: (
        <span>
          <FacebookLogo className="logo" />
          <span>Facebook</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('facebook')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerFaceBookModal} />,
      key: 2,
    },
    {
      provider: (
        <span>
          <GoogleLogo className="logo" />
          <span>Google</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('gplus')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerGoogleModal} />,
      key: 3,
    },
    {
      provider: (
        <span>
          <OpenIdLogo className="logo" />
          <span>Open ID Connect</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('oidc')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerOpenIdModal} />,
      key: 4,
    },
    {
      provider: (
        <span>
          <OktaLogo className="logo" />
          <span>Okta</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('okta')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerOktaModal} />,
      key: 5,
    },
    {
      provider: (
        <span>
          <SamlLogo className="logo" />
          <span>SAML</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('samllogin')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerSamlModal} />,
      key: 6,
    },
    {
      provider: (
        <span>
          <SignedLoginLogo className="logo" />
          <span>Signed Login</span>
        </span>
      ),
      status: <span className="status-text">{getStatus('signedlogin')}</span>,
      actions: <EditPen className="edit-pen" onClick={triggerSignedLoginModal} />,
      key: 7,
    },
  ];

  return (
    <div>
      <ApiKeyModal
        openModal={apiModal}
        toggleModal={triggerApiModal}
        apiKeySettingsFromState={apiKeySettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <FaceBookModal
        openModal={faceBookModal}
        toggleModal={triggerFaceBookModal}
        facebookSettingsFromState={facebookSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <GoogleModal
        openModal={googleModal}
        toggleModal={triggerGoogleModal}
        googleSettingsFromState={googleSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <OpenIdConnectModal
        openModal={openIdModal}
        toggleModal={triggerOpenIdModal}
        openIDSettingsFromState={openIDSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <OktaModal
        openModal={oktaModal}
        toggleModal={triggerOktaModal}
        oktaSettingsFromState={oktaSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <SAMLModal
        openModal={samlModal}
        toggleModal={triggerSamlModal}
        samlSettingsFromState={samlSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <SignedLoginModal
        openModal={signedLogin}
        toggleModal={triggerSignedLoginModal}
        signedLoginSettingsFromState={signedLoginSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <ReusableCard noPadding={true}>
        <FlightTable
          className="signin-provider-table"
          tableHeaders={tableHeaders}
          tableData={tableBody}
          allowRowSelect
          hasPaginationBeforeTable={false}
        />
      </ReusableCard>
    </div>
  );
}
