import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RootState } from 'reducers';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import { useActions } from 'actions';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { layout } from 'constants/contentTemplatesLayout';
import './ContentTemplatesPage.scss';
import ContentTemplatesList from 'components/ContentTemplateComponents/ContentTemplates';

interface Layout {
  id: string;
  value: string;
}

export default function ContentTemplatesPage() {
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const contentTemplatesFromState = useSelector((state: RootState) => state.contentTemplates);
  const contentTemplatesSettingsFromState = useSelector((state: RootState) => state.contentTemplateSettings);
  const projectsFromState = useSelector((state: RootState) => state.projects);
  const projectTokenFromState = useSelector((state: RootState) => state.token);

  async function fetchProjectDependencies() {
    await contentTemplatesActions.fetchContentTemplateSettings();
    await contentTemplatesActions.fetchContentTemplates();
  }

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="content-templates-page">
      <PageHeader title="Content Templates" isLarge={true} />
      <div className="content-templates-page__tabstrip">
        <div>
          <Container className="content-templates-page____tabstrip__container">
            <Row className="content-templates-page__tabstrip__container__tabs ">
              <Col>
                <Nav tabs>
                  {layout.map((tab: Layout) => (
                    <NavItem key={tab.id}>
                      <NavLink className={activeTab === tab.id ? 'active' : ''} onClick={() => setActiveTab(tab.id)}>
                        <span className="content-templates-page__tabstrip__container__tabs__label">{tab.value}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="content-templates-page_container ">
          <TabContent className="content-templates-page__tabstrip__container__content" activeTab={activeTab}>
            <TabPane tabId="1">
              <ContentTemplatesList
                contentTemplatesFromState={contentTemplatesFromState}
                contentTemplateSettingsFromState={contentTemplatesSettingsFromState}
                projectsFromState={projectsFromState}
                projectTokenFromState={projectTokenFromState}
              />
            </TabPane>
            <TabPane tabId="2">
              <div>otherside</div>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </div>
  );
}
