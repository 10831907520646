import { DatasourceAction, DatasourceActionTypes, DatasourceState } from '../model/datasource';
import createReducer from './createReducer';

const initialState = { datasources: undefined };

export const datasources = createReducer<DatasourceState>(initialState, {
  [DatasourceActionTypes.SET_DATASOURCES_STATE](state: DatasourceState, action: DatasourceAction) {
    return { ...state, datasources: action.payload };
  },
});
