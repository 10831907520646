import { CustomEmail, CustomEmailActionTypes, CustomEmailActions, CustomEmailHeaderLogo } from '../model/customEmail';
import { CustomEmailUploadService, CustomEmailService } from '../services/customEmail.service';
import { arrayToObject } from 'helpers/arrayToObject';
import { showNotification } from './notifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';

export function setCustomEmails(customEmails: CustomEmail): CustomEmailActions {
  return {
    type: CustomEmailActionTypes.SET_CUSTOM_EMAIL_STATE,
    payload: customEmails,
  };
}

export function setCustomEmailHeaderLogo(customEmails: CustomEmailHeaderLogo): CustomEmailActions {
  return {
    type: CustomEmailActionTypes.SET_CUSTOM_EMAIL_HEADER_LOGO,
    payload: customEmails,
  };
}

export function resetCustomEmailHeaderLogo(): CustomEmailActions {
  return {
    type: CustomEmailActionTypes.RESET_CUSTOM_EMAIL_HEADER_LOGO,
  };
}

export function resetCustomEmails(): CustomEmailActions {
  return {
    type: CustomEmailActionTypes.RESET_CUSTOM_EMAIL_STATE,
  };
}

export function uploadEmailHeaderLogo(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: CustomEmailActions): void }) => {
    try {
      const req = new CustomEmailUploadService();
      const response = await req.uploadHeaderLogo(data);
      if (response.status === 200) {
        const obj = arrayToObject(response.data);
        dispatch(resetCustomEmailHeaderLogo());
        dispatch(
          setCustomEmailHeaderLogo({
            previewImgUrl: obj.item.url,
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function fetchCustomEmailSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new CustomEmailService();
      const response = await req.getCustomEmailSettings();
      dispatch(resetCustomEmails());
      dispatch(setCustomEmails(response.data));
    } catch (error) {
      const errorObjectFromResponse = error.response.data.error.exceptionType;
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(addDefaultSetting());
      }
    }
    return 'done';
  };
}

export function addOrUpdateCustomEmailSettings(data: CustomEmail) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new CustomEmailService();
      const response = await req.createOrUpdateCustomEmailSettings(data);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'updated',
            type: 'success',
          }),
        );
        dispatch(fetchCustomEmailSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to set email settings',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function addDefaultSetting() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const data: CustomEmail = {
        isActive: false,
      };
      const req = new CustomEmailService();
      const response = await req.createOrUpdateCustomEmailSettings(data);
      if (response.status === 200) {
        dispatch(fetchCustomEmailSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not set defaults',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}
