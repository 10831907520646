export interface Notification {
  id: number;
  showNotification: boolean;
  header: string;
  body: string;
  type: 'primary' | 'danger' | 'success' | string;
}

export enum NotificationActionTypes {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  HIDE_NOTIFICATION_BY_ID = 'HIDE_NOTIFICATION_BY_ID',
}

interface NotificationActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type NotificationAction =
  | NotificationActionCreatorType<typeof NotificationActionTypes.SHOW_NOTIFICATION, Notification>
  | NotificationActionCreatorType<typeof NotificationActionTypes.HIDE_NOTIFICATION_BY_ID, Notification>
  | NotificationActionCreatorType<typeof NotificationActionTypes.HIDE_NOTIFICATION, Notification>;
