export interface Schema {
  description?: string;
  isRequired?: boolean;
  type?: string;
  valueType?: string;
  valueOptions?: string[];
}

export interface SchemaType {
  [schema: string]: Schema;
}

export interface NotificationTypes {
  id: string;
  isReserved: boolean;
  isActive: boolean;
  name: string;
  description: string;
  schema: SchemaType;
}

export interface NotificationType {
  id: string;
  isReserved: boolean;
  isActive: boolean;
  name: string;
  description: string;
  schema: SchemaType;
  isView: boolean; //FE only property
  isCreate?: boolean; //FE only property
}

export interface SchemaTypeForCreate {
  isEnabled?: boolean;
  isRequired?: boolean;
  isTracked?: boolean;
  key?: string;
  description?: string;
  type?: string;
  valueType?: string;
  valueOptions?: Array<string>;
}

export interface CreateNotificationType {
  isDefault: boolean;
  isReserved: boolean;
  isActive: boolean;
  name: string;
  description: string;
  schema: SchemaTypeForCreate;
}

export enum primitiveTypes {
  'Float Field',
  'Text Field',
  'Integer Field',
}

export enum dropDownTypes {
  'Float Dropdown',
  'Text Dropdown',
  'Integer Dropdown',
}

export enum NotificationTypesActionTypes {
  SET_NOTIFICATION_TYPES_STATE = 'SET_NOTIFICATION_TYPES_STATE',
  RESET_NOTIFICATION_TYPES_STATE = 'RESET_NOTIFICATION_TYPES_STATE',
  SET_NOTIFICATION_TYPE_STATE = 'SET_NOTIFICATION_TYPE_STATE',
  RESET_NOTIFICATION_TYPE_STATE = 'RESET_NOTIFICATION_TYPE_STATE',
}

interface NotificationActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type NotificationTypesActions =
  | NotificationActionCreatorTypes<typeof NotificationTypesActionTypes.SET_NOTIFICATION_TYPES_STATE, NotificationTypes>
  | NotificationActionCreatorTypes<typeof NotificationTypesActionTypes.SET_NOTIFICATION_TYPE_STATE, NotificationType>
  | NotificationActionCreatorTypes<typeof NotificationTypesActionTypes.RESET_NOTIFICATION_TYPE_STATE, NotificationType>
  | NotificationActionCreatorTypes<
      typeof NotificationTypesActionTypes.RESET_NOTIFICATION_TYPES_STATE,
      NotificationTypes
    >;
