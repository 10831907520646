export interface SignedLoginSettings {
  content: string;
  isJustUploaded: boolean;
}

export enum SignedLoginSettingsActionTypes {
  SET_SIGNEDLOGINSETTINGS_STATE = 'SET_SIGNEDLOGINSETTINGS_STATE',
  FETCH_SIGNEDLOGINSETTINGS_FROM_API = 'FETCH_SIGNEDLOGINSETTINGS_FROM_API',
  RESET_SIGNEDLOGINSETTINGS_STATE = 'RESET_SIGNEDLOGINSETTINGS_STATE',
}

interface SignedLoginSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type SignedLoginSettingsActions =
  | SignedLoginSettingsActionCreatorType<
      typeof SignedLoginSettingsActionTypes.SET_SIGNEDLOGINSETTINGS_STATE,
      SignedLoginSettings
    >
  | SignedLoginSettingsActionCreatorType<
      typeof SignedLoginSettingsActionTypes.FETCH_SIGNEDLOGINSETTINGS_FROM_API,
      SignedLoginSettings
    >
  | SignedLoginSettingsActionCreatorType<
      typeof SignedLoginSettingsActionTypes.RESET_SIGNEDLOGINSETTINGS_STATE,
      SignedLoginSettings
    >;
