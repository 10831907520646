import {
  UserSubscriptionActions,
  UserSubscription,
  UserSubscriptionActionTypes,
  UserSubscriptionsForApi,
} from '../model/userSubscriptions';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setUserSubscriptions(userSubscriptions: UserSubscription): UserSubscriptionActions {
  return {
    type: UserSubscriptionActionTypes.SET_USER_SUBSCRIPTION_STATE,
    payload: userSubscriptions,
  };
}

export function resetUserSubscriptions(): UserSubscriptionActions {
  return {
    type: UserSubscriptionActionTypes.RESET_USER_SUBSCRIPTION_STATE,
  };
}

export function fetchAllUserSubscriptions() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getUserSettings();
      dispatch(resetUserSubscriptions());
      dispatch(setUserSubscriptions(response.data.settings.subscriptions.data));
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function addOrUpdateUserSubscription(userSubscriptions: UserSubscription[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const addTypesObject = {
        data: [],
      };

      const objectForApi: UserSubscriptionsForApi = {
        config:
          '{"subscriptions":{"data":' +
          JSON.stringify(userSubscriptions) +
          '}' +
          ', "types":' +
          JSON.stringify(addTypesObject) +
          '}',
        configName: {
          configName: 'push-settings',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchAllUserSubscriptions());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: '',
          type: 'danger',
        }),
      );
      dispatch(fetchAllUserSubscriptions());
    }
    return 'done';
  };
}
