import { ApnsSettingsActionTypes, ApnsActions, ApnsSettings, ApnsSetting } from '../model/apnsSettings';
import { PushService, PushUploadService } from '../services/push.service';
import { showNotification } from './notifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';

interface UploadResponseBody {
  id: string;
  friendlyName: string;
}
export function setApnsSettings(apnsSettings: ApnsSettings[]): ApnsActions {
  return {
    type: ApnsSettingsActionTypes.SET_APNS_SETTINGS,
    payload: apnsSettings,
  };
}

export function resetApnsSettings(): ApnsActions {
  return {
    type: ApnsSettingsActionTypes.RESET_APNS_SETTINGS,
  };
}

export function setApnsSetting(apnsSettings: ApnsSetting): ApnsActions {
  return {
    type: ApnsSettingsActionTypes.SET_APNS_SETTING,
    payload: apnsSettings,
  };
}

export function resetApnsSetting(): ApnsActions {
  return {
    type: ApnsSettingsActionTypes.RESET_APNS_SETTING,
  };
}

export function uploadApnsCertificate(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushUploadService();
      const response = await req.uploadApnsCertificate(data);
      if (response.status === 200) {
        dispatch(resetApnsSetting());
        response.data.map((x: UploadResponseBody) => {
          return dispatch(setApnsSetting({ fileID: x.id, name: x.friendlyName, isUploaded: true }));
        });
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could upload Apns certificate',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function fetchApnsSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getApnsSettings();
      dispatch(resetApnsSettings());
      dispatch(setApnsSettings(response.data));
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not fetch apns settings',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}

export function addOrUpdateApnsSettings(apnsSettings: ApnsSetting) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.createOrUpdateApnsSettings(apnsSettings);
      if (response.status === 201) {
        dispatch(fetchApnsSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}

export function deleteApnsSetting(fileID: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();

      const response = await req.deleteApnsSettings(fileID);
      if (response.status === 200) {
        dispatch(fetchApnsSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could delete Apns setting',
          type: 'danger',
        }),
      );
    }
    return 'done';
  };
}
