import React, { useEffect } from 'react';
import EmailUsageAlerts from '../../components/ApiUsageComponents/EmailUsageAlerts/EmailUsageAlerts';
import Subscriptions from '../../components/ApiUsageComponents/Subscriptions/Subscriptions';
import GraphUsage from '../../components/ApiUsageComponents/Graph/GraphUsage';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import * as SubscriptionActions from 'actions/subscriptions';

export default function ApiUsagePage() {
  const subscriptionsFromState = useSelector((state: RootState) => state.subscriptions);
  const ratelimitsFromState = useSelector((state: RootState) => state.ratelimits);
  const subscriptionActions = useActions(SubscriptionActions);

  const routeParamsId = window.location.href.split('/')[4];

  async function fetchProjectDependencies() {
    await subscriptionActions.fetchAllSubscriptions();
    await subscriptionActions.fetchRatelimitsForProject(routeParamsId);
  }
  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <div>
        <PageHeader title="API Usage" isLarge={true} />
        <Row>
          <Col xs="3" className="pt-5">
            <Subscriptions ratelimitsFromState={ratelimitsFromState} />
          </Col>
          <Col xs="9" className="pt-5">
            <div>
              <GraphUsage />
            </div>
            <div className="pt-4">
              <EmailUsageAlerts subscriptionsFromState={subscriptionsFromState} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
