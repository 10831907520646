interface Validation {
  jwksURI: string;
}

export interface OpenIDSettings {
  issuer: string;
  audience: string;
  validation: Validation;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum OpenIDSettingsActionTypes {
  SET_OPENIDSETTINGS_STATE = 'SET_OPENIDSETTINGS_STATE',
  RESET_OPENIDSETTINGS_STATE = 'RESET_OPENIDSETTINGS_STATE',
}

interface FacebookSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type OpenIDSettingsActions =
  | FacebookSettingsActionCreatorType<typeof OpenIDSettingsActionTypes.SET_OPENIDSETTINGS_STATE, OpenIDSettings>
  | FacebookSettingsActionCreatorType<typeof OpenIDSettingsActionTypes.RESET_OPENIDSETTINGS_STATE, OpenIDSettings>;
