import BaseService from './base.service';
import { Project } from 'model/project';

interface GetProjects {
  min: number;
  max: number;
  limit: number;
}

interface CreateProject {
  name: string;
  subDomain: string;
}

interface DeleteProject {
  projectId: string;
}

export default class ProjectService extends BaseService {
  CURRENT_PATH: string;
  CURRENT_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'kernel/';
    this.CURRENT_RESOURCE = 'projects';
  }

  async getAllProjects(params: GetProjects) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_RESOURCE, params);
  }

  async createProject(data: CreateProject) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }

  async updateProject(data: Project) {
    return this.request('PUT', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }

  async deleteProject(params: DeleteProject) {
    return this.request('DELETE', this.CURRENT_PATH + this.CURRENT_RESOURCE, params);
  }
}
