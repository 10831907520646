import { ContentTemplatesActions, ContentTemplate, ContentTemplatesActionTypes } from '../model/contentTemplates';
import {
  ContentTemplateSettings,
  ContentTemplateSettingsActionTypes,
  ContentTemplateSettingsActions,
} from '../model/contentTemplateSettings';

import createReducer from './createReducer';

export const contentTemplates = createReducer<ContentTemplate[]>([], {
  [ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE](state: ContentTemplate[], action: ContentTemplatesActions) {
    return [...state, action.payload];
  },
  [ContentTemplatesActionTypes.RESET_CONTENT_TEMPLATES_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const contentTemplateSettings = createReducer<ContentTemplate[]>([], {
  [ContentTemplateSettingsActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE](
    state: ContentTemplateSettings[],
    action: ContentTemplateSettingsActions,
  ) {
    return [...state, action.payload];
  },
  [ContentTemplateSettingsActionTypes.RESET_CONTENT_TEMPLATE_SETTINGS_STATE](state: []) {
    return state.filter(item => !item);
  },
});
