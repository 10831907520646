import React, { useState } from 'react';
import { FlightButton, FlightModal, FlightTextInput } from '@flybits/webapp-design-system-react';
import { Container, Row, Form, FormGroup, Col } from 'reactstrap';
import * as UserSubscriptionActions from 'actions/userSubscriptions';
import { useActions } from 'actions';
import { flatten, cloneDeep, differenceWith, isEqual } from 'lodash';
import './UserSubscriptions.scss';
import { UserSubscription } from 'model/userSubscriptions/userSubscriptions';
import { Field, Formik } from 'formik';
import { NAME_REQUIRED } from 'constants/errors/errors';
import * as Yup from 'yup';
import moment from 'moment';
import { getGuid } from 'helpers/randomUuidGenerator';

interface Props {
  userSubscriptionsFromState?: UserSubscription[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(NAME_REQUIRED),
});

export default function UserSubscriptionsCard(props: Props) {
  const { userSubscriptionsFromState = [] } = props;
  const userSubscriptionsActions = useActions(UserSubscriptionActions);
  const localUserSubscriptions = flatten(cloneDeep(userSubscriptionsFromState));
  const [modal, setModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  function toggle() {
    setModal(!modal);
  }
  const confirmToggle = (id?: string) => {
    setConfirm(!confirm);
    if (id) {
      setSubscriptionId(id);
    }
  };
  function handleCancelDelete() {
    setSubscriptionId('');
    confirmToggle();
  }
  function handleDelete() {
    const deleteUserSubscription = localUserSubscriptions.filter(subscription => subscription.id === subscriptionId);
    if (deleteUserSubscription.length !== 0) {
      const subscriptionToBeDeleted = differenceWith(localUserSubscriptions, deleteUserSubscription, isEqual);
      userSubscriptionsActions.addOrUpdateUserSubscription(subscriptionToBeDeleted);
    }
    setSubscriptionId('');
    confirmToggle();
  }

  return (
    <div className="user-subscriptions">
      <FlightModal
        isVisible={confirm}
        size="medium"
        toggleModalShown={confirmToggle}
        className="user-subscriptions__delete-modal"
        header={<span>Confirm</span>}
        content={
          <Row>
            <span className="user-subscriptions__delete-modal__text">
              Are you sure you want to delete this subscription?
            </span>
          </Row>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={handleCancelDelete} label="Cancel" />
            <FlightButton type="submit" label="Continue" onClick={handleDelete} />
          </div>
        }
      />
      <Formik
        enableReinitialize
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const userSubscriptions: UserSubscription = {
            createdAt: moment().unix(),
            id: getGuid(),
            isEnabled: true,
            isChecked: false,
            isRequired: false,
            name: values.name,
            value: values.name,
            updatedAt: moment().unix(),
          };
          localUserSubscriptions.push(userSubscriptions);
          userSubscriptionsActions.addOrUpdateUserSubscription(localUserSubscriptions);
          toggle();
          setSubmitting(true);
          resetForm();
        }}
      >
        {({ isValid, errors, values, handleSubmit, handleChange, handleBlur }) => (
          <FlightModal
            className="user-subscriptions__modal"
            isVisible={modal}
            toggleModalShown={toggle}
            scrollable={false}
            size="small"
            header={<span>New Push Subscription </span>}
            content={
              <Form>
                <FormGroup row>
                  <Col sm={5}>
                    <Field
                      type="text"
                      name="name"
                      as={FlightTextInput}
                      width="360px"
                      placeHolder="User Subscription"
                      value={values.name}
                      hasError={!isValid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={errors.name}
                    />
                  </Col>
                </FormGroup>
              </Form>
            }
            footer={
              <div className="user-subscriptions__modal__footer">
                <FlightButton
                  className="user-subscriptions__modal__submit"
                  type="primary"
                  onClick={handleSubmit}
                  label="Add Subscription"
                />
                <FlightButton
                  className="user-subscriptions__modal__cancel"
                  theme="secondary"
                  onClick={toggle}
                  label="Cancel"
                />
              </div>
            }
          />
        )}
      </Formik>
      <Container>
        <Col>
          <Row xs={12}>
            <div className="user-subscriptions__card__header">Subscriptions</div>
          </Row>
          <Row>
            <div className="user-subscriptions__card__subheader">
              Push subscriptions are used for users to specify what types of notifications they want to receive
            </div>
          </Row>
          <Row>
            <div className="user-subscriptions__card__bottom">
              <FlightButton
                className="user-subscriptions__card__bottom__add"
                label="+"
                onClick={() => toggle()}
                theme="secondary"
              />
              <div className="pt-3 user-subscriptions__card__bottom__container">
                {localUserSubscriptions.map((userSubscriptions: UserSubscription) => (
                  <span key={userSubscriptions.id}>
                    <span className="user-subscriptions__card__bottom__container__tabs">
                      {userSubscriptions.name}
                      <span
                        className="user-subscriptions__card__bottom__container__close"
                        onClick={() => confirmToggle(userSubscriptions.id)}
                      >
                        x
                      </span>
                    </span>
                    <span className="user-subscriptions__card__bottom__container__tabs--spacer" />
                  </span>
                ))}
              </div>
            </div>
          </Row>
        </Col>
      </Container>
    </div>
  );
}
