import React, { useState, useEffect } from 'react';
import { FlightModal, FlightButton, FlightRadioButton } from '@flybits/webapp-design-system-react';
import './AccountDomainSettingsModal.scss';
import { Row, Col } from 'reactstrap';
import { ReactComponent as WarningIcon } from 'assets/images/warning-icon.svg';
import ArrayFields from '../../Shared/ArrayFields/ArrayFields';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AllowedTenants, TenantAccessSettings } from 'model/tenantAccessSettings';
import * as TenantSettingsActions from 'actions/tenantAccessSettings';
import { useActions } from 'actions';
import { includes, differenceWith } from 'lodash';
import { ACCOUNT_DOMAINS_SETTINGS_WARNING } from 'constants/banners/banners';
import * as Yup from 'yup';
import { ARRAY_FIELD_REQUIRED, INVALID_DOMAIN } from 'constants/errors/errors';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
}

interface RadioButtonStates {
  state: 'any' | 'restricted' | string;
}
// eslint-disable-next-line
const domainRegex = /^(https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const validationSchema = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(ARRAY_FIELD_REQUIRED)
      .matches(domainRegex, INVALID_DOMAIN),
  ),
});

export default function AccountDomainSettingsModal(props: Props) {
  const { openModal, toggleModal } = props;
  const [checked, setChecked] = useState<RadioButtonStates>({ state: 'any' });
  const tenantAccessSettingsFromState = useSelector((state: RootState) => state.tenantAccessSettings);
  const [initialValues, setInitialValues] = useState(['', '']);
  const tenantSettingsActions = useActions(TenantSettingsActions);
  const values: string[] = [];

  let allowed: AllowedTenants[] = [{ type: 0, value: '' }];
  if (tenantAccessSettingsFromState.length !== 0) {
    [{ allowed }] = tenantAccessSettingsFromState;
  }

  let allowedTypeZeroTenants: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedTypeZeroTenants = allowed.filter(item => item.type === 0);
  }

  const [valuesFromArrayFields, setValuesFromArrayFields] = useState(['']);
  const getValuesFromArrayFields = (data: string) => {
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
  };

  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };

  const setFields = () => {
    if (allowedTypeZeroTenants.length !== 0 && allowedTypeZeroTenants[0].value !== '') {
      setChecked({ state: 'restricted' });
      allowedTypeZeroTenants.forEach(domain => {
        values.push(domain.value);
      });
      setInitialValues(values);
    } else {
      setInitialValues(['', '']);
      setChecked({ state: 'any' });
    }
  };

  useEffect(() => {
    setFields();
    // eslint-disable-next-line
  }, [tenantAccessSettingsFromState]);

  const handleApplyChanges = () => {
    switch (checked.state) {
      case 'any':
        setInitialValues(['', '']);
        const config = { allowed: allowed.filter(item => item.type !== 0) };
        tenantSettingsActions.createOrUpdateTenantAccessSettings(config);
        toggleModal();
        break;
      case 'restricted':
        if (includes(valuesFromArrayFields, '')) {
          toggleModal();
        } else {
          const allowedTenantsFromComponent: AllowedTenants[] = valuesFromArrayFields.flat();
          const convertToArrayString = allowedTenantsFromComponent.toString().split(',');
          const allowedTenants: AllowedTenants[] = [];
          let tenantAccessSettings: TenantAccessSettings = {
            allowed: allowedTenants,
          };
          convertToArrayString.forEach(value => {
            allowedTenants.push({
              type: 0,
              value: value,
            });
            tenantAccessSettings = {
              allowed: allowedTenants,
            };
          });
          const otherAllowedTypes = allowed.filter(item => item.type !== 0);
          otherAllowedTypes.forEach(value => {
            allowedTenants.push({
              type: 1,
              value: value.value,
            });
            tenantAccessSettings = {
              allowed: allowedTenants,
            };
          });
          tenantSettingsActions.createOrUpdateTenantAccessSettings(tenantAccessSettings);
          toggleModal();
          break;
        }
    }
  };
  const areFieldsEmpty = differenceWith(valuesFromArrayFields, initialValues).length === 0 ? true : false;
  return (
    <div className="account-settings-modal">
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        header={<span>Account Domain Settings</span>}
        content={
          <Col className="account-settings-modal__content">
            <Row className="account-settings-modal__content__warning-banner">
              <WarningIcon className="account-settings-modal__content__warning-banner__icon" />
              <span className="account-settings-modal__content__warning-banner__text">
                {ACCOUNT_DOMAINS_SETTINGS_WARNING}
              </span>
            </Row>
            <Row className="account-settings-modal__content__text-description">
              This setting allows for accounts to be created using any email domain or restrict accounts to be created
              with specific whitelisted email domains.
            </Row>
            <Row className="account-settings-modal__content__radio-btn-grp">
              <FlightRadioButton
                label="Accept any email domain for account creation."
                checked={checked.state === 'any' ? true : false}
                className="account-settings-modal__content__radio-btn-grp__radio-btn"
                onSelect={() => setChecked({ state: 'any' })}
                value={checked.state}
              />
              <FlightRadioButton
                label="Only accept accounts with specific email domains."
                checked={checked.state === 'restricted' ? true : false}
                className="account-settings-modal__content__radio-btn-grp__radio-btn"
                onSelect={() => setChecked({ state: 'restricted' })}
                value={checked.state}
              />
            </Row>
            {checked.state === 'restricted' ? (
              <Row>
                <ArrayFields
                  initialValues={{ values: initialValues }}
                  callback={getValuesFromArrayFields}
                  placeHolderText="myorganization.com"
                  validationSchema={validationSchema}
                  hasError={getErrorState}
                />
              </Row>
            ) : (
              ''
            )}
          </Col>
        }
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                setFields();
                toggleModal();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton
              type="submit"
              label="Apply Changes"
              onClick={() => handleApplyChanges()}
              disabled={checked.state === 'restricted' && (hasError || areFieldsEmpty)}
            />
          </div>
        }
      />
    </div>
  );
}
