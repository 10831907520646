export interface ProjectSetting {
  displayName: string;
  labels: Array<string>;
}
export interface ContentTemplateSettings {
  categories: Array<ProjectSetting>;
}

export enum ContentTemplateSettingsActionTypes {
  SET_CONTENT_TEMPLATE_SETTINGS_STATE = 'SET_CONTENT_TEMPLATE_SETTINGS_STATE',
  RESET_CONTENT_TEMPLATE_SETTINGS_STATE = 'RESET_CONTENT_TEMPLATE_SETTINGS_STATE',
}

interface ContentTemplateSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type ContentTemplateSettingsActions =
  | ContentTemplateSettingsActionCreatorType<
      typeof ContentTemplateSettingsActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE,
      ContentTemplateSettings
    >
  | ContentTemplateSettingsActionCreatorType<
      typeof ContentTemplateSettingsActionTypes.RESET_CONTENT_TEMPLATE_SETTINGS_STATE,
      ContentTemplateSettings
    >;
