export interface UserSubscription {
  createdAt: number;
  id: string;
  isChecked: boolean;
  isEnabled: boolean;
  isRequired: boolean;
  name: string;
  updatedAt: number;
  value: string;
}

interface ConfigName {
  configName: string;
}

export interface UserSubscriptionsForApi {
  config: string;
  configName: ConfigName;
}

export enum UserSubscriptionActionTypes {
  SET_USER_SUBSCRIPTION_STATE = 'SET_USER_SUBSCRIPTION_STATE',
  RESET_USER_SUBSCRIPTION_STATE = 'RESET_USER_SUBSCRIPTION_STATE',
}

interface UserSubscriptionActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type UserSubscriptionActions =
  | UserSubscriptionActionCreatorType<typeof UserSubscriptionActionTypes.SET_USER_SUBSCRIPTION_STATE, UserSubscription>
  | UserSubscriptionActionCreatorType<
      typeof UserSubscriptionActionTypes.RESET_USER_SUBSCRIPTION_STATE,
      UserSubscription
    >;
