import {
  ContextPlugin,
  ContextPlugins,
  Attributes,
  ContextPluginsActionTypes,
  ContextPluginsActions,
  CreateContextPlugin,
} from '../model/contextPlugins';
import ContextService from '../services/context.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setContextPluginState(contextPlugin: ContextPlugin): ContextPluginsActions {
  return {
    type: ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_STATE,
    payload: contextPlugin,
  };
}
export function setContextPluginAttributes(
  contextPlugin: ContextPlugin,
  data: Attributes,
  name: string,
): ContextPluginsActions {
  contextPlugin.values = Object.assign(contextPlugin.values, data);
  return {
    type: ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES,
    payload: contextPlugin,
  };
}
export function deleteAttribute(contextPlugin: ContextPlugin, name: string): ContextPluginsActions {
  delete contextPlugin.values[name];
  return {
    type: ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES,
    payload: contextPlugin,
  };
}
export function resetContextPluginState(): ContextPluginsActions {
  return {
    type: ContextPluginsActionTypes.RESET_CONTEXT_PLUGIN_STATE,
  };
}
export function setContextPluginsState(contextPlugins: ContextPlugins): ContextPluginsActions {
  return {
    type: ContextPluginsActionTypes.SET_CONTEXT_PLUGINS_STATE,
    payload: contextPlugins,
  };
}
export function setCreateAttribute(contextPlugin: ContextPlugin, isCreateAttribute: boolean): ContextPluginsActions {
  contextPlugin.isCreateAttribute = isCreateAttribute;
  return {
    type: ContextPluginsActionTypes.SET_CREATE_ATTRIBUTE,
    payload: contextPlugin,
  };
}
export function setViewAttribute(contextPlugin: ContextPlugin, name: string): ContextPluginsActions {
  contextPlugin.isViewAttribute = name;
  return {
    type: ContextPluginsActionTypes.SET_VIEW_ATTRIBUTE,
    payload: contextPlugin,
  };
}
export function setChangedPlugin(contextPlugin: ContextPlugin): ContextPluginsActions {
  return {
    type: ContextPluginsActionTypes.SET_CHANGE_PLUGIN,
    payload: contextPlugin,
  };
}
export function resetContextPluginsState(): ContextPluginsActions {
  return {
    type: ContextPluginsActionTypes.RESET_CONTEXT_PLUGINS_STATE,
  };
}
export function createContextPluginState(params: CreateContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.createContextPlugin(params);
      if (response.status === 200 || response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context Plugin created.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
        dispatch(resetContextPluginState());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
  };
}
export function deleteContextPlugin(contextPluginId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.deleteContextPlugin(contextPluginId);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context Plugin Deleted.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
        dispatch(resetContextPluginState());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to delete plugin.',
          type: 'danger',
        }),
      );
    }
  };
}
export function updateContextPlugin(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.updateContextPlugin(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context Plugin Updated.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
        dispatch(resetContextPluginState());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
  };
}
export function disableContextPluginStatus(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.disableContextPluginStatus(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Disabled context plugin',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'danger',
        }),
      );
    }
  };
}
export function enableContextPluginStatus(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.enableContextPluginStatus(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Activated context plugin.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Error activating context plugin',
          type: 'danger',
        }),
      );
    }
  };
}
export function fetchContextPlugins() {
  return async (dispatch: { (arg0: ContextPluginsActions): void }) => {
    try {
      const req = new ContextService();
      const params = {
        limit: 1000000,
      };
      const response = await req.getAllContextPlugins(params);
      dispatch(resetContextPluginsState());
      dispatch(setContextPluginsState(response.data));
    } catch (error) {}
    return 'done';
  };
}
