import { UserSubscriptionActions, UserSubscriptionActionTypes, UserSubscription } from '../model/userSubscriptions';
import createReducer from './createReducer';

export const userSubscriptions = createReducer<UserSubscription[]>([], {
  [UserSubscriptionActionTypes.SET_USER_SUBSCRIPTION_STATE](
    state: UserSubscription[],
    action: UserSubscriptionActions,
  ) {
    return [...state, action.payload];
  },

  [UserSubscriptionActionTypes.RESET_USER_SUBSCRIPTION_STATE](state: []) {
    return state.filter(item => !item);
  },
});
