import {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  SECONDS_IN_DAY,
  SECONDS_IN_WEEK,
  SECONDS_IN_MONTH,
  SECONDS_IN_YEAR,
  MINUTES_IN_HOUR,
  MINUTES_IN_DAY,
  MINUTES_IN_WEEK,
  MINUTES_IN_MONTH,
  MINUTES_IN_YEAR,
} from 'constants/timeConversions';

export function getTime(time: number) {
  if (time % SECONDS_IN_YEAR === 0) {
    return time / SECONDS_IN_YEAR;
  } else if (time % SECONDS_IN_MONTH === 0) {
    return time / SECONDS_IN_MONTH;
  } else if (time % SECONDS_IN_WEEK === 0) {
    return time / SECONDS_IN_WEEK;
  } else if (time % SECONDS_IN_DAY === 0) {
    return time / SECONDS_IN_DAY;
  } else if (time % SECONDS_IN_HOUR === 0) {
    return time / SECONDS_IN_HOUR;
  } else if (time % SECONDS_IN_MINUTE === 0) {
    return time / SECONDS_IN_MINUTE;
  } else {
    return time;
  }
}
export function getTimeMinutes(time: number) {
  if (time % MINUTES_IN_YEAR === 0) {
    return time / MINUTES_IN_YEAR;
  } else if (time % MINUTES_IN_MONTH === 0) {
    return time / MINUTES_IN_MONTH;
  } else if (time % MINUTES_IN_WEEK === 0) {
    return time / MINUTES_IN_WEEK;
  } else if (time % MINUTES_IN_DAY === 0) {
    return time / MINUTES_IN_DAY;
  } else if (time % MINUTES_IN_HOUR === 0) {
    return time / MINUTES_IN_HOUR;
  } else {
    return time;
  }
}
export function timeConverter(time: number) {
  if (time % SECONDS_IN_YEAR === 0) {
    return { key: 'years', name: 'years' };
  } else if (time % SECONDS_IN_MONTH === 0) {
    return { key: 'months', name: 'Months' };
  } else if (time % SECONDS_IN_WEEK === 0) {
    return { key: 'weeks', name: 'weeks' };
  } else if (time % SECONDS_IN_DAY === 0) {
    return { key: 'days', name: 'Days' };
  } else if (time % SECONDS_IN_HOUR === 0) {
    return { key: 'hours', name: 'Hours' };
  } else if (time % SECONDS_IN_MINUTE === 0) {
    return { key: 'minutes', name: 'Minutes' };
  } else {
    return { key: 'seconds', name: 'Seconds' };
  }
}
export function minuteTimeConverter(time: number) {
  if (time % MINUTES_IN_YEAR === 0) {
    return { key: 'years', name: 'years' };
  } else if (time % MINUTES_IN_MONTH === 0) {
    return { key: 'months', name: 'Months' };
  } else if (time % MINUTES_IN_WEEK === 0) {
    return { key: 'weeks', name: 'weeks' };
  } else if (time % MINUTES_IN_DAY === 0) {
    return { key: 'days', name: 'Days' };
  } else if (time % MINUTES_IN_HOUR === 0) {
    return { key: 'hours', name: 'Hours' };
  } else {
    return { key: 'minutes', name: 'Minutes' };
  }
}
export function dataExpires(time: number) {
  if (time % MINUTES_IN_YEAR === 0) {
    return 'in ' + time / MINUTES_IN_YEAR + ' years';
  } else if (time % MINUTES_IN_MONTH === 0) {
    return 'in ' + time / MINUTES_IN_MONTH + ' months';
  } else if (time % MINUTES_IN_WEEK === 0) {
    return 'in ' + time / MINUTES_IN_WEEK + ' weeks';
  } else if (time % MINUTES_IN_DAY === 0) {
    return 'in ' + time / MINUTES_IN_DAY + ' days';
  } else if (time % MINUTES_IN_HOUR === 0) {
    return 'in ' + time / MINUTES_IN_HOUR + ' hours';
  } else {
    if (time >= 0) {
      return 'in ' + time + ' minutes';
    } else {
      return 'never expires';
    }
  }
}
export function getTimeUnit(unit: string) {
  if (unit === 'seconds') {
    return 1;
  } else if (unit === 'minutes') {
    return SECONDS_IN_MINUTE;
  } else if (unit === 'hours') {
    return SECONDS_IN_HOUR;
  } else if (unit === 'days') {
    return SECONDS_IN_DAY;
  } else if (unit === 'weeks') {
    return SECONDS_IN_WEEK;
  } else if (unit === 'months') {
    return SECONDS_IN_MONTH;
  } else {
    return SECONDS_IN_YEAR;
  }
}
export function getTimeUnitMinutes(unit: string) {
  if (unit === 'minutes') {
    return 1;
  } else if (unit === 'hours') {
    return MINUTES_IN_HOUR;
  } else if (unit === 'days') {
    return MINUTES_IN_DAY;
  } else if (unit === 'weeks') {
    return MINUTES_IN_WEEK;
  } else if (unit === 'months') {
    return MINUTES_IN_MONTH;
  } else {
    return MINUTES_IN_YEAR;
  }
}
export function stringTimeConverter(time: number) {
  if (time % SECONDS_IN_YEAR === 0) {
    return time / SECONDS_IN_YEAR + ' years';
  } else if (time % SECONDS_IN_MONTH === 0) {
    return time / SECONDS_IN_MONTH + ' months';
  } else if (time % SECONDS_IN_WEEK === 0) {
    return time / SECONDS_IN_WEEK + ' weeks';
  } else if (time % SECONDS_IN_DAY === 0) {
    return time / SECONDS_IN_DAY + ' days';
  } else if (time % SECONDS_IN_HOUR === 0) {
    return time / SECONDS_IN_HOUR + ' hours';
  } else if (time % SECONDS_IN_MINUTE === 0) {
    return time / SECONDS_IN_MINUTE + ' minutes';
  } else {
    return time + ' seconds';
  }
}
