import React, { useEffect, useState } from 'react';
import {
  FlightTextInput,
  FlightRadioButton,
  FlightTooltip,
  FlightNumberInput,
  FlightButton,
  FlightTable,
  FlightSelect,
  FlightCheckbox,
} from '@flybits/webapp-design-system-react';
import * as ContextPluginActions from 'actions/contextPlugins';
import { isEqual } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import * as AuthActions from 'actions/auth';
import { ReactComponent as TrashBin } from 'assets/images/trash_bin.svg';
import { useActions } from 'actions';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { NAME_REQUIRED, NO_SPECIAL_CHARACTERS, CATEGORY_REQUIRED, VALID_ICON } from 'constants/errors/errors';
import { getTime, timeConverter, dataExpires, getTimeUnit } from 'helpers/timeConverters';
import { Row, Col } from 'reactstrap';
import { ReactComponent as DefaultIcon } from 'assets/images/default-ctx-plugin.svg';
import { ContextPlugin, CreateContextPlugin, Attributes, Attribute } from 'model/contextPlugins';
import { Project } from 'model/project';
import { ProjectToken } from 'model/auth';
import { getProjectToken } from 'helpers/auth';
import './CreateContextPlugin.scss';
import { history } from 'configureStore';

const iconRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|svg|tiff))/i;
const categoryRegex = /^[A-Za-z0-9-]*$/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required(NAME_REQUIRED),
  category: Yup.string()
    .required(CATEGORY_REQUIRED)
    .matches(categoryRegex, NO_SPECIAL_CHARACTERS),
  iconUrl: Yup.string().matches(iconRegex, VALID_ICON),
});
interface AttributeType {
  id: string;
  valueType: string;
  valueTypeOptions: any[];
  valueKeys: any[];
  defaultExpDuration: number;
  iconUrl: string;
  color: string;
  name: string;
  description: string;
  parameters: any[];
  performsExtraProcessing: boolean;
  extraProcessingAddress: string;
  isTenantScoped: boolean;
  isTimeContext: boolean;
  shouldBeSaved: boolean;
  isShowValue: boolean;
  isShowDisplayName: boolean;
}
interface TableBody {
  contextId: JSX.Element;
  name: string;
  dateExpiry: string;
  dataFormat: string;
  trash: JSX.Element;
  key: string;
}
interface Props {
  projectFromState?: Project[];
  contextPluginFromState?: ContextPlugin[];
  categories?: Array<string>;
  projectTokenFromState?: ProjectToken[];
}
interface SelectOptionProps {
  key: string;
  name: string;
}
interface CheckboxState {
  state: 'SELECTED' | 'UNSELECTED' | string;
}

export default function CreateNewContextPlugin(props: Props) {
  const { categories, projectFromState = [], projectTokenFromState = [], contextPluginFromState = [] } = props;
  const projectToken = getProjectToken();
  const authActions = useActions(AuthActions);
  console.log(categories);
  let id = '';
  let name = '';
  let subDomain = undefined;
  if (projectFromState.length !== 0) {
    [{ id, name, subDomain }] = projectFromState;
  }
  let projectName = subDomain?.slice(0, subDomain.length - 12);
  let isReserved = false;
  let category = '';
  let description = '';
  let refreshRate = 0;
  let values: Attributes = {};
  let iconUrl = '';
  let userScope = undefined;
  let isCreate: boolean | undefined = false;
  let listOfNames: Array<string> = [];
  let supportedPlatforms: Array<string> = [];
  if (contextPluginFromState?.length !== 0) {
    [
      {
        name,
        category,
        userScope,
        isCreate,
        supportedPlatforms,
        iconUrl,
        isReserved,
        description,
        refreshRate,
        values,
      },
    ] = contextPluginFromState;
  }
  const oldValues: Attributes[] = [Object.assign(values)];
  let initialFormValues = {
    name: contextPluginFromState?.length !== 0 ? name : '',
    category: contextPluginFromState?.length !== 0 ? category : '',
    refreshRate: contextPluginFromState?.length !== 0 ? getTime(refreshRate) : 60,
    refreshRateUnit: 'seconds',
    status: 'Active',
    compatibility: contextPluginFromState?.length !== 0 ? supportedPlatforms : [],
    userScope: userScope ? userScope : false,
    isEnabled: true,
    ios: supportedPlatforms?.includes('ios') ? true : false,
    javascript: supportedPlatforms?.includes('web') ? true : false,
    android: supportedPlatforms?.includes('android') ? true : false,
    isReserved: contextPluginFromState?.length !== 0 ? isReserved : false,
    description: contextPluginFromState?.length !== 0 ? description : '',
    iconUrl: contextPluginFromState?.length !== 0 ? iconUrl : '',
  };
  const contextPluginActions = useActions(ContextPluginActions);
  const [categoryState, setCategoryState] = useState();
  if (contextPluginFromState.length !== 0 && categoryState === null) {
    setCategoryState(category);
  }
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [attributes, setAttributes] = useState<Attributes[]>([]);
  if (contextPluginFromState.length !== 0 && attributes.length === 0) {
    setAttributes([...attributes, Object.assign(values)]);
  }
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        authActions.fetchProjectToken(projectFromState[0].id);
        history.push('/project/' + projectTokenFromState[0].projectId + '/context-plugins');
        contextPluginActions.resetContextPluginState();
      }
    };
    // eslint-disable-next-line
  }, []);
  const createAttribute = (values: any) => {
    history.action = 'PUSH';
    setInitialValues({
      name: values.name,
      category: values.category,
      refreshRate: values.refreshRate,
      refreshRateUnit: 'seconds',
      status: 'Active',
      compatibility: values.supportedPlatforms,
      userScope: values.userScope,
      isEnabled: true,
      isReserved: false,
      description: values.description,
      iconUrl: values.iconUrl,
      ios: values.ios,
      javascript: values.javascript,
      android: values.android,
    });
    const contextPluginView: ContextPlugin = {
      id: '',
      tenantId: '',
      isReserved: false,
      refreshRate: 0,
      supportedPlatforms: [''],
      provider: '',
      category: values.category,
      userScope: false,
      isSaRestricted: false,
      name: values.name,
      description: values.description,
      iconUrl: values.iconUrl,
      values: (contextPluginFromState && contextPluginFromState[0]).values ? (contextPluginFromState && contextPluginFromState[0]).values : {},
      tenantConfig: {
        id: '',
        tenantId: '',
        contextPluginId: '',
        isEnabled: false,
        refreshRate: 0,
      },
      dataSource: '',
      isView: true,
      isCreate: true,
      isCreateAttribute: true,
      isPreview: false,
    };
    contextPluginActions.setContextPluginState(contextPluginView);
  };
  const viewAttribute = (attribute: Attribute) => {
    history.action = 'PUSH';
    contextPluginActions.setViewAttribute(contextPluginFromState[0] && contextPluginFromState, attribute.uid);
  };
  const [isToggleStatusConfirm, setIsToggleStatusConfirm] = useState(false);
  const [idStatus, setIdForStatus] = useState('');
  const toggleStatusConfirm = (id: string) => {
    if (idStatus === '') {
      setIdForStatus(id);
    } else {
      setIdForStatus('');
    }
    setIsToggleStatusConfirm(isToggleStatusConfirm => !isToggleStatusConfirm);
  };
  const handleCancelStatus = () => {
    setIdForStatus('');
    toggleStatusConfirm('');
  };
  const [refreshRateTime, setRefreshRateTime] = useState<SelectOptionProps>({ key: '', name: '' });
  const options = [
    {
      key: 'seconds',
      name: 'Seconds',
    },
    {
      key: 'minutes',
      name: 'Minutes',
    },
    {
      key: 'hours',
      name: 'Hours',
    },
    {
      key: 'days',
      name: 'Days',
    },
    {
      key: 'weeks',
      name: 'Weeks',
    },
    {
      key: 'months',
      name: 'Months',
    },
    {
      key: 'years',
      name: 'Years',
    },
  ];
  if (contextPluginFromState.length !== 0 && refreshRate) {
    if (refreshRate >= 0 && refreshRateTime.key === '') {
      setRefreshRateTime(timeConverter(refreshRate));
    }
  } else {
    if (refreshRateTime.key === '') {
      setRefreshRateTime({ key: 'seconds', name: 'Seconds' });
    }
  }
  const handleOptionClick = (option: SelectOptionProps) => {
    setRefreshRateTime(option);
  };
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const toggleCancelConfirm = () => {
    setIsCancelConfirm(!isCancelConfirm);
  };
  const handleCancel = () => {
    contextPluginActions.resetContextPluginState();
  };
  const tableHeaders = [
    {
      name: 'Context ID',
      key: 'contextId',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Data Format',
      key: 'dataFormat',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Data Expiry',
      key: 'dateExpiry',
      isVisible: true,
      hideTooltip: true,
    },
    {
      key: 'trash',
      name: '',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [idDelete, setIdForDelete] = useState('');
  const toggleDeleteConfirm = (id: string) => {
    if (idDelete === '') {
      setIdForDelete(id);
    } else {
      setIdForDelete('');
    }
    setIsDeleteConfirm(isDeleteConfirm => !isDeleteConfirm);
  };
  const handleCancelDelete = () => {
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  const toggleStatus = () => {
    const plugin = contextPluginFromState && contextPluginFromState[0];
    const status = !plugin.tenantConfig.isEnabled;
    if (status) {
      contextPluginActions.enableContextPluginStatus(plugin);
    } else {
      contextPluginActions.disableContextPluginStatus(plugin);
    }
    setIsToggleStatusConfirm(false);
    setIdForStatus('');
  };
  const tableBody: TableBody[] = [];
  for (const name in (contextPluginFromState && contextPluginFromState[0])?.values) {
    listOfNames.push((contextPluginFromState && contextPluginFromState[0])?.values[name].name);
    tableBody.push({
      contextId: (
        <FlightTooltip
          className="context-plugins__table__name__text__name"
          direction="right"
          isEnabled={true}
          description={
            (contextPluginFromState && contextPluginFromState[0])?.values[name].description
              ? (contextPluginFromState && contextPluginFromState[0])?.values[name].description
              : 'This plugin has no description'
          }
          delay={0}
        >
          <span onClick={() => viewAttribute((contextPluginFromState && contextPluginFromState[0])?.values[name])}>
            {'ctx.' +
              projectName +
              '.' +
              (contextPluginFromState && contextPluginFromState[0])?.category +
              '.' +
              (contextPluginFromState && contextPluginFromState[0])?.values[name].name}
          </span>
        </FlightTooltip>
      ),
      name: (contextPluginFromState && contextPluginFromState[0])?.values[name].name,
      dataFormat: (contextPluginFromState && contextPluginFromState[0])?.values[name].valueType,
      dateExpiry: !(contextPluginFromState && contextPluginFromState[0])?.values[name].isNeverExpires
        ? dataExpires((contextPluginFromState && contextPluginFromState[0])?.values[name].defaultExpDuration)
        : 'never',
      trash: (
        <div className={isReserved ? 'create-context-plugin__invisible' : 'create-attribute__trash'}>
          <TrashBin onClick={() => toggleDeleteConfirm((contextPluginFromState && contextPluginFromState[0])?.values[name].name)} />
        </div>
      ),
      key: (contextPluginFromState && contextPluginFromState[0])?.values[name].name,
    });
  }
  const deleteAttribute = (data: Attribute) => {
    contextPluginActions.deleteAttribute(contextPluginFromState[0] && contextPluginFromState, idDelete);
    setIdForDelete('');
    toggleDeleteConfirm('');
  };
  return (
    <div>
      <div className="create-context-plugin">
        <Col>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let attributesForApi: Attributes = {};
              let platforms: string[] = [];
              if (values.ios) {
                platforms.push('ios');
              }
              if (values.android) {
                platforms.push('android');
              }
              if (values.javascript) {
                platforms.push('web');
              }
              for (const name in attributes) {
                const iterator = attributes[name];
                for (const innerName in iterator) {
                  const attributeValues = {
                    uid: 'ctx.' + iterator[innerName].name,
                    name: iterator[innerName].name,
                    description: iterator[innerName].description,
                    valueType: iterator[innerName].valueType,
                    valueKeys: iterator[innerName].valueKeys,
                    inputAttrExpiryRate: iterator[innerName].inputAttrExpiryRate,
                    inputAttrExpiryRateUnit: iterator[innerName].inputAttrExpiryRateUnit,
                    defaultExpDuration: iterator[innerName].defaultExpDuration,
                    isNeverExpires: iterator[innerName].isNeverExpires,
                    valueTypeOptions: iterator[innerName].valueTypeOptions,
                    parameters: iterator[innerName].parameters,
                    isTenantScoped: iterator[innerName].isTenantScoped,
                    isTimeContext: iterator[innerName].isTimeContext,
                    shouldBeSaved: iterator[innerName].shouldBeSaved,
                    isShowValue: iterator[innerName].isShowValue,
                    isShowDisplayName: iterator[innerName].isShowDisplayName,
                  };
                  attributesForApi[iterator[innerName].name] = attributeValues;
                }
              }
              var timeUnit = getTimeUnit(refreshRateTime.key);
              const createContextPlugin: CreateContextPlugin = {
                category: values.category,
                description: values.description,
                iconUrl: values.iconUrl,
                isEnabled: values.isEnabled,
                isReserved: values.isReserved,
                name: values.name,
                projectId: id,
                refreshRate: values.refreshRate * timeUnit,
                supportedPlatforms: platforms,
                token: projectToken,
                userScope: values.userScope,
                values: attributesForApi,
              };
              if (!isCreate) {
                (contextPluginFromState && contextPluginFromState[0]).name = values.name;
                (contextPluginFromState && contextPluginFromState[0]).description = values.description;
                (contextPluginFromState && contextPluginFromState[0]).supportedPlatforms = platforms;
                (contextPluginFromState && contextPluginFromState[0]).iconUrl = values.iconUrl;
                (contextPluginFromState && contextPluginFromState[0]).refreshRate = values.refreshRate * timeUnit;
                (contextPluginFromState && contextPluginFromState[0]).userScope = values.userScope;
                (contextPluginFromState && contextPluginFromState[0]).supportedPlatforms = platforms;
                (contextPluginFromState && contextPluginFromState[0]).values = attributesForApi;
                contextPluginActions.updateContextPlugin(contextPluginFromState[0] && contextPluginFromState);
              } else {
                contextPluginActions.createContextPluginState(createContextPlugin);
              }
              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, setFieldValue, isValid, touched }) => (
              <div className="create-context-plugin__form">
                <div className="pt-5 create-context-plugin__fields">Label</div>
                <Row>
                  <Field
                    type="text"
                    name="name"
                    className="mt-3 create-context-plugin__inputs"
                    as={FlightTextInput}
                    disabled={contextPluginFromState?.length !== 0 ? isReserved : false}
                    width="332px"
                    hasError={touched.name && errors.name ? true : false}
                    value={values.name}
                    errorMessage={<span>{errors.name}</span>}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <div className="pt-5 create-context-plugin__fields">Category</div>
                <Row>
                  <Field
                    type="text"
                    name="category"
                    className="mt-3 create-context-plugin__inputs"
                    as={FlightTextInput}
                    disabled={!isCreate}
                    width="332px"
                    hasError={
                      errors.category || (categories?.includes(values.category) && isCreate)
                    }
                    value={values.category}
                    errorMessage={
                      <span>{categories?.includes(values.category) ? 'Category already exists' : errors.category}</span>
                    }
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setCategoryState(e.currentTarget.value);
                    }}
                    onBlur={handleBlur}
                  />
                </Row>
                {(contextPluginFromState && contextPluginFromState[0])?.id ? (
                  <span className="create-context-plugin__id-preview">{(contextPluginFromState && contextPluginFromState[0]).id}</span>
                ) : (
                  <span className="create-context-plugin__id-preview">
                    ctx.{projectName}.{values.category}
                  </span>
                )}
                <div className="pt-5 create-context-plugin__fields">Refresh Rate</div>
                <Row>
                  <Field
                    type="number"
                    name="refreshRate"
                    className="mt-3 create-context-plugin__refresh-rate"
                    as={FlightNumberInput}
                    width="100px"
                    disabled={isReserved}
                    maxValue={1000}
                    minValue={0}
                    value={values.refreshRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FlightSelect
                    label=""
                    className="mt-3 create-context-plugin__refresh-rate"
                    width="100px"
                    selected={refreshRateTime}
                    disabled={isReserved}
                    options={options}
                    handleOptionClick={handleOptionClick}
                  />
                </Row>
                <div className="pt-5 create-context-plugin__fields">Status</div>
                <Row>
                  {!(contextPluginFromState && contextPluginFromState[0])?.isCreate &&
                  (contextPluginFromState && contextPluginFromState[0])?.tenantConfig.isEnabled === false ? (
                    <span className="create-context-plugin__inactive">Inactive</span>
                  ) : (
                    <span className="create-context-plugin__active">Active</span>
                  )}
                  {!(contextPluginFromState && contextPluginFromState[0])?.isCreate ? (
                    <FlightButton
                      label={(contextPluginFromState && contextPluginFromState[0])?.tenantConfig.isEnabled ? 'Deactivate' : 'Activate'}
                      theme="link"
                      onClick={() => toggleStatusConfirm((contextPluginFromState && contextPluginFromState[0])?.id)}
                    />
                  ) : null}
                </Row>
                <div className="pt-5 create-context-plugin__fields">Compatibility</div>
                <Row>
                  <FlightCheckbox
                    label="Android"
                    checkState={values.android ? 'SELECTED' : 'UNSELECTED'}
                    disabled={isReserved}
                    onSelect={() => setFieldValue('android', !values.android)}
                    className={'create-attribute__options__option'}
                  />
                  <FlightCheckbox
                    label="IOS"
                    disabled={isReserved}
                    checkState={values.ios ? 'SELECTED' : 'UNSELECTED'}
                    onSelect={() => setFieldValue('ios', !values.ios)}
                    className={'create-attribute__options__option'}
                  />
                  <FlightCheckbox
                    label="Javascript"
                    disabled={isReserved}
                    checkState={values.javascript ? 'SELECTED' : 'UNSELECTED'}
                    onSelect={() => setFieldValue('javascript', !values.javascript)}
                    className={'create-attribute__options__option'}
                  />
                </Row>
                <div className="pt-5 create-context-plugin__fields">Scope</div>
                <Row>
                  <Field
                    type="radio"
                    name="tenantScoped"
                    label="User"
                    checked={values.userScope}
                    disabled={isReserved}
                    value="userScope"
                    className={'create-attribute__options__option'}
                    as={FlightRadioButton}
                    onSelect={() => setFieldValue('userScope', true)}
                  />
                  <Field
                    type="radio"
                    name="tenantScoped"
                    label="Project"
                    checked={!values.userScope}
                    disabled={isReserved}
                    value="scoped"
                    as={FlightRadioButton}
                    className={'create-attribute__options__option'}
                    onSelect={() => setFieldValue('userScope', false)}
                  />
                </Row>
                <div className="pt-5 create-context-plugin__fields">Description</div>
                <Row>
                  <Field
                    type="text"
                    name="description"
                    className="mt-3 create-context-plugin__inputs"
                    as={FlightTextInput}
                    disabled={contextPluginFromState?.length !== 0 ? isReserved : false}
                    width="332px"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <div className="pt-5 create-context-plugin__fields">Icon URL </div>
                <Row>
                  <Field
                    type="text"
                    name="iconUrl"
                    className="mt-3 create-context-plugin__inputs"
                    as={FlightTextInput}
                    disabled={contextPluginFromState?.length !== 0 ? isReserved : false}
                    width="332px"
                    value={values.iconUrl}
                    hasError={errors.iconUrl ? true : false}
                    errorMessage={<span>{errors.iconUrl}</span>}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                {values.iconUrl && !errors.iconUrl ? (
                  <img className="context-plugins__table__name__icon" src={values.iconUrl} alt="plugin-icon"></img>
                ) : (
                  <DefaultIcon className="context-plugins__table__name__icon" />
                )}
                <div className="pt-5 create-context-plugin__fields">Add custom attributes </div>
                <Row>
                  <span className="create-context-plugin__id-preview2">
                    Every Attribute has a name, description and expiry date
                  </span>
                </Row>
                <div>
                  {tableBody.length !== 0 ? (
                    <FlightTable
                      className="create-attribute__table"
                      tableHeaders={tableHeaders}
                      tableData={tableBody}
                      hasPaginationBeforeTable={false}
                    />
                  ) : null}
                </div>
                <Row>
                  <FlightButton
                    onClick={() => createAttribute(values)}
                    className="create-context-plugin__inputs"
                    label="+ New Attribute"
                    disabled={!isCreate}
                    theme="link"
                  />
                </Row>
                <Row className="create-push-template__page-ftr">
                  <FlightButton
                    onClick={() => {
                      if (
                        initialValues === values &&
                        (isEqual(attributes, oldValues) ||
                          (attributes.length === 0 && Object.values(oldValues[0]).length === 0)) &&
                        (isEqual(refreshRateTime, timeConverter(refreshRate)) || refreshRate === 0)
                      ) {
                        handleCancel();
                      } else {
                        toggleCancelConfirm();
                      }
                    }}
                    label="Cancel"
                    theme="secondary"
                  />
                  <FlightButton
                    onClick={handleSubmit}
                    className="create-push-template__page-ftr__save-btn"
                    label="Save"
                    disabled={
                      errors.category === NO_SPECIAL_CHARACTERS ||
                      values.name.length < 1 ||
                      values.category.length < 1 ||
                      errors.category === 'No spaces allowed' ||
                      (categories?.includes(values?.category) &&
                        (contextPluginFromState && contextPluginFromState[0])?.category !== values?.category) ||
                      errors.iconUrl === 'Must be a valid icon url' ||
                      (initialValues === values &&
                        isEqual(attributes, oldValues) &&
                        isEqual(refreshRateTime, timeConverter(refreshRate)))
                    }
                    theme="primary"
                  />
                </Row>
              </div>
            )}
          </Formik>
        </Col>
        <ConfirmModal
          isVisible={isToggleStatusConfirm}
          title="Confirm Change Status"
          message="Are you sure you want to change the status of this plugin?"
          toggleCallback={toggleStatusConfirm}
          continueCallback={toggleStatus}
          cancelCallback={handleCancelStatus}
        />
        <ConfirmModal
          isVisible={isDeleteConfirm}
          title="Delete Attribute"
          message="Are you sure you want to delete this attribute?"
          toggleCallback={toggleDeleteConfirm}
          continueCallback={deleteAttribute}
          cancelCallback={handleCancelDelete}
        />
        <ConfirmModal
          isVisible={isCancelConfirm}
          title="Confirm Exit"
          message="Are you sure you want to exit without saving?"
          toggleCallback={toggleCancelConfirm}
          continueCallback={handleCancel}
          cancelCallback={toggleCancelConfirm}
        />
      </div>
    </div>
  );
}
