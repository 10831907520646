import { FcmActionTypes, FcmActions, FcmSettings } from '../model/fcmSettings';
import createReducer from './createReducer';
import { ApnsSettings, ApnsActions, ApnsSettingsActionTypes, ApnsSetting } from 'model/apnsSettings';
import { SilentPushActions, SilentPushActionTypes, SilentPushSettings } from 'model/silentPushSettings';
import {
  NotificationTypes,
  NotificationTypesActions,
  NotificationTypesActionTypes,
  NotificationType,
} from 'model/notificationTypes';

export const fcmSettings = createReducer<FcmSettings[]>([], {
  [FcmActionTypes.SET_FCM_SETTINGS](state: FcmSettings[], action: FcmActions) {
    return [...state, action.payload];
  },

  [FcmActionTypes.RESET_FCM_SETTINGS](state: []) {
    return state.filter(item => !item);
  },
});

export const apnsSettings = createReducer<ApnsSettings[]>([], {
  [ApnsSettingsActionTypes.SET_APNS_SETTINGS](state: ApnsSettings[], action: ApnsActions) {
    return [...state, action.payload];
  },

  [ApnsSettingsActionTypes.RESET_APNS_SETTINGS](state: []) {
    return state.filter(item => !item);
  },
});

export const apnsSetting = createReducer<ApnsSetting[]>([], {
  [ApnsSettingsActionTypes.SET_APNS_SETTING](state: ApnsSetting[], action: ApnsActions) {
    return [...state, action.payload];
  },

  [ApnsSettingsActionTypes.RESET_APNS_SETTING](state: []) {
    return state.filter(item => !item);
  },
});

export const silentPushSettings = createReducer<SilentPushSettings[]>([], {
  [SilentPushActionTypes.SET_SILENT_PUSH_SETTINGS](state: SilentPushActions[], action: SilentPushActions) {
    return [...state, action.payload];
  },

  [SilentPushActionTypes.RESET_SILENT_PUSH_SETTINGS](state: []) {
    return state.filter(item => !item);
  },
});

export const notificationTypes = createReducer<NotificationTypes[]>([], {
  [NotificationTypesActionTypes.SET_NOTIFICATION_TYPES_STATE](
    state: NotificationTypesActions[],
    action: NotificationTypesActions,
  ) {
    return [...state, action.payload];
  },

  [NotificationTypesActionTypes.RESET_NOTIFICATION_TYPES_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const notificationType = createReducer<NotificationType[]>([], {
  [NotificationTypesActionTypes.SET_NOTIFICATION_TYPE_STATE](
    state: NotificationTypesActions[],
    action: NotificationTypesActions,
  ) {
    return [...state, action.payload];
  },

  [NotificationTypesActionTypes.RESET_NOTIFICATION_TYPE_STATE](state: []) {
    return state.filter(item => !item);
  },
});
