export interface GoogleSettings {
  clientID: string;
  secretKey: string;
  redirectURI: string;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum GoogleSettingsActionTypes {
  SET_GOOGLESETTINGS_STATE = 'SET_GOOGLESETTINGS_STATE',
  RESET_GOOGLESETTINGS_STATE = 'RESET_GOOGLESETTINGS_STATE',
}

interface GoogleSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type GoogleSettingsActions =
  | GoogleSettingsActionCreatorType<typeof GoogleSettingsActionTypes.SET_GOOGLESETTINGS_STATE, GoogleSettings>
  | GoogleSettingsActionCreatorType<typeof GoogleSettingsActionTypes.RESET_GOOGLESETTINGS_STATE, GoogleSettings>;
