import { ProjectAction, Project, ProjectActionTypes } from '../model/project';

import createReducer from './createReducer';

//TODO: sai.karthik@flybits.com : consider trade off's between arrays vs objects for reducer
export const project = createReducer<Project[]>([], {
  [ProjectActionTypes.SET_PROJECT_STATE](state: Project[], action: ProjectAction) {
    return [...state, action.payload];
  },
  [ProjectActionTypes.RESET_PROJECT_STATE](state: []) {
    return state.filter(item => !item);
  },
});
