import React, { useEffect, useState } from 'react';
import * as NotificationTypeActions from 'actions/notificationTypes';
import { useActions } from 'actions';
import './CreatePushTemplate.scss';
import {
  FlightTextInput,
  FlightOverflowMenu,
  FlightButton,
  FlightTable,
  FlightModal,
} from '@flybits/webapp-design-system-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { NAME_REQUIRED, DESCRIPTION_REQUIRED } from 'constants/errors/errors';
import { Row, Col } from 'reactstrap';
import CreatePushTemplateModal from './CreatePushTemplateModal';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import { getValueType } from 'helpers/getPushValueType';
import {
  SchemaTypeForCreate,
  CreateNotificationType,
  NotificationType,
  Schema,
  primitiveTypes,
  dropDownTypes,
} from 'model/notificationTypes';
import { isEmpty } from 'lodash';
import { history } from 'configureStore';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(NAME_REQUIRED),
  description: Yup.string().required(DESCRIPTION_REQUIRED),
});

interface ModalState {
  header: string;
  type: 'primitive' | 'dropdown' | ' url' | 'entity' | 'image' | 'json' | string;
  description: string;
  isDropdown: boolean;
  isBool?: boolean;
  fieldName: string;
  valueType?: string;
  valueOptions?: Array<string>;
}

interface SchemaType {
  description: string;
  isRequiredField: boolean;
  type: string | undefined;
  valueType: string;
  valueOptions: Array<string>;
  isTracked: boolean;
}

interface TableBody {
  keyField: string;
  description: string;
  required: string;
  inputType: JSX.Element;
  tracked: string;
  moreOpts: JSX.Element;
  key: number;
}

interface LocalSchema {
  [key: string]: SchemaType;
}

interface Props {
  notificationTypeFromState?: NotificationType[];
}

const tableHeaders = [
  {
    name: 'Key',
    key: 'keyField',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Description',
    key: 'description',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Required',
    key: 'required',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Input Type',
    key: 'inputType',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Tracked',
    key: 'tracked',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: '',
    key: 'moreOpts',
    isVisible: true,
    hideTooltip: true,
  },
];

export default function CreatePushTemplate(props: Props) {
  const { notificationTypeFromState = [] } = props;
  const notificationTypeActions = useActions(NotificationTypeActions);

  let name = '';
  let description = '';
  let schema: { [key: string]: Schema } = {};
  if (notificationTypeFromState.length !== 0) {
    [{ name, description, schema }] = notificationTypeFromState;
  }

  const initialFormValues = {
    name: name,
    description: description,
    fieldName: '',
    fieldDescription: '',
    key: '',
  };

  const [initialValues, setInitialValues] = useState(initialFormValues);
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.go(1);
        notificationTypeActions.resetNotificationType();
      }
    };
    // eslint-disable-next-line
  }, []);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setInitialValues({ name: name, description: description, fieldName: '', fieldDescription: '', key: '' });
    setShowCreateModal(showCreateModal => !showCreateModal);
  };

  const [modalState, setModal] = useState<ModalState>({
    description: '',
    header: '',
    fieldName: '',
    type: '',
    isDropdown: false,
  });

  const createFreeFormFields = (fieldName?: string, valueType?: string) => {
    setModal({
      header: 'New Freeform Input Field',
      description: 'This description needs to be decided.',
      isDropdown: false,
      type: 'primitive',
      fieldName: fieldName || '',
      valueType: valueType || 'string',
    });
    toggleCreateModal();
  };

  const createDropdownFields = (fieldName?: string, valueType?: string, valueOptions?: Array<string>) => {
    setModal({
      header: 'New Dropdown Input Field',
      description: 'This description needs to be decided.',
      isDropdown: true,
      type: 'dropdown',
      fieldName: fieldName || '',
      valueType: valueType || 'string',
      valueOptions: valueOptions,
    });
    toggleCreateModal();
  };

  const createWeblinkField = (fieldName?: string) => {
    setModal({
      header: 'New Weblink Field',
      description: 'This description needs to be decided.',
      isDropdown: false,
      type: 'url',
      fieldName: fieldName || '',
    });
    toggleCreateModal();
  };

  const createContentPickerField = (fieldName?: string) => {
    setModal({
      header: 'New Content Picker Field',
      description: 'This description needs to be decided. It can be anything that relates to this field',
      isDropdown: false,
      type: 'entity',
      fieldName: fieldName || '',
    });
    toggleCreateModal();
  };

  const createImagePickerField = (fieldName?: string) => {
    setModal({
      header: 'New Image Picker Field',
      description: 'This description needs to be decided.',
      isDropdown: false,
      type: 'image',
      fieldName: fieldName || '',
    });
    toggleCreateModal();
  };

  const createYesNoField = (fieldName?: string) => {
    setModal({
      header: 'New Yes/No Field',
      description: 'This description needs to be decided.',
      isDropdown: false,
      type: 'primitive',
      isBool: true,
      fieldName: fieldName || '',
    });
    toggleCreateModal();
  };

  const createJsonField = (fieldName?: string) => {
    setModal({
      header: 'New JSON Field',
      description: 'This description needs to be decided.',
      isDropdown: false,
      type: 'json',
      fieldName: fieldName || '',
      valueType: 'string',
    });
    toggleCreateModal();
  };

  const optionGroups = [
    [
      {
        key: 1,
        name: 'Freeform',
        disabled: false,
        onClick: () => createFreeFormFields(''),
      },
      {
        key: 2,
        name: 'Dropdown',
        disabled: false,
        onClick: () => createDropdownFields(''),
      },
      {
        key: 3,
        name: 'Weblink',
        disabled: false,
        onClick: () => createWeblinkField(''),
      },
      {
        key: 4,
        name: 'Content Picker',
        disabled: false,
        onClick: () => createContentPickerField(''),
      },
      {
        key: 5,
        name: 'Image Picker',
        disabled: false,
        onClick: () => createImagePickerField(''),
      },
      {
        key: 6,
        name: 'Yes/No(Boolean)',
        disabled: false,
        onClick: () => createYesNoField(''),
      },
      {
        key: 7,
        name: 'JSON Object',
        disabled: false,
        onClick: () => createJsonField(''),
      },
    ],
  ];

  const [localSchema, setLocalSchema] = useState<LocalSchema[]>([]);
  const getSchemaFromModal = (data: {}) => {
    setLocalSchema([...localSchema, data]);
  };

  const [showValuesModal, setShowValuesModal] = useState(false);
  const [valuesModalContent, setValuesModalContent] = useState(['']);
  const toggleValuesModal = () => {
    setValuesModalContent(['']);
    setShowValuesModal(showValuesModal => !showValuesModal);
  };

  const tableBody: TableBody[] = [];
  const showValues = (values: string[]) => {
    toggleValuesModal();
    setValuesModalContent(values);
  };

  const handleDuplicate = (value: SchemaType) => {
    const valueType = getValueType(value.type, value.valueType);
    if (valueType in primitiveTypes) {
      createFreeFormFields(value.description, value.valueType);
    } else if (valueType in dropDownTypes) {
      createDropdownFields(value.description, value.valueType, value.valueOptions);
    } else if (valueType === 'Weblink') {
      createWeblinkField(value.description);
    } else if (valueType === 'JSON Object') {
      createJsonField(value.description);
    } else if (valueType === 'Image Picker') {
      createImagePickerField(value.description);
    } else if (valueType === 'Content Picker') {
      createContentPickerField();
    } else if (valueType === 'Yes/No') {
      createContentPickerField(value.description);
    }
  };

  const handleDelete = (keyId: string) => {
    for (const key in localSchema) {
      const iterator = localSchema[key];
      for (const innerKey in iterator) {
        if (innerKey === keyId) {
          delete iterator[keyId];
        }
      }
    }
    setLocalSchema([...localSchema]);
  };

  const moreOpts = (key: string, value: SchemaType) => {
    const optionGroups = [
      [
        {
          key: 1,
          name: 'Duplicate',
          disabled: false,
          onClick: () => handleDuplicate(value),
        },
        {
          key: 2,
          name: 'Delete',
          disabled: false,
          onClick: () => handleDelete(key),
        },
      ],
    ];

    return <FlightOverflowMenu optionGroups={optionGroups} />;
  };

  const listOfKeys: Array<string> = [];
  for (const key in localSchema) {
    const iterator = localSchema[key];
    for (const innerKey in iterator) {
      listOfKeys.push(innerKey);
      tableBody.push({
        keyField: innerKey,
        description: iterator[innerKey].description,
        required: iterator[innerKey].isRequiredField ? 'Yes' : 'No',
        inputType: (
          <span>
            <div>{getValueType(iterator[innerKey].type, iterator[innerKey].valueType)}</div>
            <div>
              {iterator[innerKey].valueOptions?.length !== 0 ? (
                <span
                  className="manage-templates__container__link"
                  onClick={() => showValues(iterator[innerKey].valueOptions || [''])}
                >
                  View values
                </span>
              ) : (
                ''
              )}
            </div>
          </span>
        ),
        moreOpts: moreOpts(innerKey, iterator[innerKey]),
        tracked: iterator[innerKey].isTracked ? 'Yes' : 'No',
        key: Math.random(),
      });
    }
  }
  if (!isEmpty({ schema }) && localSchema.length === 0) {
    delete schema['trackedKeys'];
    getSchemaFromModal(schema);
  }

  const handleCancel = () => {
    notificationTypeActions.resetNotificationType();
  };

  return (
    <div className="create-push-template">
      <FlightModal
        isVisible={showValuesModal}
        size="small"
        className="manage-templates__modal"
        toggleModalShown={toggleValuesModal}
        header={<span>Values</span>}
        content={
          <span className="manage-templates__modal__content">
            {valuesModalContent.map(values => (
              <div className="manage-templates__modal__content__values" key={Math.random()}>
                {values}
              </div>
            ))}
          </span>
        }
        footer={
          <div className="modal-footer">
            <FlightButton type="secondary" onClick={toggleValuesModal} label="Close" />
          </div>
        }
      />
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            let schemaForApi: SchemaTypeForCreate = {};
            const keysTracked = [];
            for (const key in localSchema) {
              const iterator = localSchema[key];
              for (const innerKey in iterator) {
                const schemaValues = {
                  isEnabled: true,
                  isRequired: iterator[innerKey].isRequiredField,
                  isTracked: iterator[innerKey].isTracked,
                  key: innerKey,
                  description: iterator[innerKey].description,
                  type: iterator[innerKey].type || '',
                  valueType: iterator[innerKey].valueType,
                  valueOptions: iterator[innerKey].valueOptions,
                };
                schemaForApi = Object.assign({ [innerKey]: schemaValues }, schemaForApi);
                if (iterator[innerKey].isTracked) {
                  keysTracked.push(innerKey);
                }
              }
            }

            const trackedKeys = {
              description: 'This field contains all tracked keys. This field should not be visible to signed-in user.',
              isRequired: false,
              type: 'dropdown',
              valueType: 'string',
              valueOptions: keysTracked,
            };

            schemaForApi = Object.assign({ trackedKeys: trackedKeys }, schemaForApi);
            const createNotificationType: CreateNotificationType = {
              isActive: true,
              isReserved: false,
              isDefault: false,
              name: values.name,
              description: values.description,
              schema: schemaForApi,
            };
            notificationTypeActions.createNotificationType(createNotificationType);
            resetForm();
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <div>
              <div className="pt-5 create-push-template__fields">Name</div>
              <Row>
                <Field
                  type="text"
                  name="name"
                  className="mt-3"
                  as={FlightTextInput}
                  width="332px"
                  hasError={touched.name && errors.name ? true : false}
                  value={values.name}
                  errorMessage={<span>{errors.name}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Row>
              <div className="pt-5 create-push-template__fields">Description</div>
              <Row>
                <Field
                  type="text"
                  name="description"
                  className="mt-3"
                  as={FlightTextInput}
                  width="332px"
                  hasError={touched.description && errors.description ? true : false}
                  value={values.description}
                  errorMessage={<span>{errors.description}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Row>
              <div className="pt-5 create-push-template__fields">Custom Input Fields</div>
              <div className="pt-3 create-push-template__description">
                Every notification has a message title and body field.
              </div>
              <div className="pt-3 pb-3 create-push-template__table">
                {tableBody.length !== 0 ? (
                  <ReusableCard>
                    <FlightTable tableHeaders={tableHeaders} tableData={tableBody} hasPaginationBeforeTable={false} />
                  </ReusableCard>
                ) : null}
              </div>

              <FlightOverflowMenu
                isOpenRight={true}
                label="Add Input Field"
                className="mt-3 create-push-template__add"
                iconLeft={'add'}
                iconRight={''}
                optionGroups={optionGroups}
              />
              <CreatePushTemplateModal
                toggleModal={toggleCreateModal}
                openModal={showCreateModal}
                modalState={modalState}
                callback={getSchemaFromModal}
                listOfKeys={listOfKeys}
              />
              <Row className="create-push-template__page-ftr">
                <FlightButton onClick={handleCancel} label="Cancel" theme="secondary" />
                <FlightButton
                  onClick={handleSubmit}
                  disabled={tableBody.length === 0}
                  className="create-push-template__page-ftr__save-btn"
                  label="Save"
                  theme="primary"
                />
              </Row>
            </div>
          )}
        </Formik>
      </Col>
    </div>
  );
}
