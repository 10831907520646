import {
  ContextPluginsActions,
  ContextPlugin,
  ContextPlugins,
  ContextPluginsActionTypes,
} from '../model/contextPlugins';
import createReducer from './createReducer';

export const contextPlugins = createReducer<ContextPlugins[]>([], {
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGINS_STATE](state: ContextPlugins[], action: ContextPluginsActions) {
    return [...state, action.payload];
  },
  [ContextPluginsActionTypes.RESET_CONTEXT_PLUGINS_STATE](state: []) {
    return state.filter(item => !item);
  },
});

export const contextPlugin = createReducer<ContextPlugin[]>([], {
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_STATE](state: ContextPlugin[], action: ContextPluginsActions) {
    return [action.payload];
  },
  [ContextPluginsActionTypes.RESET_CONTEXT_PLUGIN_STATE](state: []) {
    return  state.filter(item => !item);
  },
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES](state: ContextPlugin[], action: ContextPluginsActions) {
    return state.map(contextPlugin =>
      contextPlugin ? { ...contextPlugin, values: action.payload?.values } : contextPlugin,
    );
  },
  [ContextPluginsActionTypes.SET_CREATE_ATTRIBUTE](state: ContextPlugin[], action: ContextPluginsActions) {
    return state.map(contextPlugin =>
      contextPlugin ? { ...contextPlugin, isCreateAttribute: action.payload?.isCreateAttribute } : contextPlugin,
    );
  },
  [ContextPluginsActionTypes.SET_VIEW_ATTRIBUTE](state: ContextPlugin[], action: ContextPluginsActions) {
    return state.map(contextPlugin =>
      contextPlugin ? { ...contextPlugin, isViewAttribute: action.payload?.isViewAttribute } : contextPlugin,
    );
  },
  [ContextPluginsActionTypes.SET_CHANGE_PLUGIN](state: ContextPlugin[], action: ContextPluginsActions) {
    return state.map(contextPlugin => (contextPlugin ? { ...contextPlugin, isChanged: true } : contextPlugin));
  },
});
