import React, { ReactNode } from 'react';
import { Card, CardBody } from 'reactstrap';
import './ReusableCard.scss';

interface Props {
  children: ReactNode;
  maxHeight?: string;
  noPadding?: boolean;
}

export default function ReusableCard(props: Props) {
  const { children, maxHeight, noPadding } = props;
  return (
    <Card className={`reusable-card${noPadding ? '--no-padding' : ''}`} style={{ maxHeight: `${maxHeight}` }}>
      <CardBody>{children}</CardBody>
    </Card>
  );
}
