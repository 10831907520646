/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { ToastHeader, ToastBody, Toast } from 'reactstrap';
import './Notifications.scss';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { useActions } from 'actions';
import * as NotificationsActions from 'actions/notifications';

const Notifications = () => {
  const notificationState = useSelector((state: RootState) => state.notifications);
  let showNotification = false;
  const notficationActions = useActions(NotificationsActions);
  let id = 0;
  let header = '';
  let body = '';
  let type = '';
  if (notificationState.length !== 0) {
    [{ id, showNotification, header, body, type }] = notificationState;
  }

  const onDismiss = (id?: number) => {
    if (id) {
      notficationActions.hideNotificationById({ id: id });
    } else if (showNotification) {
      notficationActions.hideNotification();
    }
  };

  useEffect(() => {
    const duration = 5000;
    const id = setTimeout(() => onDismiss(), duration);
    return () => clearTimeout(id);
    // eslint-disable-next-line
  }, [showNotification]);

  return (
    <div className="notification">
      {notificationState.map(x => (
        <Toast key={x.id} className="notification__toast" isOpen={x.showNotification}>
          <ToastHeader icon={x.type} toggle={() => onDismiss(x.id)}>
            {x.header}
          </ToastHeader>
          <ToastBody>{x.body}</ToastBody>
        </Toast>
      ))}
    </div>
  );
};

export default Notifications;
