export interface Auth {
  token?: string;
  email: string;
  firstName: string;
  lastName: string;
  date?: string;
  isConfirmed?: boolean;
  currentDeviceID?: string;
  isPending?: boolean;
  level: number;
  id: string;
}

export interface ProjectToken {
  projectId: string;
  projectToken: string;
}

export enum AuthActionTypes {
  GET_SIGNED_IN_USER = 'GET_SIGNED_IN_USER',
  AUTHENTICATE = 'AUTHENTICATE',
  SET_AUTH_STATE = 'SET_AUTH_STATE',
  RESET_AUTH_STATE = 'RESET_AUTH_STATE',
  UNAUTHENTICATE = 'UNAUTHENTICATE',
  SET_PROJECT_TOKEN = 'SET_PROJECT_TOKEN',
  RESET_PROJECT_TOKEN = 'RESET_PROJECT_TOKEN',
}

interface AuthActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

interface ProjectActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type AuthAction =
  | AuthActionCreatorType<typeof AuthActionTypes.GET_SIGNED_IN_USER, Auth>
  | AuthActionCreatorType<typeof AuthActionTypes.AUTHENTICATE, Auth>
  | AuthActionCreatorType<typeof AuthActionTypes.SET_AUTH_STATE, Auth>
  | AuthActionCreatorType<typeof AuthActionTypes.RESET_AUTH_STATE, Auth>
  | AuthActionCreatorType<typeof AuthActionTypes.UNAUTHENTICATE, Auth>
  | ProjectActionCreatorType<typeof AuthActionTypes.SET_PROJECT_TOKEN, ProjectToken>
  | ProjectActionCreatorType<typeof AuthActionTypes.RESET_PROJECT_TOKEN, ProjectToken>;
