import React from 'react';
import ReactDOM from 'react-dom';
import { ReduxRoot } from './ReduxRoot';
import 'bootstrap/dist/css/bootstrap.min.css';

const rootEl = document.getElementById('root');
ReactDOM.render(<ReduxRoot />, rootEl);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
