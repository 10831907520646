import React from 'react';
import { useSelector } from 'react-redux';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import './AppConfigurations.scss';
import ApnsSettingsCard from '../ApnsSettings/ApnsSettings';
import FcmSettingCard from '../FcmSettings/FcmSettings';
import { RootState } from 'reducers';
import SilentPushSettingsCard from '../SilentPushSettings/SilentPushSettings';
import UserSubscriptionsCard from '../UserSubscriptions/UserSubscriptions';

export default function AppConfigurations() {
  const fcmSettingsFromState = useSelector((state: RootState) => state.fcmSettings);
  const apnsSettingsFromState = useSelector((state: RootState) => state.apnsSettings);
  const apnsSettingFromState = useSelector((state: RootState) => state.apnsSetting);
  const silentPushSettingsFormState = useSelector((state: RootState) => state.silentPushSettings);
  const userSubscriptionsFromState = useSelector((state: RootState) => state.userSubscriptions);

  return (
    <div>
      <div className="app-config">
        <ReusableCard>
          <FcmSettingCard fcmSettingsFromState={fcmSettingsFromState} />
        </ReusableCard>
        <div className="app-config__ele">
          <ReusableCard>
            <ApnsSettingsCard
              apnsSettingsFromState={apnsSettingsFromState}
              apnsSettingFromState={apnsSettingFromState}
            />
          </ReusableCard>
        </div>
      </div>
      <div className="pt-5">
        <div className="silent-push-settings-card">
          <ReusableCard>
            <SilentPushSettingsCard silentPushSettingsFromState={silentPushSettingsFormState} />
          </ReusableCard>
        </div>
        <div className="user-subscriptions-card">
          <ReusableCard>
            <UserSubscriptionsCard userSubscriptionsFromState={userSubscriptionsFromState} />
          </ReusableCard>
        </div>
      </div>
    </div>
  );
}
