export const layout = [
  {
    id: '1',
    value: 'App Configurations',
  },
  {
    id: '2',
    value: 'Push Templates',
  },
];
