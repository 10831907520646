import React, { useEffect } from 'react';
import ProjectDetails from '../../components/AboutProjectComponents/ProjectDetails/ProjectDetails';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { RootState } from 'reducers';
import LanguagesCard from '../../components/AboutProjectComponents/Languages/Languages';
import DeleteProject from '../../components/AboutProjectComponents/DeleteProject/DeleteProject';
import { useActions } from 'actions';
import * as LanguagesActions from 'actions/languages';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { getProjectToken, setProjectTokenToStorage } from 'helpers/auth';
import { flatten } from 'lodash';
import { ProjectToken } from 'model/auth';

export default function AboutProjectPage() {
  const projectFromState = useSelector((state: RootState) => state.project);
  const languagesFromState = useSelector((state: RootState) => state.languages);
  const projectTokenFromState = useSelector((state: RootState) => state.token);
  const languagesActions = useActions(LanguagesActions);
  const routeParamsId = window.location.href.split('/')[4];

  function getToken() {
    if (projectTokenFromState.length !== 0) {
      const localTokenState = flatten(projectTokenFromState);
      const token = localTokenState.find(x => x.projectId === routeParamsId);
      return token as ProjectToken;
    }
    return null;
  }

  async function fetchProjectDependencies() {
    const projectToken = await getProjectToken();
    if (projectToken) {
      await languagesActions.fetchAllLanguages();
    } else {
      const projectTokenFromState = getToken();
      const token = projectTokenFromState?.projectToken || '';
      setProjectTokenToStorage(token, routeParamsId);
      await languagesActions.fetchAllLanguages();
    }
  }

  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <div>
        <PageHeader title="About this project" isLarge={true} />
        <Col className="pt-5">
          <ProjectDetails projectFromState={projectFromState} />
        </Col>
        <Col className="pt-5">
          <LanguagesCard languagesFromState={languagesFromState} />
        </Col>
        <Col className="pt-5">
          <DeleteProject projectFromState={projectFromState} />
        </Col>
      </div>
    </div>
  );
}
