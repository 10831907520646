import React, { useState, useEffect } from 'react';
import { Alert, Jumbotron, Container, Button } from 'reactstrap';
import './LandingPage.scss';
import flyBitsLogo from 'assets/images/logo-flybits-white.svg';
import { Link } from 'react-router-dom';
import { useActions } from 'actions';
import * as AuthActions from 'actions/auth';
import { NavBar } from 'components/NavBar';

export function LandingPage() {
  const authActions = useActions(AuthActions);
  useEffect(() => {
    /* Check if token exists in user's localstorage (localForage)*/
    authActions.tokenExists();
    //eslint-disable-next-line
  }, []);
  //TODO: sai.karthik@flybits.com - use locale/constants to populate cookie disclaimer
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  return (
    <div>
      <NavBar />
      <div className="landing-page">
        <Jumbotron className="landing-page__jumbotron">
          <Container className="landing-page__jumbotron__container-logo">
            <img src={flyBitsLogo} className="landing-page__jumbotron__container-logo__img" alt="flybits-logo" />
            <div className="landing-page__lead">
              Deliver hyper-personalized customer experiences with Flybits contextual Digital Experience Platform.
            </div>
          </Container>
          <div className="landing-page__button">
            <Button outline className="landing-page__button__link btn-outline-light btn-lg btn">
              Get Started
            </Button>
            <Button
              outline
              className="landing-page__button__link btn-outline-light btn-lg btn"
              tag={Link}
              to="/projects"
            >
              View Projects
            </Button>
          </div>
        </Jumbotron>
        <Alert color="warning" className="landing-page__alert-message-cookie" isOpen={visible} toggle={onDismiss}>
          This site uses cookies to store your preferences for site-specific display options. By using this site, you
          agree to our updated <a href="/privacy">Privacy Policy</a> and our <a href="/terms">Terms of Use</a>.
        </Alert>
      </div>
    </div>
  );
}
