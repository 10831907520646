import React from 'react';
import { Datasource } from 'model/datasource';
import { FlightButton } from '@flybits/webapp-design-system-react';
import './DatasourceInfo.scss';

interface Props {
  datasource: Datasource;
  addDatasourceToProject(datasourceId: string): void;
  removeDatasourceFromProject(tenantConnectionId: string): void;
}

export default function DatasourceInfo(props: Props) {
  const { datasource, addDatasourceToProject, removeDatasourceFromProject } = props;
  const MAIN_CLASS = 'datasource-info';

  function handleContactSupport() {
    window.location.href = 'mailto:support@flybits.com';
  }

  function handleLearnMore() {
    if (datasource.documentationUrl) {
      window.open(datasource.documentationUrl, '_blank');
    }
  }

  function handleAddToProject() {
    addDatasourceToProject(datasource.id);
  }

  function handleRemoveFromProject() {
    if (datasource.tenantConnectionID) {
      removeDatasourceFromProject(datasource.tenantConnectionID);
    }
  }

  return (
    <div className={MAIN_CLASS}>
      <div className={`${MAIN_CLASS}__header`}>
        <div className={`${MAIN_CLASS}__header__info`}>
          <img
            src={datasource.imageUrl}
            alt={datasource.providerName}
            className={`${MAIN_CLASS}__header__info__image`}
          />
          <div className={`${MAIN_CLASS}__header__info__content`}>
            <div className={`${MAIN_CLASS}__header__info__content__name`}>{datasource.name}</div>
            {datasource.providerName && (
              <div className={`${MAIN_CLASS}__header__info__content__text`}>By {datasource.providerName}</div>
            )}
            {datasource.description && (
              <div className={`${MAIN_CLASS}__header__info__content__text`}>{datasource.description}</div>
            )}
            <div className={`${MAIN_CLASS}__header__info__content__links`}>
              <FlightButton
                label="Learn more"
                theme="link"
                onClick={handleLearnMore}
                className={`${MAIN_CLASS}__header__link-button`}
              />
              <FlightButton label="Contact support" theme="link" onClick={handleContactSupport} />
            </div>
          </div>
        </div>
        <div className={`${MAIN_CLASS}__header__manage-links`}>
          <FlightButton
            label="Manage"
            theme="link"
            iconRight="openInNew"
            //eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}} // Todo: Add link to Manage
            className={`${MAIN_CLASS}__header__link-button`}
          />
          {datasource.hasTenantConnection ? (
            <FlightButton
              label="Remove from project"
              theme="link"
              onClick={handleRemoveFromProject}
              className={`${MAIN_CLASS}__header__manage-links__remove`}
            />
          ) : (
            <FlightButton label="Add to project" onClick={handleAddToProject} />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}
