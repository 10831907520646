export interface SamlConfigs {
  acsURL: string;
  entityID: string;
  idpIssuer: string;
  idpMetadataURL: string;
  idpSSOURL: string;
  publicCert: string;
  skipSignatureValidation: boolean;
  tenantID: string;
}

export interface SamlSettings extends SamlConfigs {
  samlConfigs: SamlConfigs[];
}

export interface SamlSettingsForApi {
  samlConfigs: SamlConfigs[];
}

export enum SamlSettingsActionTypes {
  SET_SAMLSETTINGS_STATE = 'SET_SAMLSETTINGS_STATE',
  RESET_SAMLSETTINGS_STATE = 'RESET_SAMLSETTINGS_STATE',
}

interface SamlSettingsActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type SamlSettingsActions =
  | SamlSettingsActionCreatorType<typeof SamlSettingsActionTypes.SET_SAMLSETTINGS_STATE, SamlSettings>
  | SamlSettingsActionCreatorType<typeof SamlSettingsActionTypes.RESET_SAMLSETTINGS_STATE, SamlSettings>;
