export interface Properties {
  isRequired: boolean;
  isUniqueIdentifier: boolean;
}
export interface Fields {
  name: string;
  displayName: {};
  properties: Properties;
  type: string;
  subtype?: string;
}

export interface Schema {
  fields: Fields[];
}

export interface ContentTemplate {
  id: string;
  tenantId: string;
  localizations: {};
  type: string;
  labels: Array<string>;
  internal: boolean;
  schema: Schema;
  iconUrl: string;
  createdAt: number;
  modifiedAt: number;
}

export interface ContentTemplateAttributes {
  createdAt: number;
  iconUrl: string;
  localizations: {};
  modifiedAt: number;
  schema: Schema;
  templateId: string;
  tenantId: string;
  type: string;
  labels: string[];
  projectTokenForApiCall?: string; //front-end only property for base service
}

export interface ContentTemplateData {
  id: string;
  attributes: ContentTemplateAttributes;
  type: string;
}

export interface ContentTemplatesDownload {
  data: ContentTemplateData[];
  meta: {
    downloadedAt: number;
    totalRecords: number;
  };
}

export enum ContentTemplatesActionTypes {
  SET_CONTENT_TEMPLATES_STATE = 'SET_CONTENT_TEMPLATES_STATE',
  RESET_CONTENT_TEMPLATES_STATE = 'RESET_CONTENT_TEMPLATES_STATE',
}

interface ContentTemplatesActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type ContentTemplatesActions =
  | ContentTemplatesActionCreatorTypes<typeof ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE, ContentTemplate>
  | ContentTemplatesActionCreatorTypes<
      typeof ContentTemplatesActionTypes.RESET_CONTENT_TEMPLATES_STATE,
      ContentTemplate
    >;
