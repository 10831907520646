import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RootState } from 'reducers';
import * as FcmActions from 'actions/fcmSettings';
import * as ApnsActions from 'actions/apnsSettings';
import * as UserSubscriptionActions from 'actions/userSubscriptions';
import * as SilentPushSettingsActions from 'actions/silentPushSettings';
import * as NotificationTypesActions from 'actions/notificationTypes';
import * as NotificationTypeActions from 'actions/notificationTypes';
import { useActions } from 'actions';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { layout } from 'constants/pushPageLayout';
import './PushPage.scss';
import AppConfigurations from 'components/PushComponents/AppConfigurations/AppConfigurations';
import PushTemplatesCard from 'components/PushComponents/PushTemplates/PushTemplates';
import ManagePushTemplates from 'components/PushComponents/PushTemplates/ManagePushTemplate';
import { getTab, setTab } from 'helpers/rememberTab';

interface Layout {
  id: string;
  value: string;
}

export default function PushPage() {
  const fcmActions = useActions(FcmActions);
  const apnsActions = useActions(ApnsActions);
  const userSubscriptions = useActions(UserSubscriptionActions);
  const silentPushSettingsActions = useActions(SilentPushSettingsActions);
  const notificationTypesActions = useActions(NotificationTypeActions);
  const notificationTypeActions = useActions(NotificationTypesActions);
  const notificationTypesFromState = useSelector((state: RootState) => state.notificationTypes);
  const notificationTypeFromState = useSelector((state: RootState) => state.notificationType);

  async function fetchProjectDependencies() {
    await fcmActions.fetchFcmSettings();
    await apnsActions.fetchApnsSettings();
    await userSubscriptions.fetchAllUserSubscriptions();
    await silentPushSettingsActions.fetchSilentPushSettings();
    await notificationTypeActions.resetNotificationType();
    await notificationTypesActions.fetchNotificationTypes();
  }

  let isView = false;
  if (notificationTypeFromState.length !== 0) {
    [{ isView }] = notificationTypeFromState;
  }

  const getTabId = getTab('push');
  const [activeTab, setActiveTab] = useState(getTabId);

  useEffect(() => {
    setTab('push', activeTab);
    //eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {!isView ? (
        <div>
          <PageHeader title="Push Settings" isLarge={true} />
          <div className="push-page__tabstrip">
            <div>
              <Container className="push-page____tabstrip__container">
                <Row className="push-page__tabstrip__container__tabs ">
                  <Col>
                    <Nav tabs>
                      {layout.map((tab: Layout) => (
                        <NavItem key={tab.id}>
                          <NavLink
                            className={activeTab === tab.id ? 'active' : ''}
                            onClick={() => setActiveTab(tab.id)}
                          >
                            <span className="push-page__tabstrip__container__tabs__label">{tab.value}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container className="push-page__container ">
              <TabContent className="push-page__tabstrip__container__content" activeTab={activeTab}>
                <TabPane tabId="1">
                  <AppConfigurations />
                </TabPane>
                <TabPane tabId="2">
                  <PushTemplatesCard notificationTypesFromState={notificationTypesFromState} />
                </TabPane>
              </TabContent>
            </Container>
          </div>
        </div>
      ) : (
        <ManagePushTemplates notificationTypeFromState={notificationTypeFromState} />
      )}
    </div>
  );
}
