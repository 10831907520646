import React, { useState } from 'react';
import './PushTemplates.scss';
import {
  FlightSnackbar,
  FlightTable,
  FlightButton,
  FlightTooltip,
  FlightOverflowMenu,
  FlightModal,
} from '@flybits/webapp-design-system-react';
import { PUSH_TEMPLATES_INFO } from 'constants/banners/banners';
import { NotificationTypes, Schema, NotificationType } from 'model/notificationTypes';
import { flatten, cloneDeep, orderBy } from 'lodash';
import * as NotificationTypeActions from 'actions/notificationTypes';
import { useActions } from 'actions';

interface Props {
  notificationTypesFromState?: NotificationTypes[];
}

interface TableBody {
  templateName: JSX.Element;
  fields: string;
  requiredFields: string;
  createdBy: string;
  status: JSX.Element;
  moreOpts: JSX.Element;
  key: string;
}

interface IndexSignature {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function PushTemplatesCard(props: Props) {
  const { notificationTypesFromState = [] } = props;
  const [showBanner, setShowBanner] = useState(true);
  const TRACKED_KEYS = 'trackedKeys';
  const notificationTypeActions = useActions(NotificationTypeActions);
  const localNotificationTypesFromState = flatten(cloneDeep(notificationTypesFromState));

  const hideBanner = () => {
    setShowBanner(showBanner => !showBanner);
  };

  const tableHeaders = [
    {
      name: 'Template Name',
      key: 'templateName',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Fields',
      key: 'fields',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Required Fields',
      key: 'requiredFields',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Created By',
      key: 'createdBy',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'moreOpts',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const [isConfirm, setIsConfirm] = useState(false);
  const [idDelete, setIdForDelete] = useState('');
  const toggleDeleteConfirm = (id: string) => {
    if (idDelete === '') {
      setIdForDelete(id);
    } else {
      setIdForDelete('');
    }
    setIsConfirm(isConfirm => !isConfirm);
  };
  const handleDelete = async () => {
    await notificationTypeActions.deleteNotificationType(idDelete);
    setIdForDelete('');
    toggleDeleteConfirm('');
  };

  const handleDuplicate = (id: string) => {
    const notificationTypeFromState = localNotificationTypesFromState.filter(x => x.id === id);
    let notificationTypeView: NotificationType = {
      isActive: false,
      id: '',
      isReserved: false,
      isView: false,
      schema: {},
      description: '',
      name: '',
    };
    notificationTypeFromState.map(type => {
      return (notificationTypeView = {
        id: type.id,
        isActive: type.isActive,
        isReserved: type.isReserved,
        name: type.name,
        description: type.description,
        schema: type.schema,
        isView: true,
        isCreate: true,
      });
    });
    notificationTypeActions.resetNotificationType();
    notificationTypeActions.setNotificationType(notificationTypeView);
  };

  const moreOpts = (id: string) => {
    const optionGroups = [
      [
        {
          key: 1,
          name: 'Duplicate',
          disabled: false,
          onClick: () => handleDuplicate(id),
        },
        {
          key: 2,
          name: 'Delete',
          disabled: false,
          onClick: () => toggleDeleteConfirm(id),
        },
      ],
    ];

    return <FlightOverflowMenu optionGroups={optionGroups} />;
  };

  localNotificationTypesFromState.push({
    id: 'basic-push-type',
    name: 'Basic',
    description: `When a message is opened, the user will be redirected to your app's default home screen`,
    isActive: true,
    isReserved: true,
    schema: {},
  });

  const getNumberOfFields = (item: Schema) => {
    if (Object.keys(item).length === 0) {
      return '0';
    } else if (item.hasOwnProperty(TRACKED_KEYS)) {
      return `${
        Object.keys(item).length - 1 === 1 ? `${Object.keys(item).length - 1}` : `${Object.keys(item).length - 1}`
      }`;
    } else {
      return `${Object.keys(item).length === 1 ? `${Object.keys(item).length}` : `${Object.keys(item).length}`}`;
    }
  };

  const getNumberOfRequiredFields = (item: IndexSignature) => {
    let numOfRequiredFields = 0;
    const keys = Object.keys(item);
    keys.forEach((key: string) => {
      if (item[key].isRequired) {
        numOfRequiredFields++;
      }
    });
    return numOfRequiredFields.toString();
  };

  const sortedSubscriptionState = orderBy(localNotificationTypesFromState, 'name', 'asc');
  const viewNotificationType = (id: string) => {
    const notificationTypeFromState = localNotificationTypesFromState.filter(x => x.id === id);
    let notificationTypeView: NotificationType = {
      isActive: false,
      id: '',
      isReserved: false,
      isView: false,
      schema: {},
      description: '',
      name: '',
    };
    notificationTypeFromState.map(type => {
      return (notificationTypeView = {
        id: type.id,
        isActive: type.isActive,
        isReserved: type.isReserved,
        name: type.name,
        description: type.description,
        schema: type.schema,
        isView: true,
      });
    });
    notificationTypeActions.resetNotificationType();
    notificationTypeActions.setNotificationType(notificationTypeView);
  };

  const createPushTemplate = () => {
    const notificationTypeView: NotificationType = {
      isActive: false,
      id: '',
      isReserved: false,
      isView: true,
      schema: {},
      description: '',
      name: '',
      isCreate: true,
    };
    notificationTypeActions.resetNotificationType();
    notificationTypeActions.setNotificationType(notificationTypeView);
  };

  const toggleStatus = (notification: NotificationTypes) => {
    const status = notification.isActive;
    const updateNotificationType: NotificationTypes = {
      id: notification.id,
      isReserved: notification.isReserved,
      isActive: !status,
      name: notification.name,
      description: notification.description,
      schema: notification.schema,
    };
    notificationTypeActions.updatePushNotificationType(updateNotificationType);
  };

  const tableBody: TableBody[] = [];
  sortedSubscriptionState.forEach(notification => {
    tableBody.push({
      templateName: (
        <FlightTooltip direction="right" isEnabled={true} description={notification.description} delay={0}>
          <span className="push-templates__name" onClick={() => viewNotificationType(notification.id)}>
            {notification.name}
          </span>
        </FlightTooltip>
      ),

      fields: getNumberOfFields(notification.schema),
      requiredFields: getNumberOfRequiredFields(notification.schema),
      createdBy: notification.isReserved ? 'Flybits' : 'User',
      status: (
        <div>
          {notification.isActive ? (
            <span className="push-templates__status__active" onClick={() => toggleStatus(notification)}>
              Active
            </span>
          ) : (
            <span className="push-templates__status__inactive" onClick={() => toggleStatus(notification)}>
              Inactive
            </span>
          )}
        </div>
      ),
      moreOpts: moreOpts(notification.id),
      key: notification.id,
    });
  });

  return (
    <div className="push-templates">
      <FlightModal
        isVisible={isConfirm}
        size="medium"
        toggleModalShown={toggleDeleteConfirm}
        header={<span>Confirm Delete</span>}
        content={
          <span className="push-templates__delete-modal__text">
            Are you sure you want to delete notification type? You Can&apos;t undo this.
          </span>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={toggleDeleteConfirm} label="Cancel" />
            <FlightButton type="submit" label="Continue" onClick={handleDelete} />
          </div>
        }
      />
      <FlightSnackbar
        isVisible={showBanner}
        isFloating
        icon="infoFilled"
        title=""
        content={PUSH_TEMPLATES_INFO}
        type="info"
        isAutoDismiss={true}
        action={hideBanner}
        actionName="Got it!"
      />
      <div className="pt-5 pb-3">
        <FlightButton
          label={'New Push Template'}
          size="medium"
          type="primary"
          iconLeft={'add'}
          className="push-templates__btn"
          onClick={createPushTemplate}
        />
      </div>
      <div className="pt-5">
        <FlightTable
          className="push-templates__table"
          tableHeaders={tableHeaders}
          tableData={tableBody}
          hasPaginationBeforeTable={false}
        />
      </div>
    </div>
  );
}
