import {
  FacebookSettingsActions,
  FacebookSettingsActionTypes,
  FacebookSettings,
  ObjectForApi,
} from '../model/facebookSettings';
import SettingsService from '../services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './notifications';

export function setFacebookSettings(facebookSettings: FacebookSettings): FacebookSettingsActions {
  return {
    type: FacebookSettingsActionTypes.SET_FACEBOOKSETTINGS_STATE,
    payload: facebookSettings,
  };
}

export function resetFacebookSettings(): FacebookSettingsActions {
  return {
    type: FacebookSettingsActionTypes.RESET_FACEBOOKSETTINGS_STATE,
  };
}

export function fetchFacebookSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getFacebookSettings();
      dispatch(resetFacebookSettings());
      dispatch(setFacebookSettings(response.data.settings));
    } catch (error) {
      if (error.response.status && error.response.status === 404) {
        dispatch(resetFacebookSettings());
      }
    }
    return 'done';
  };
}

export function createOrUpdateFacebookSettings(facebookSettings: FacebookSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: JSON.stringify(facebookSettings),
        configName: {
          configName: 'facebook',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchFacebookSettings());
      }
    } catch (error) {
      console.log(error);
    }
    return 'done';
  };
}
